import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Link,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getSalaryHistory, downloadSalarySlip, getTrainerMonthlyHours, handleApiError } from '../api';
import dayjs from 'dayjs';
import SalaryBreakdownModal from './SalaryBreakdownModal';

const GradientBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  background: 'linear-gradient(45deg, #1a237e, #311b92, #4a148c)',
});

const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

function SalaryHistoryModal({ open, onClose, trainerId, trainerName }) {
  const [salaryHistory, setSalaryHistory] = useState([]);
  const [monthlyHours, setMonthlyHours] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSalary, setSelectedSalary] = useState(null);
  const [breakdownModalOpen, setBreakdownModalOpen] = useState(false);

  useEffect(() => {
    if (open && trainerId) {
      fetchSalaryHistory();
    }
  }, [open, trainerId]);

  const fetchSalaryHistory = async () => {
    try {
      setLoading(true);
      const response = await getSalaryHistory(trainerId);
      setSalaryHistory(response.data || []);

      // Fetch monthly hours for each salary record
      const hoursPromises = response.data.map(salary => 
        getTrainerMonthlyHours(trainerId, salary.year, salary.month)
      );
      const hoursResults = await Promise.all(hoursPromises);
      
      const hoursMap = {};
      hoursResults.forEach((result, index) => {
        const salary = response.data[index];
        hoursMap[`${salary.year}-${salary.month}`] = result.total_hours;
      });
      setMonthlyHours(hoursMap);
    } catch (error) {
      const errorMessage = handleApiError(error);
      setError(errorMessage.message || 'Failed to fetch salary history. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSlip = async (year, month) => {
    try {
      const response = await downloadSalarySlip(trainerId, { year, month });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `salary_slip_${year}_${month}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      const errorMessage = handleApiError(error);
      setError(errorMessage.message || 'Error downloading salary slip. Please try again.');
    }
  };

  const handleMonthClick = (salary) => {
    setSelectedSalary({
      ...salary,
      total_hours: monthlyHours[`${salary.year}-${salary.month}`] || 'N/A'
    });
    setBreakdownModalOpen(true);
  };

  return (
    <>
      <GradientBackground />
      <GlassDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ color: 'white' }}>Salary History for {trainerName}</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress sx={{ color: 'white' }} />
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : salaryHistory.length > 0 ? (
            <GlassTableContainer sx={{ maxHeight: 400, overflow: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell>Month</TableCell>
                    <TableCell>Total Salary</TableCell>
                    <TableCell>Total Hours</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salaryHistory.map((salary) => (
                    <TableRow key={`${salary.year}-${salary.month}`}>
                      <TableCell>{salary.year}</TableCell>
                      <TableCell>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => handleMonthClick(salary)}
                          sx={{ color: 'white' }}
                        >
                          {dayjs().month(salary.month - 1).format('MMMM')}
                        </Link>
                      </TableCell>
                      <TableCell>₹{parseFloat(salary.total_salary).toFixed(2)}</TableCell>
                      <TableCell>{monthlyHours[`${salary.year}-${salary.month}`] || 'N/A'}</TableCell>
                      <TableCell>
                        <GradientButton 
                          onClick={() => handleDownloadSlip(salary.year, salary.month)}
                          size="small"
                        >
                          Download Slip
                        </GradientButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GlassTableContainer>
          ) : (
            <Typography sx={{ color: 'white' }}>No salary history available for this trainer.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={onClose}>
            Close
          </GradientButton>
        </DialogActions>
        <SalaryBreakdownModal
          open={breakdownModalOpen}
          onClose={() => setBreakdownModalOpen(false)}
          salaryData={selectedSalary}
        />
      </GlassDialog>
    </>
  );
}

export default SalaryHistoryModal;