import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
  CircularProgress,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { 
  listAttendances, 
  createAttendanceReview, 
  addClassContent,
  updateAttendance,
  createAttendance
} from '../api';

const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(10, 10, 50, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const DayCell = styled(Box)(({ theme, isSelected, isToday, status }) => ({
  height: '70px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: 
    isSelected ? 'rgba(255, 255, 255, 0.2)' :
    isToday ? 'rgba(255, 255, 255, 0.1)' :
    'transparent',
  color: 'white',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
}));

const StatusText = styled(Typography)(({ theme, status }) => ({
  fontSize: '10px',
  color: 
    status === 'PRESENT' ? theme.palette.success.main :
    status === 'ABSENT' ? theme.palette.error.main :
    status === 'TRAINER_ABSENT' ? theme.palette.warning.main :
    status === 'OFF' ? theme.palette.info.main :
    status === 'COMP' ? theme.palette.secondary.main :
    theme.palette.text.secondary,
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(10, 10, 50, 0.9)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const AttendanceManagement = ({ studentId, trainerId, userRole, onRequestReview }) => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState(null);
  const [attendanceData, setAttendanceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [classContent, setClassContent] = useState('');
  const [reviewRemark, setReviewRemark] = useState('');
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchAttendanceData = useCallback(async () => {
    if (!studentId) return;
    setLoading(true);
    try {
      const startDate = selectedMonth.startOf('month').format('YYYY-MM-DD');
      const endDate = selectedMonth.endOf('month').format('YYYY-MM-DD');
      
      // Modified to only use studentId for filtering
      const response = await listAttendances({
        student: studentId,
        start_date: startDate,
        end_date: endDate,
      });
      
      const attendanceMap = {};
      response.data.forEach(attendance => {
        const attendanceDate = dayjs(attendance.timestamp).format('YYYY-MM-DD');
        attendanceMap[attendanceDate] = attendance;
      });
      setAttendanceData(attendanceMap);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch attendance data',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  }, [selectedMonth, studentId]);

  useEffect(() => {
    fetchAttendanceData();
  }, [fetchAttendanceData]);

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth.add(1, 'month'));
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const dateString = date.format('YYYY-MM-DD');
    const attendance = attendanceData[dateString];
    if (attendance) {
      setSelectedAttendance(attendance);
      setClassContent(attendance.class_content || '');
      setNewStatus(attendance.status);
    } else {
      setSelectedAttendance(null);
      setClassContent('');
      setNewStatus('');
    }
    setDetailModalOpen(true);
  };

  const handleUpdateAttendance = async () => {
    try {
      if (userRole === 'TRAINER') {
        if (!selectedAttendance) return;
  
        // Add class content
        const response = await addClassContent(selectedAttendance.id, { 
          class_content: classContent,
          trainer: trainerId  // Make sure this is passed
        });
        
        setAttendanceData(prevData => ({
          ...prevData,
          [selectedDate.format('YYYY-MM-DD')]: response.data
        }));
        
        setDetailModalOpen(false);
        setSnackbar({
          open: true,
          message: 'Class content updated successfully',
          severity: 'success'
        });
      } else if (userRole === 'ADMIN' || userRole === 'MANAGER') {
        let response;
        if (selectedAttendance) {
          response = await updateAttendance(selectedAttendance.id, {
            status: newStatus,
            student: studentId,
          });
        } else {
          response = await createAttendance({
            student: studentId,
            trainer: trainerId,
            date: selectedDate.format('YYYY-MM-DD'),
            status: newStatus,
          });
        }
        setAttendanceData(prevData => ({
          ...prevData,
          [selectedDate.format('YYYY-MM-DD')]: response.data
        }));
        setSnackbar({ 
          open: true, 
          message: 'Attendance updated successfully', 
          severity: 'success' 
        });
      }
      
      setDetailModalOpen(false);
      fetchAttendanceData();
    } catch (error) {
      console.error('Error updating attendance:', error);
      setSnackbar({ 
        open: true, 
        message: error.response?.data?.error || 'Failed to update attendance',
        severity: 'error'
      });
    }
  };

  const handleRequestReview = async () => {
    try {
      await createAttendanceReview({
        attendance: selectedAttendance.id,
        trainer: trainerId,
        remark: reviewRemark,
      });
      setReviewModalOpen(false);
      onRequestReview(selectedAttendance);
      setReviewRemark('');
      setSnackbar({ open: true, message: 'Attendance review requested successfully', severity: 'success' });
    } catch (error) {
      console.error('Error requesting review:', error);
      setSnackbar({ open: true, message: 'Failed to request review. Please try again.', severity: 'error' });
    }
  };

  const renderCalendar = () => {
    const startOfMonth = selectedMonth.startOf('month');
    const endOfMonth = selectedMonth.endOf('month');
    const startDate = startOfMonth.startOf('week');
    const endDate = endOfMonth.endOf('week');

    const calendarDays = [];
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    calendarDays.push(
      <Grid container key="weekdays">
        {weekDays.map(day => (
          <Grid item xs key={day}>
            <Typography align="center" variant="subtitle2" color="white">{day}</Typography>
          </Grid>
        ))}
      </Grid>
    );

    let week = [];
    for (let day = startDate; day.isBefore(endDate) || day.isSame(endDate); day = day.add(1, 'day')) {
      const dateString = day.format('YYYY-MM-DD');
      const attendance = attendanceData[dateString];
      const isSelected = selectedDate && selectedDate.isSame(day, 'day');
      const isToday = day.isSame(dayjs(), 'day');

      week.push(
        <Grid item xs key={dateString}>
          <DayCell
            isSelected={isSelected}
            isToday={isToday}
            onClick={() => handleDateClick(day)}
          >
            <Typography variant="body2">{day.date()}</Typography>
            {attendance && (
              <StatusText status={attendance.status}>
                {attendance.status}
              </StatusText>
            )}
          </DayCell>
        </Grid>
      );

      if (week.length === 7) {
        calendarDays.push(<Grid container key={day.format('YYYY-MM-DD')}>{week}</Grid>);
        week = [];
      }
    }

    if (week.length > 0) {
      calendarDays.push(<Grid container key={endDate.format('YYYY-MM-DD')}>{week}</Grid>);
    }

    return calendarDays;
  };

  return (
    <GlassContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <IconButton onClick={handlePrevMonth} sx={{ color: 'white' }}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h6" color="white">{selectedMonth.format('MMMM YYYY')}</Typography>
        <IconButton onClick={handleNextMonth} sx={{ color: 'white' }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress sx={{ color: 'white' }} />
        </Box>
      ) : (
        renderCalendar()
      )}

<GlassDialog 
        open={detailModalOpen} 
        onClose={() => setDetailModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ color: 'white' }}>Attendance Details</DialogTitle>
        <DialogContent>
          <Typography color="white">Date: {selectedDate ? selectedDate.format('MMMM D, YYYY') : ''}</Typography>
          {selectedAttendance && (
            <>
              <Typography color="white">Status: {selectedAttendance.status}</Typography>
              <Typography color="white">Time: {dayjs(selectedAttendance.timestamp).format('HH:mm:ss')}</Typography>
            </>
          )}
          {(userRole === 'ADMIN' || userRole === 'MANAGER') && (
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel id="status-select-label" style={{ color: 'white' }}>Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  style={{ color: 'white' }}
                >
                  <MenuItem value="PRESENT">Present</MenuItem>
                  <MenuItem value="ABSENT">Absent</MenuItem>
                  <MenuItem value="TRAINER_ABSENT">Trainer Absent</MenuItem>
                </Select>
              </FormControl>
              {selectedAttendance && selectedAttendance.class_content && (
                <Box mt={2}>
                  <Typography variant="subtitle2" color="white">Class Content:</Typography>
                  <Typography color="white" style={{ whiteSpace: 'pre-wrap' }}>
                    {selectedAttendance.class_content}
                  </Typography>
                </Box>
              )}
            </>
          )}
          {userRole === 'TRAINER' && (
            <>
              <StyledTextField
                fullWidth
                multiline
                rows={4}
                label="Class Content: Update what you taught"
                value={classContent}
                onChange={(e) => setClassContent(e.target.value)}
                margin="normal"
              />
            </>
          )}
          {selectedAttendance && selectedAttendance.student_feedback !== 'NO_ACTION' && (
            <Box mt={2}>
              <Typography variant="subtitle2" color="white">Student Feedback:</Typography>
              <Chip
                label={selectedAttendance.student_feedback}
                color={selectedAttendance.student_feedback === 'ACCEPTED' ? 'success' : 'error'}
                sx={{ color: 'white', borderColor: 'white' }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailModalOpen(false)} sx={{ color: 'white' }}>Close</Button>
          {userRole === 'TRAINER' && (
            <>
              <GradientButton
                variant="contained"
                size="small"
                onClick={() => setReviewModalOpen(true)}
              >
                Request Attendance Review
              </GradientButton>
              <GradientButton onClick={handleUpdateAttendance}>
                Update Class Content
              </GradientButton>
            </>
          )}
          {(userRole === 'ADMIN' || userRole === 'MANAGER') && (
            <GradientButton onClick={handleUpdateAttendance}>
              Update Attendance
            </GradientButton>
          )}
        </DialogActions>
      </GlassDialog>

      <GlassDialog 
        open={reviewModalOpen} 
        onClose={() => setReviewModalOpen(false)}
      >
        <DialogTitle sx={{ color: 'white' }}>Request Attendance Review</DialogTitle>
        <DialogContent>
          <StyledTextField
            fullWidth
            multiline
            rows={5}
            label="Reason for review request"
            value={reviewRemark}
            onChange={(e) => setReviewRemark(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReviewModalOpen(false)} sx={{ color: 'white' }}>Cancel</Button>
          <GradientButton onClick={handleRequestReview}>
            Submit Review Request
          </GradientButton>
        </DialogActions>
      </GlassDialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassContainer>
  );
};

export default AttendanceManagement;