import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Link,
  Chip,
  Autocomplete,
  styled,
  InputAdornment,
  Tooltip,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  FilterList as FilterIcon,
  Search as SearchIcon,
  Label as LabelIcon,
  Category as CategoryIcon
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// Styled Components
const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GlassChip = styled(Chip)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.common.white,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
}));

const ContentManagement = ({ 
  contents, 
  categories,
  onContentCreate, 
  onContentUpdate, 
  onContentDelete 
}) => {
  // State Management
  const [openDialog, setOpenDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [editingContent, setEditingContent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [contentTypeFilter, setContentTypeFilter] = useState('ALL');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    content_type: '',
    video_url: '',
    is_youtube: false,
    markdown_content: '',
    category_ids: []
  });

  // Filtered Contents
  const filteredContents = useMemo(() => {
    return contents.filter(content => {
      const matchesSearch = 
        content.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        content.description.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesType = 
        contentTypeFilter === 'ALL' || 
        content.content_type === contentTypeFilter;
      
      const matchesCategories = 
        selectedCategories.length === 0 ||
        selectedCategories.every(catId => 
          content.categories?.some(cat => cat.id === catId)
        );

      return matchesSearch && matchesType && matchesCategories;
    });
  }, [contents, searchTerm, contentTypeFilter, selectedCategories]);

  // Dialog Handlers
  const handleOpen = () => {
    resetForm();
    setOpenDialog(true);
  };

  const handleClose = () => {
    resetForm();
    setOpenDialog(false);
  };

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      content_type: '',
      video_url: '',
      is_youtube: false,
      markdown_content: '',
      category_ids: []
    });
    setEditingContent(null);
    setError(null);
  };

  const handleOpenPreview = (content) => {
    setSelectedContent(content);
    setOpenPreviewDialog(true);
  };

  const handleClosePreview = () => {
    setOpenPreviewDialog(false);
    setSelectedContent(null);
  };

  // Form Handlers
  const handleEdit = (content) => {
    setEditingContent(content);
    setFormData({
      title: content.title,
      description: content.description,
      content_type: content.content_type,
      video_url: content.video_url || '',
      is_youtube: content.is_youtube || false,
      markdown_content: content.markdown_content || '',
      category_ids: content.categories?.map(cat => cat.id) || []
    });
    setOpenDialog(true);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      const payload = {
        ...formData,
        category_ids: formData.category_ids
      };

      if (editingContent) {
        await onContentUpdate(editingContent.id, payload);
      } else {
        await onContentCreate(payload);
      }
      
      handleClose();
    } catch (err) {
      setError('Failed to save content');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Category Display Component
  const CategoryDisplay = ({ categories }) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {categories?.map((category) => (
        <GlassChip
          key={category.id}
          label={category.name}
          size="small"
          icon={<CategoryIcon sx={{ fontSize: 16 }} />}
          variant="outlined"
        />
      ))}
    </Box>
  );

  return (
    <Box>
      {/* Header and Controls */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography variant="h6" sx={{ color: 'white' }}>
          Content Management
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Add Content
          </Button>
        </Box>
      </Box>

      {/* Filters */}
      <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          placeholder="Search content..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />

        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Content Type</InputLabel>
          <Select
            value={contentTypeFilter}
            onChange={(e) => setContentTypeFilter(e.target.value)}
            label="Content Type"
          >
            <MenuItem value="ALL">All Types</MenuItem>
            <MenuItem value="VIDEO">Video</MenuItem>
            <MenuItem value="TEXT">Text</MenuItem>
            <MenuItem value="QUIZ">Quiz</MenuItem>
          </Select>
        </FormControl>

        <Autocomplete
          multiple
          options={categories}
          getOptionLabel={(option) => option.name}
          value={categories.filter(cat => selectedCategories.includes(cat.id))}
          onChange={(_, newValue) => {
            setSelectedCategories(newValue.map(cat => cat.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Categories"
              placeholder="Select categories"
            />
          )}
          sx={{ minWidth: 250 }}
        />
      </Box>

      {/* Alerts */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {/* Content Table */}
      <GlassTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContents.map((content) => (
              <TableRow key={content.id}>
                <TableCell>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => handleOpenPreview(content)}
                    sx={{
                      textAlign: 'left',
                      textDecoration: 'none',
                      color: 'primary.main',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    {content.title}
                  </Link>
                </TableCell>
                <TableCell>
                  <GlassChip
                    label={content.content_type}
                    size="small"
                  />
                </TableCell>
                <TableCell>{content.description}</TableCell>
                <TableCell>
                  <CategoryDisplay categories={content.categories} />
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Preview">
                    <IconButton onClick={() => handleOpenPreview(content)} sx={{ color: 'primary.main' }}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(content)} sx={{ color: 'info.main' }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton 
                      onClick={() => onContentDelete(content.id)}
                      sx={{ color: 'error.main' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </GlassTableContainer>

      {/* Create/Edit Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            background: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white'
          }
        }}
      >
        <DialogTitle>
          {editingContent ? 'Edit Content' : 'Create New Content'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Title"
              value={formData.title}
              onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
              fullWidth
              required
            />
            
            <TextField
              label="Description"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              multiline
              rows={3}
              fullWidth
            />

            <FormControl fullWidth required>
              <InputLabel>Content Type</InputLabel>
              <Select
                value={formData.content_type}
                onChange={(e) => setFormData(prev => ({ 
                  ...prev, 
                  content_type: e.target.value 
                }))}
                label="Content Type"
              >
                <MenuItem value="VIDEO">Video</MenuItem>
                <MenuItem value="TEXT">Text</MenuItem>
                <MenuItem value="QUIZ">Quiz</MenuItem>
              </Select>
            </FormControl>

            <Autocomplete
              multiple
              options={categories}
              getOptionLabel={(option) => option.name}
              value={categories.filter(cat => formData.category_ids.includes(cat.id))}
              onChange={(_, newValue) => {
                setFormData(prev => ({
                  ...prev,
                  category_ids: newValue.map(cat => cat.id)
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categories"
                  placeholder="Select categories"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <GlassChip
                    key={option.id}
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />

            {formData.content_type === 'VIDEO' && (
              <>
                <TextField
                  label="Video URL"
                  value={formData.video_url}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    video_url: e.target.value 
                  }))}
                  fullWidth
                  required
                />
                <FormControl fullWidth>
                  <InputLabel>Video Type</InputLabel>
                  <Select
                    value={formData.is_youtube}
                    onChange={(e) => setFormData(prev => ({ 
                      ...prev, 
                      is_youtube: e.target.value 
                    }))}
                    label="Video Type"
                  >
                    <MenuItem value={true}>YouTube</MenuItem>
                    <MenuItem value={false}>Direct URL</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}

            {formData.content_type === 'TEXT' && (
              <TextField
                label="Content (Markdown supported)"
                value={formData.markdown_content}
                onChange={(e) => setFormData(prev => ({ 
                  ...prev, 
                  markdown_content: e.target.value 
                }))}
                multiline
                rows={6}
                fullWidth
                required
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: 'white' }}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : (editingContent ? 'Update' : 'Create')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog 
        open={openPreviewDialog} 
        onClose={handleClosePreview}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            background: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white',
            maxHeight: '90vh'
          }
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{selectedContent?.title}</Typography>
          <Box>
            {selectedContent?.categories?.map(category => (
              <GlassChip
                key={category.id}
                label={category.name}
                size="small"
                icon={<CategoryIcon sx={{ fontSize: 16 }} />}
                sx={{ ml: 1 }}
              />
            ))}
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {selectedContent?.content_type === 'TEXT' && (
            <Box sx={{ 
              '& img': { maxWidth: '100%', height: 'auto' },
              '& table': { 
                borderCollapse: 'collapse', 
                width: '100%',
                color: 'white' 
              },
              '& th, & td': { 
                border: '1px solid rgba(255, 255, 255, 0.2)', 
                padding: '8px' 
              },
              '& code': { 
                backgroundColor: 'rgba(255, 255, 255, 0.1)', 
                padding: '2px 4px', 
                borderRadius: '4px',
                color: '#e3e3e3'
              },
              '& pre': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                padding: '16px',
                borderRadius: '4px',
                overflow: 'auto',
                color: '#e3e3e3'
              },
              color: 'white'
            }}>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {selectedContent?.markdown_content || ''}
              </ReactMarkdown>
            </Box>
          )}
          {selectedContent?.content_type === 'VIDEO' && (
            <Box sx={{ width: '100%', position: 'relative', pt: '56.25%' }}>
              {selectedContent.is_youtube ? (
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0
                  }}
                  src={selectedContent.video_url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              ) : (
                <video
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                  }}
                  controls
                  src={selectedContent.video_url}
                />
              )}
            </Box>
          )}
          {selectedContent?.content_type === 'QUIZ' && (
            <Box sx={{ color: 'white' }}>
              <Typography variant="subtitle1" gutterBottom>
                Quiz Preview
              </Typography>
              {selectedContent.questions?.map((question, index) => (
                <Box key={question.id} sx={{ mb: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Question {index + 1}: {question.question_text}
                  </Typography>
                  {question.question_image && (
                    <Box sx={{ my: 2 }}>
                      <img 
                        src={question.question_image} 
                        alt={`Question ${index + 1}`}
                        style={{ maxWidth: '100%', borderRadius: '8px' }}
                      />
                    </Box>
                  )}
                  {question.question_video_url && (
                    <Box sx={{ my: 2 }}>
                      <video 
                        src={question.question_video_url}
                        controls
                        style={{ maxWidth: '100%', borderRadius: '8px' }}
                      />
                    </Box>
                  )}
                  <Box sx={{ ml: 2, mt: 2 }}>
                    {question.options.map((option, optIndex) => (
                      <Box 
                        key={option.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 1,
                          p: 1,
                          borderRadius: 1,
                          bgcolor: 'rgba(255, 255, 255, 0.05)',
                          border: '1px solid',
                          borderColor: 'rgba(255, 255, 255, 0.1)'
                        }}
                      >
                        <Typography variant="body1">
                          {String.fromCharCode(65 + optIndex)}. {option.option_text}
                        </Typography>
                        {option.option_image && (
                          <Box sx={{ ml: 2 }}>
                            <img 
                              src={option.option_image} 
                              alt={`Option ${String.fromCharCode(65 + optIndex)}`}
                              style={{ maxHeight: '50px', borderRadius: '4px' }}
                            />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                  {question.explanation && (
                    <Box sx={{ 
                      mt: 2, 
                      p: 2, 
                      bgcolor: 'rgba(255, 255, 255, 0.05)',
                      borderRadius: 1
                    }}>
                      <Typography variant="subtitle2" color="primary.main" gutterBottom>
                        Explanation:
                      </Typography>
                      <Typography variant="body2">
                        {question.explanation}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview} sx={{ color: 'white' }}>
            Close
          </Button>
          <Button 
            onClick={() => {
              handleClosePreview();
              handleEdit(selectedContent);
            }}
            variant="contained"
            startIcon={<EditIcon />}
          >
            Edit Content
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContentManagement;