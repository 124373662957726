import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Card,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
  Chip,
  IconButton,
  Collapse,
  Tooltip,
  Button,
  LinearProgress,
  Divider,
  useTheme
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  Article as ArticleIcon,
  OndemandVideo as VideoIcon,
  Quiz as QuizIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as UncheckedIcon,
  Info as InfoIcon,
  Lock as LockIcon,
  LockOpen as UnlockIcon
} from '@mui/icons-material';

import { 
  listStudentCourseFlows, 
  getStudentProgressReport, 
  markContentComplete 
} from '../api';

import ContentViewer from './ContentViewer'; // The component we created earlier

const ContentTypeIcon = ({ type, completed }) => {
  const theme = useTheme();
  const color = completed ? theme.palette.success.main : theme.palette.primary.main;

  switch (type) {
    case 'VIDEO':
      return <VideoIcon sx={{ color }} />;
    case 'TEXT':
      return <ArticleIcon sx={{ color }} />;
    case 'QUIZ':
      return <QuizIcon sx={{ color }} />;
    default:
      return null;
  }
};

const ProgressChip = ({ value }) => {
  let color = 'default';
  if (value === 100) color = 'success';
  else if (value > 50) color = 'primary';
  else if (value > 0) color = 'warning';

  return (
    <Chip
      label={`${Math.round(value)}% Complete`}
      color={color}
      variant="outlined"
      size="small"
    />
  );
};

const FlowProgress = ({ flow }) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: '100%', mb: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
        <Typography variant="caption" color="text.secondary">
          Progress
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {Math.round(flow.completion_percentage)}%
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={flow.completion_percentage}
        sx={{
          height: 8,
          borderRadius: 4,
          bgcolor: alpha(theme.palette.primary.main, 0.1),
          '& .MuiLinearProgress-bar': {
            borderRadius: 4,
            bgcolor: theme.palette.primary.main,
          }
        }}
      />
    </Box>
  );
};

const CourseFlowProgress = () => {
  const theme = useTheme();
  const [flows, setFlows] = useState([]);
  const [progressData, setProgressData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const [expandedFlows, setExpandedFlows] = useState({});
  const [selectedContent, setSelectedContent] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Fetch data
  useEffect(() => {
    fetchData();
  }, [refreshTrigger]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const flowsResponse = await listStudentCourseFlows();
      setFlows(flowsResponse.data);

      const progressPromises = flowsResponse.data.map(flow => 
        getStudentProgressReport(flow.id)
      );
      const progressResponses = await Promise.all(progressPromises);
      
      const progressMap = {};
      progressResponses.forEach((response, index) => {
        progressMap[flowsResponse.data[index].id] = response.data;
      });
      setProgressData(progressMap);
    } catch (err) {
      setError('Failed to load course flow data');
      console.error('Error fetching course flow data:', err);
    } finally {
      setLoading(false);
    }
  };

  // Handle content completion
  const handleContentComplete = async (flowId, flowItemId, contentId) => {
    try {
      // Log the IDs being sent
      console.log('Marking content complete:', {
        flowId,
        flowItemId,
        contentId
      });
  
      if (!flowItemId || !contentId) {
        console.error('Missing required IDs:', { flowItemId, contentId });
        setError('Invalid content or flow item ID');
        return;
      }
  
      await markContentComplete(flowId, {
        content_id: contentId,
        flow_item_id: flowItemId
      });
  
      // Log success
      console.log('Successfully marked content complete');
  
      // Refresh progress data
      const progressResponse = await getStudentProgressReport(flowId);
      setProgressData(prev => ({
        ...prev,
        [flowId]: progressResponse.data
      }));
      setRefreshTrigger(prev => prev + 1);
    } catch (err) {
      console.error('Error marking content complete:', err);
      setError('Failed to mark content as complete');
    }
  };

  // Handlers for expanding/collapsing sections
  const toggleItemExpansion = (itemId) => {
    setExpandedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  const toggleFlowExpansion = (flowId) => {
    setExpandedFlows(prev => ({
      ...prev,
      [flowId]: !prev[flowId]
    }));
  };

  // Handle content viewing
  // In CourseFlowProgress.js
  // In CourseFlowProgress.js
  const handleViewContent = async (content, flowId, itemId) => {
    try {
      setError(null);
      setLoading(true);
      
      // Log the incoming data
      console.log('Content data:', {
        content,
        flowId,
        itemId
      });
  
      const contentData = {
        content_id: content.content_id, // Get the actual content ID
        title: content.content_title,
        description: content.description || '',
        content_type: content.content_type,
        markdown_content: content.markdown_content || '',
        video_url: content.video_url || '',
        is_youtube: content.is_youtube || false,
        questions: content.questions || [],
        flowId,
        itemId,
        currentCompletionStatus: content.is_completed
      };
  
      // Log the processed data
      console.log('Prepared content data:', contentData);
  
      setSelectedContent(contentData);
    } catch (err) {
      console.error('Error loading content:', err);
      setError('Failed to load content');
    } finally {
      setLoading(false);
    }
  };

  // Check if content is available based on prerequisites
  const isContentAvailable = (flow, itemIndex, contentIndex) => {
    // First content of first item is always available
    if (itemIndex === 0 && contentIndex === 0) return true;

    const progress = progressData[flow.id];
    if (!progress) return false;

    // Check if previous content is completed
    const item = progress.items_progress[itemIndex];
    if (contentIndex > 0) {
      return item.content_progress[contentIndex - 1].is_completed;
    }

    // First content of subsequent items - check if previous item is completed
    const previousItem = progress.items_progress[itemIndex - 1];
    return previousItem.is_completed;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        My Learning Progress
      </Typography>

      {flows.map((flow) => {
        const progress = progressData[flow.id] || {
          overall_progress: { completion_percentage: 0 },
          items_progress: []
        };
        
        return (
          <Card key={flow.id} sx={{ mb: 3, p: 3 }}>
            {/* Flow Header */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                mb: 2
              }}
              onClick={() => toggleFlowExpansion(flow.id)}
            >
              <Box>
                <Typography variant="h6">
                  {flow.base_course_flow.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Assigned: {new Date(flow.assigned_at).toLocaleDateString()}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <ProgressChip value={progress.overall_progress.completion_percentage} />
                {expandedFlows[flow.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
            </Box>

            <FlowProgress flow={progress.overall_progress} />

            {/* Flow Content */}
            <Collapse in={expandedFlows[flow.id]}>
              <Stepper orientation="vertical">
                {progress.items_progress.map((item, itemIndex) => (
                  <Step 
                    key={item.item_id} 
                    active={true}
                    completed={item.is_completed}
                  >
                    <StepLabel
                      StepIconComponent={() => (
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {item.is_completed ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <UncheckedIcon color="disabled" />
                          )}
                        </Box>
                      )}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                          width: '100%'
                        }}
                        onClick={() => toggleItemExpansion(item.item_id)}
                      >
                        <Box>
                          <Typography variant="subtitle1">
                            {item.item_title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.content_progress.length} Contents
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                          <ProgressChip 
                            value={
                              (item.content_progress.filter(c => c.is_completed).length /
                              item.content_progress.length) * 100
                            }
                          />
                          <IconButton size="small">
                            {expandedItems[item.item_id] ? 
                              <ExpandLessIcon /> : 
                              <ExpandMoreIcon />
                            }
                          </IconButton>
                        </Box>
                      </Box>
                    </StepLabel>
                    <StepContent>
                      <Collapse in={expandedItems[item.item_id]}>
                      <List dense>
  {item.content_progress.map((content, contentIndex) => {
    const available = isContentAvailable(flow, itemIndex, contentIndex);
    
    // Log the content data
    console.log('Content item:', {
      contentId: content.content_id,
      title: content.content_title,
      flowItemId: item.flow_item_id
    });
    
    return (
      <ListItem
        key={content.content_id}
        sx={{
          bgcolor: 'background.paper',
          mb: 1,
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
          cursor: available ? 'pointer' : 'not-allowed',
          opacity: available ? 1 : 0.7,
          transition: 'all 0.2s'
        }}
        onClick={() => {
          if (available) {
            handleViewContent(content, flow.id, item.flow_item_id); // Use flow_item_id
          }
        }}
      >
                                <ListItemIcon>
                                  {available ? (
                                    <ContentTypeIcon 
                                      type={content.content_type}
                                      completed={content.is_completed}
                                    />
                                  ) : (
                                    <LockIcon color="disabled" />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={content.content_title}
                                  secondary={
                                    content.is_completed
                                      ? `Completed on ${new Date(content.completed_at).toLocaleDateString()}`
                                      : available 
                                        ? 'Not completed'
                                        : 'Complete previous content to unlock'
                                  }
                                />
                                {available && (
          <Tooltip title={content.is_completed ? 'Completed' : 'Mark as complete'}>
            <Checkbox
              checked={content.is_completed}
              disabled={content.is_completed}
              color="success"
              onClick={(e) => {
                e.stopPropagation();
                if (!content.is_completed) {
                  // Log the data being passed
                  console.log('Checkbox onClick:', {
                    flowId: flow.id,
                    itemId: item.flow_item_id,
                    contentId: content.content_id
                  });
                  handleContentComplete(flow.id, item.flow_item_id, content.content_id);
                }
              }}
            />
          </Tooltip>
        )}
      </ListItem>
    );
  })}
</List>
                      </Collapse>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Collapse>
          </Card>
        );
      })}

      {flows.length === 0 && (
        <Alert severity="info">
          No course flows are currently assigned to you.
        </Alert>
      )}

      {/* Content Viewer Dialog */}
      {selectedContent && (
  <ContentViewer
    open={Boolean(selectedContent)}
    onClose={() => setSelectedContent(null)}
    content={selectedContent}
    onComplete={() => {
      // Log what we're passing to handleContentComplete
      console.log('ContentViewer onComplete:', {
        flowId: selectedContent.flowId,
        itemId: selectedContent.itemId,
        contentId: selectedContent.content_id // Make sure we use content_id here
      });
      
      handleContentComplete(
        selectedContent.flowId,
        selectedContent.itemId,
        selectedContent.content_id // Use content_id instead of id
      );
      setSelectedContent(null);
    }}
    currentCompletionStatus={selectedContent.currentCompletionStatus}
  />
)}
    </Box>
  );
};

export default CourseFlowProgress;