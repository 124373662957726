import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Input,
  Snackbar,
  Alert,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { updateTrainer, updateTrainerUser } from '../api';

// Styled components
const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const GlassInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '8px',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.common.white,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.common.white,
  },
}));

const GlassSelect = styled(Select)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '8px',
  color: theme.palette.common.white,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.5)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiSelect-icon': {
    color: theme.palette.common.white,
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

function AddEditTrainerModal({ open, onClose, trainer, onSave }) {
  const [formData, setFormData] = useState({
    phone: '',
    contract_type: '',
    approved_hours: '',
    salary: '',
    bank_name: '',
    account_number: '',
    ifsc_code: '',
    google_meet_link: '',
    zoom_meeting_link: ''
  });
  
  const [qrImage, setQrImage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    if (trainer) {
      setFormData({
        phone: trainer.user?.phone || '',
        contract_type: trainer.contract_type || '',
        approved_hours: trainer.approved_hours || '',
        salary: trainer.salary || '',
        bank_name: trainer.bank_name || '',
        account_number: trainer.account_number || '',
        ifsc_code: trainer.ifsc_code || '',
        google_meet_link: trainer.google_meet_link || '',
        zoom_meeting_link: trainer.zoom_meeting_link || ''
      });
      setQrImage(null); // Reset QR image when trainer changes
    }
  }, [trainer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleQrImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setQrImage(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      if (!trainer?.user?.id || !trainer?.id) {
        throw new Error('Invalid trainer data');
      }

      // Prepare user data
      const userDataToSend = {};
      if (formData.phone !== trainer.user.phone) {
        userDataToSend.phone = formData.phone;
      }

      // First update user data if needed
      if (Object.keys(userDataToSend).length > 0) {
        console.log('Updating user data:', userDataToSend);
        await updateTrainerUser(trainer.user.id, userDataToSend);
      }

      // Prepare trainer data
      const trainerDataToSend = new FormData();
      
      // Add all fields to FormData
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== 'phone') { // Exclude user fields
          trainerDataToSend.append(key, value);
        }
      });

      // Add QR image if changed
      if (qrImage) {
        trainerDataToSend.append('upi_qr_image', qrImage);
      }

      // Update trainer data
      console.log('Updating trainer data', Object.fromEntries(trainerDataToSend));
      await updateTrainer(trainer.id, trainerDataToSend);

      // Update local state
      const updatedTrainer = {
        ...trainer,
        ...Object.fromEntries(trainerDataToSend),
        user: {
          ...trainer.user,
          ...userDataToSend
        }
      };

      onSave(updatedTrainer);
      setSnackbar({
        open: true,
        message: 'Trainer updated successfully',
        severity: 'success'
      });
      onClose();

    } catch (error) {
      console.error('Error saving trainer:', error);
      setSnackbar({
        open: true,
        message: `Error: ${error.message}`,
        severity: 'error'
      });
    }
  };

  return (
    <GlassDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ color: 'white' }}>
        {trainer ? 'Edit Trainer' : 'Add Trainer'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <GlassInput
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel sx={{ color: 'white' }}>Contract Type</InputLabel>
              <GlassSelect
                name="contract_type"
                value={formData.contract_type}
                onChange={handleChange}
                label="Contract Type"
              >
                <MenuItem value="SALARIED">Salaried</MenuItem>
                <MenuItem value="FREELANCER">Freelancer</MenuItem>
              </GlassSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GlassInput
              fullWidth
              label="Approved Hours"
              name="approved_hours"
              type="number"
              value={formData.approved_hours}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GlassInput
              fullWidth
              label="Salary"
              name="salary"
              type="number"
              value={formData.salary}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GlassInput
              fullWidth
              label="Bank Name"
              name="bank_name"
              value={formData.bank_name}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GlassInput
              fullWidth
              label="Account Number"
              name="account_number"
              value={formData.account_number}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GlassInput
              fullWidth
              label="IFSC Code"
              name="ifsc_code"
              value={formData.ifsc_code}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GlassInput
              fullWidth
              label="Google Meet Link"
              name="google_meet_link"
              value={formData.google_meet_link}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GlassInput
              fullWidth
              label="Zoom Meeting Link"
              name="zoom_meeting_link"
              value={formData.zoom_meeting_link}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ color: 'white', mb: 1 }}>
              UPI QR Code
            </Typography>
            <Input
              type="file"
              onChange={handleQrImageChange}
              accept="image/*"
              sx={{ color: 'white' }}
            />
            {trainer?.upi_qr_image && (
              <Box mt={1}>
                <img 
                  src={trainer.upi_qr_image} 
                  alt="Current UPI QR Code" 
                  style={{ maxWidth: '100px', maxHeight: '100px' }} 
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <GradientButton onClick={onClose}>
          Cancel
        </GradientButton>
        <GradientButton onClick={handleSave}>
          {trainer ? 'Update' : 'Add'}
        </GradientButton>
      </DialogActions>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassDialog>
  );
}

export default AddEditTrainerModal;