import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, CircularProgress, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoginPage from './LoginPage';
import AdminDashboard from './pages/AdminDashboard';
import TrainerDashboard from './pages/TrainerDashboard';
import StudentDashboard from './pages/StudentDashboard';
import CoursesPage from './pages/CoursesPage';
import StudentsPage from './pages/StudentsPage';
import TrainersPage from './pages/TrainersPage';
import CoordinatorsPage from './pages/CoordinatorsPage';
import TopNavBar from './components/TopNavBar';
import { getCurrentUser } from './api';
import customDarkTheme from './theme';
import { startTokenValidityCheck, checkTokenValidity } from './api';
import TeamUpdatesPage from './pages/TeamUpdatesPage';
import ClassRecordingsPage from './pages/ClassRecordingsPage';
import MyEmploymentPage from './pages/MyEmploymentPage';
import ManagersDashboard from './pages/ManagersDashboard';
import AllotmentPage from './pages/AllotmentPage';
import CourseCompletionPage from './pages/CourseCompletionPage';
import StudentDetails from './pages/StudentDetails';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import CourseManagementPage from './pages/CourseManagement';
import CourseFlowProgress from './components/CourseFlowProgress';
import CompetitionsPage from './pages/CompetitionsPage';
import CompetitionQuiz from './components/CompetitionQuiz';
import StudentCompetitionsPage from './pages/StudentCompetitionsPage';

import PropTypes from 'prop-types'
import { useMediaQuery, useTheme } from '@mui/material';
import MobileStudentDashboard from './pages/MobileStudentDashboard';






startTokenValidityCheck();




const ProtectedRoute = ({ children, allowedRoles }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userRole: null,
    loading: true,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const isValid = await checkTokenValidity();
        if (isValid) {
          const userResponse = await getCurrentUser();
          const userRole = userResponse.data.role;
          setAuthState({
            isAuthenticated: true,
            userRole: userRole,
            loading: false,
          });
        } else {
          throw new Error('Token is invalid');
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        localStorage.removeItem('token');
        setAuthState({ isAuthenticated: false, userRole: null, loading: false });
        navigate('/login', { replace: true, state: { from: location } });
      }
    } else {
      setAuthState({ isAuthenticated: false, userRole: null, loading: false });
      navigate('/login', { replace: true, state: { from: location } });
    }
  }, [navigate, location]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (authState.loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!authState.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles && !allowedRoles.includes(authState.userRole)) {
    return <Navigate to="/" replace />;
  }

  return children;
};



const DRAWER_WIDTH = 300;
const COLLAPSED_DRAWER_WIDTH = 64;

const StyledContainer = styled('div', { 
  shouldForwardProp: (prop) => prop !== 'open' 
})(({ theme, open, isMobileStudent }) => ({
  width: '100%',
  minHeight: '100vh',
  background: '#0f172a',
  backgroundImage: 'none',
  paddingLeft: isMobileStudent ? 0 : theme.spacing(3),
  paddingRight: isMobileStudent ? 0 : theme.spacing(3),
  paddingTop: isMobileStudent ? 0 : theme.spacing(3),
  paddingBottom: isMobileStudent ? 0 : theme.spacing(3),
  position: 'relative',
  zIndex: 0,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('md')]: {
    marginLeft: isMobileStudent ? 0 : (open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH),
    width: isMobileStudent ? '100%' : `calc(100% - ${open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH}px)`,
  },
}));

const AppContent = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userRole: null,
    loading: true,
  });
  const [desktopOpen, setDesktopOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine if the current user is a student on mobile
  const isMobileStudent = 
    authState.userRole === 'STUDENT' && 
    isMobile;

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    setAuthState({
      isAuthenticated: false,
      userRole: null,
      loading: false,
    });
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const isValid = await checkTokenValidity();
          if (isValid) {
            const userResponse = await getCurrentUser();
            const userRole = userResponse.data.role;
            setAuthState({
              isAuthenticated: true,
              userRole: userRole,
              loading: false,
            });
          } else {
            throw new Error('Token is invalid');
          }
        } catch (error) {
          console.error('Authentication failed:', error);
          localStorage.removeItem('token');
          setAuthState({ isAuthenticated: false, userRole: null, loading: false });
          navigate('/login');
        }
      } else {
        setAuthState({ isAuthenticated: false, userRole: null, loading: false });
      }
    };

    checkAuth();
  }, [navigate]);
  if (authState.loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {/* Only show TopNavBar if user is authenticated AND is not a mobile student */}
      {authState.isAuthenticated && !isMobileStudent && (
        <TopNavBar 
          userRole={authState.userRole} 
          onLogout={handleLogout}
          desktopOpen={desktopOpen}
          setDesktopOpen={setDesktopOpen}
        />
      )}
      <StyledContainer open={desktopOpen} isMobileStudent={isMobileStudent}>
        <Routes>
          <Route path="/login" element={<LoginPage setAuthState={setAuthState} />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                {authState.userRole === 'ADMIN' ? <AdminDashboard /> :
                 authState.userRole === 'MANAGER' ? <ManagersDashboard /> :
                 authState.userRole === 'TRAINER' ? <TrainerDashboard /> :
                 authState.userRole === 'STUDENT' ? (
                   isMobile ? <MobileStudentDashboard onLogout={handleLogout} /> : <StudentDashboard />
                 ) :
                 <Navigate to="/login" replace />}
              </ProtectedRoute>
            }
          />
          <Route
            path="/courses"
            element={
              <ProtectedRoute>
                <CoursesPage />
              </ProtectedRoute>
            }
          />
           <Route
            path="/allotment"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <AllotmentPage />
              </ProtectedRoute>
            }
          /><Route path="/course-progress" element={<CourseFlowProgress />} />
          <Route
            path="/students"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <StudentsPage />
              </ProtectedRoute>
            }
          /> <Route
          path="/competitions"
          element={
            <ProtectedRoute>
              {authState.userRole === 'STUDENT' ? (
                isMobile ? 
                  <MobileStudentDashboard activeTab={2} onLogout={handleLogout} /> : 
                  <StudentCompetitionsPage />
              ) : (
                <CompetitionsPage />
              )}
            </ProtectedRoute>
          }
        /><Route
        path="/competitions/:id/quiz"
        element={
          <ProtectedRoute>
            <CompetitionQuiz />
          </ProtectedRoute>
        }
      />
          <Route
            path="/trainers"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <TrainersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/trainers/:id"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <TrainersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student-details/:studentId"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}>
                <StudentDetails />
              </ProtectedRoute>
            }
          /><Route
          path="/course-management"
          element={
            <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}>
              <CourseManagementPage />
            </ProtectedRoute>
          }
        />
          <Route
            path="/course-completion"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <CourseCompletionPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/course-management"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <CourseCompletionPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/coordinators"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <CoordinatorsPage />
              </ProtectedRoute>
            }
          />
         
          <Route
            path="/recordings"
            element={
              <ProtectedRoute>
                <ClassRecordingsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/team-updates"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}>
                <TeamUpdatesPage />
              </ProtectedRoute>
            }
          /> <Route path="reset-password/:uid/:token" element={<PasswordResetConfirm />} /> {/* Add this route without leading slash */}

          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/my-employment" element={<MyEmploymentPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        </StyledContainer>
    </>
  );
};

TopNavBar.propTypes = {
  userRole: PropTypes.oneOf(['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT']).isRequired,
  onLogout: PropTypes.func.isRequired,
  children: PropTypes.node,
  desktopOpen: PropTypes.bool.isRequired,
  setDesktopOpen: PropTypes.func.isRequired,
};

const App = () => {
  return (
    <ThemeProvider theme={customDarkTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Box
          sx={{
            background: '#0f172a !important', // Force dark background
            backgroundImage: 'none !important', // Prevent any gradients
            minHeight: '100vh',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
        />
        <Router>
          <AppContent />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;