// components/WorkingWindowModal.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';

const GlassModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: theme.palette.common.white,
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const WorkingWindowModal = ({ open, onClose, windows, onSave, onDelete }) => {
  const [newWindow, setNewWindow] = useState({
    start_time: dayjs().startOf('day'),
    end_time: dayjs().startOf('day'),
  });

  const handleAdd = () => {
    onSave({
      start_time: newWindow.start_time.format('HH:mm'),
      end_time: newWindow.end_time.format('HH:mm'),
    });
    setNewWindow({
      start_time: dayjs().startOf('day'),
      end_time: dayjs().startOf('day'),
    });
  };

  const isValidWindow = () => {
    return newWindow.start_time.isBefore(newWindow.end_time);
  };

  return (
    <GlassModal open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Manage Working Windows</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Set your preferred working hours. You can add multiple time windows for each day.
        </Typography>
        
        <Box display="flex" gap={2} alignItems="center">
          <TimePicker
            label="Start Time"
            value={newWindow.start_time}
            onChange={(newValue) => setNewWindow(prev => ({ ...prev, start_time: newValue }))}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="End Time"
            value={newWindow.end_time}
            onChange={(newValue) => setNewWindow(prev => ({ ...prev, end_time: newValue }))}
            renderInput={(params) => <TextField {...params} />}
          />
          <GradientButton 
            onClick={handleAdd}
            disabled={!isValidWindow()}
          >
            Add Window
          </GradientButton>
        </Box>

        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {windows.map((window) => (
                <TableRow key={window.id}>
                  <TableCell>{dayjs(window.start_time, 'HH:mm').format('hh:mm A')}</TableCell>
                  <TableCell>{dayjs(window.end_time, 'HH:mm').format('hh:mm A')}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => onDelete(window.id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </DialogContent>
      <DialogActions>
        <GradientButton onClick={onClose}>Close</GradientButton>
      </DialogActions>
    </GlassModal>
  );
};

export default WorkingWindowModal;