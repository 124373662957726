import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, IconButton, Avatar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Menu as MenuIcon, Notifications as NotificationsIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { getImageUrl } from '../api';

const COLLAPSE_THRESHOLD = 5;
const TRANSITION_DURATION = '0.3s';

const DynamicBanner = styled(Box)(({ theme, isCollapsed }) => ({
  background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), 
               url(https://nationalinstituteoflanguage.in/wp-content/uploads/2024/11/ENGLISH-Quiz-1.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderBottomLeftRadius: 24,
  borderBottomRightRadius: 24,
  padding: '12px 16px',
  position: 'sticky',
  top: 0,
  zIndex: 1100,
  boxShadow: `0 1px 10px rgba(0,0,0,0.2),
               0 5px 15px rgba(74, 144, 226, 0.5)`,
  marginBottom: 30,
  overflow: 'hidden',
  transform: 'translateZ(0)',
  backfaceVisibility: 'hidden',
  perspective: 1000,

  willChange: 'transform, height',
  height: isCollapsed ? '110px' : '420px',
  transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(10, 15, 36, 0.85)',
    opacity: isCollapsed ? 0.95 : 0.7,
    transition: `opacity ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
    willChange: 'opacity'
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '80px',
    background: 'rgba(10, 15, 36, 0.95)',
    opacity: isCollapsed ? 1 : 0,
    transition: `opacity ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
    zIndex: -1
  }
}));

const HeaderContent = styled(Box)({
  position: 'relative',
  zIndex: 2,
  transform: 'translateZ(0)',
  backfaceVisibility: 'hidden'
});

const ProfileSection = styled(Box)(({ isCollapsed }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  transform: 'translateZ(0)',
  transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
  willChange: 'transform',
  position: 'relative',
  zIndex: 3
}));

const StyledAvatar = styled(Avatar)(({ isCollapsed }) => ({
  width: isCollapsed ? '36px' : '80px',
  height: isCollapsed ? '36px' : '80px',
  border: '3px solid #4A90E2',
  boxShadow: '0 0 15px rgba(74,144,226,0.3)',
  transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
  willChange: 'width, height'
}));

const InfoGrid = styled(Box)(({ isCollapsed }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '16px',
  opacity: isCollapsed ? 0 : 1,
  transform: `scale(${isCollapsed ? 0.95 : 1}) translateY(${isCollapsed ? -20 : 0}px)`,
  maxHeight: isCollapsed ? '0' : '1000px',
  overflow: 'hidden',
  transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
  transitionDelay: isCollapsed ? '0s' : '0.1s',
  transformOrigin: 'top',
  marginTop: '24px',
  visibility: isCollapsed ? 'hidden' : 'visible',
  position: 'relative',
  willChange: 'transform, opacity, max-height',
  pointerEvents: isCollapsed ? 'none' : 'auto'
}));

const InfoItem = styled(Box)({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  padding: '12px',
  borderRadius: '8px',
  transform: 'translateZ(0)',
  backfaceVisibility: 'hidden'
});

const TextContainer = styled(Box)(({ isCollapsed }) => ({
  flex: 1,
  transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
  transform: 'translateZ(0)',
  willChange: 'transform',
  '& .email-text': {
    opacity: isCollapsed ? 0 : 1,
    maxHeight: isCollapsed ? '0' : '20px',
    overflow: 'hidden',
    transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
    marginTop: isCollapsed ? 0 : '4px'
  }
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'rgba(74, 144, 226, 0.1)',
  border: '1px solid rgba(74, 144, 226, 0.3)',
  color: '#4A90E2',
  zIndex: 4,
  transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
  '&:hover': {
    backgroundColor: 'rgba(74, 144, 226, 0.2)',
    transform: 'translateX(-50%) scale(1.1)',
  },
  animation: 'fadeIn 0.3s ease-in-out',
  '@keyframes fadeIn': {
    '0%': { 
      opacity: 0,
      transform: 'translateX(-50%) scale(0.9)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(-50%) scale(1)'
    }
  }
}));

const EnhancedProfileBanner = ({
  studentData,
  notifications,
  handleMenuOpen,
  setNotificationPopperOpen,
  setNotificationIconRef
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [forceExpand, setForceExpand] = useState(false);

  const handleScroll = useCallback(() => {
    if (!forceExpand) {
      requestAnimationFrame(() => {
        setIsCollapsed(window.scrollY > COLLAPSE_THRESHOLD);
      });
    }
  }, [forceExpand]);

  useEffect(() => {
    let rafId;
    let lastScrollY = 0;
    let lastScrollTime = Date.now();
    
    const scrollHandler = () => {
      const now = Date.now();
      if (now - lastScrollTime < 16) {
        return;
      }
      
      if (rafId) return;
      
      rafId = requestAnimationFrame(() => {
        const currentScrollY = window.scrollY;
        if (Math.abs(currentScrollY - lastScrollY) > 5) {
          handleScroll();
          lastScrollY = currentScrollY;
          lastScrollTime = now;
        }
        rafId = null;
      });
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
    };
  }, [handleScroll]);

  const handleExpandClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setForceExpand(true);
    setIsCollapsed(false);
    window.scrollTo({ 
      top: 0, 
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (forceExpand && window.scrollY > COLLAPSE_THRESHOLD) {
      const timeout = setTimeout(() => {
        setForceExpand(false);
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [forceExpand]);

  return (
    <DynamicBanner isCollapsed={isCollapsed}>
      <HeaderContent>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography 
            variant="h6" 
            sx={{ 
              color: '#4A90E2',
              fontSize: isCollapsed ? '0.9rem' : '1.25rem',
              transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
              transform: 'translateZ(0)',
              willChange: 'font-size'
            }}
          >
            Student Dashboard
          </Typography>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => setNotificationPopperOpen(true)}
              sx={{ 
                color: '#4A90E2', 
                mr: 1,
                padding: isCollapsed ? '4px' : '8px',
                transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
              }}
              ref={setNotificationIconRef}
            >
              <Badge badgeContent={notifications.unread.length} color="secondary">
                <NotificationsIcon sx={{ 
                  fontSize: isCollapsed ? '1.2rem' : '1.5rem',
                  transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
                }} />
              </Badge>
            </IconButton>
            <IconButton 
              onClick={handleMenuOpen} 
              sx={{ 
                color: '#4A90E2',
                padding: isCollapsed ? '4px' : '8px',
                transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
              }}
            >
              <MenuIcon sx={{ 
                fontSize: isCollapsed ? '1.2rem' : '1.5rem',
                transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
              }} />
            </IconButton>
          </Box>
        </Box>

        <ProfileSection isCollapsed={isCollapsed}>
          <StyledAvatar
            src={getImageUrl(studentData?.profile_image)}
            alt={`${studentData?.first_name} ${studentData?.last_name}`}
            isCollapsed={isCollapsed}
          />
          <TextContainer isCollapsed={isCollapsed}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: 'white',
                fontWeight: 'bold',
                lineHeight: 1.5,
                fontSize: isCollapsed ? '0.9rem' : '1.25rem',
                transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
                willChange: 'font-size'
              }}
            >
              {`${studentData?.first_name || ''} ${studentData?.last_name || ''}`}
            </Typography>
            <Typography 
              variant="body2" 
              className="email-text"
              sx={{ 
                color: 'rgba(255,255,255,0.7)',
                fontSize: '0.875rem',
                lineHeight: 1.4
              }}
            >
              {studentData?.email}
            </Typography>
          </TextContainer>
        </ProfileSection>

        <InfoGrid isCollapsed={isCollapsed}>
          <InfoItem>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              Phone
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {studentData?.phone || 'Not provided'}
            </Typography>
          </InfoItem>

          <InfoItem>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              City
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {studentData?.city || 'Not provided'}
            </Typography>
          </InfoItem>

          <InfoItem>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              Username
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {studentData?.username || 'Not provided'}
            </Typography>
          </InfoItem>

          <InfoItem>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              Date of Birth
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {studentData?.date_of_birth || 'Not provided'}
            </Typography>
          </InfoItem>

          <InfoItem sx={{ gridColumn: '1 / -1' }}>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              About Me
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'white',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: 1.4
              }}
            >
              {studentData?.about_me || 'No information provided'}
            </Typography>
          </InfoItem>
        </InfoGrid>

        {isCollapsed && (
          <ExpandButton 
            onClick={handleExpandClick} 
            size="small"
          >
            <ExpandMoreIcon sx={{ transform: 'rotate(180deg)' }} />
          </ExpandButton>
        )}
      </HeaderContent>
    </DynamicBanner>
  );
};

EnhancedProfileBanner.propTypes = {
  studentData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    city: PropTypes.string,
    date_of_birth: PropTypes.string,
    username: PropTypes.string,
    about_me: PropTypes.string,
    profile_image: PropTypes.string
  }),
  notifications: PropTypes.shape({
    unread: PropTypes.array.isRequired
  }).isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
  setNotificationPopperOpen: PropTypes.func.isRequired,
  setNotificationIconRef: PropTypes.func.isRequired
};

export default React.memo(EnhancedProfileBanner);