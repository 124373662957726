import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  LinearProgress,
  Collapse,
  Tooltip,
  Chip,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  CheckCircle as CheckCircleIcon,
  Article as ArticleIcon,
  OndemandVideo as VideoIcon,
  Quiz as QuizIcon,
  Timeline as TimelineIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ContentViewer from '../components/ContentViewer';
import { 
  listStudentCourseFlows,
  getStudentProgressReport,
  markContentComplete
} from '../api';

const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '15px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
  '&:disabled': {
    background: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

const ContentIcon = ({ type, completed }) => {
  const iconProps = {
    color: completed ? 'success' : 'primary',
    fontSize: 'small'
  };

  switch (type) {
    case 'VIDEO':
      return <VideoIcon {...iconProps} />;
    case 'TEXT':
      return <ArticleIcon {...iconProps} />;
    case 'QUIZ':
      return <QuizIcon {...iconProps} />;
    default:
      return null;
  }
};

const DashboardCourseFlow = ({ studentId }) => {
  const [flows, setFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedFlow, setExpandedFlow] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  
  useEffect(() => {
    const fetchFlows = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await listStudentCourseFlows();
        const flowsWithProgress = await Promise.all(
          response.data.map(async (flow) => {
            const progressResponse = await getStudentProgressReport(flow.id);
            return {
              ...flow,
              progress: progressResponse.data
            };
          })
        );
        setFlows(flowsWithProgress);
      } catch (error) {
        console.error('Error fetching course flows:', error);
        setError('Failed to load course progress. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchFlows();
  }, []);

  const handleContentClick = (content, flowId, itemId) => {
    setSelectedContent({
      content_id: content.content_id,
      title: content.content_title,
      description: content.description || '',
      content_type: content.content_type,
      markdown_content: content.markdown_content || '',
      video_url: content.video_url || '',
      is_youtube: content.is_youtube || false,
      questions: content.questions || [],
      flowId,
      itemId,
      currentCompletionStatus: content.is_completed
    });
  };

  const handleContentComplete = async (flowId, itemId, contentId) => {
    try {
      await markContentComplete(flowId, {
        content_id: contentId,
        flow_item_id: itemId
      });
      
      // Refresh the flows data
      const response = await listStudentCourseFlows();
      const flowsWithProgress = await Promise.all(
        response.data.map(async (flow) => {
          const progressResponse = await getStudentProgressReport(flow.id);
          return {
            ...flow,
            progress: progressResponse.data
          };
        })
      );
      setFlows(flowsWithProgress);
    } catch (error) {
      console.error('Error marking content complete:', error);
      setError('Failed to mark content as complete. Please try again.');
    }
  };

  if (loading) {
    return (
      <GlassCard>
        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center" p={3}>
            <CircularProgress />
          </Box>
        </CardContent>
      </GlassCard>
    );
  }

  if (error) {
    return (
      <GlassCard>
        <CardContent>
          <Alert severity="error">{error}</Alert>
        </CardContent>
      </GlassCard>
    );
  }

  return (
    <GlassCard>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Course Progress</Typography>
          <GradientButton
            component={Link}
            to="/course-progress"
            startIcon={<TimelineIcon />}
          >
            View Full Progress
          </GradientButton>
        </Box>

        {flows.length === 0 ? (
          <Alert severity="info">No course flows are currently assigned to you.</Alert>
        ) : (
          flows.map((flow) => (
            <Box key={flow.id} sx={{ mb: 2 }}>
              <Box
                onClick={() => setExpandedFlow(expandedFlow === flow.id ? null : flow.id)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  p: 1
                }}
              >
                <Box>
                  <Typography variant="subtitle1">
                    {flow.base_course_flow.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {flow.progress.overall_progress.completion_percentage}% Complete
                  </Typography>
                </Box>
                <IconButton size="small">
                  {expandedFlow === flow.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>

              <LinearProgress
                variant="determinate"
                value={flow.progress.overall_progress.completion_percentage}
                sx={{ mb: 1, height: 8, borderRadius: 4 }}
              />

              <Collapse in={expandedFlow === flow.id}>
                <List dense>
                  {flow.progress.items_progress.slice(0, 3).map((item) => (
                    <Box key={item.item_id}>
                      <Typography variant="subtitle2" sx={{ mt: 1, mb: 0.5 }}>
                        {item.item_title}
                      </Typography>
                      {item.content_progress.slice(0, 2).map((content) => (
                        <ListItem
                          key={content.content_id}
                          onClick={() => handleContentClick(content, flow.id, item.flow_item_id)}
                          sx={{
                            bgcolor: 'background.paper',
                            mb: 0.5,
                            borderRadius: 1,
                            cursor: 'pointer',
                            '&:hover': { bgcolor: 'action.hover' }
                          }}
                        >
                          <ListItemIcon>
                            <ContentIcon
                              type={content.content_type}
                              completed={content.is_completed}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={content.content_title}
                            secondary={content.is_completed ? 'Completed' : 'In Progress'}
                          />
                          {content.is_completed && (
                            <Chip
                              size="small"
                              icon={<CheckCircleIcon />}
                              label="Complete"
                              color="success"
                              variant="outlined"
                            />
                          )}
                        </ListItem>
                      ))}
                    </Box>
                  ))}
                </List>
              </Collapse>
            </Box>
          ))
        )}

        {selectedContent && (
          <ContentViewer
            open={Boolean(selectedContent)}
            onClose={() => setSelectedContent(null)}
            content={selectedContent}
            onComplete={() => {
              handleContentComplete(
                selectedContent.flowId,
                selectedContent.itemId,
                selectedContent.content_id
              );
              setSelectedContent(null);
            }}
            currentCompletionStatus={selectedContent.currentCompletionStatus}
          />
        )}
      </CardContent>
    </GlassCard>
  );
};

export default DashboardCourseFlow;