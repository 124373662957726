import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Divider,
  Tooltip,
  LinearProgress
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Article as ArticleIcon,
  OndemandVideo as VideoIcon,
  QuestionMark as QuizIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  PlayArrow as PlayIcon
} from '@mui/icons-material';
import { submitQuiz, getQuizAttempts } from '../api';

import PropTypes from 'prop-types'

const ContentViewer = ({
  open,
  onClose,
  content,
  onComplete,
  currentCompletionStatus
}) => {
  // State Management
  const [quizState, setQuizState] = useState({
    answers: {},
    submitted: false,
    results: null,
    attempts: [],
    loading: false,
    error: null,
    bestScore: 0
  });

  // Load quiz attempts on mount
  useEffect(() => {
    if (content?.content_type === 'QUIZ') {
      loadQuizAttempts();
    }
    // Cleanup on unmount
    return () => resetQuizState();
  }, [content]);

  const resetQuizState = () => {
    setQuizState({
      answers: {},
      submitted: false,
      results: null,
      attempts: [],
      loading: false,
      error: null,
      bestScore: 0
    });
  };

  const loadQuizAttempts = async () => {
    try {
      const response = await getQuizAttempts(content.content_id);
      const attempts = response.data;
      const bestScore = attempts.length > 0 
        ? Math.max(...attempts.map(a => a.score))
        : 0;

      setQuizState(prev => ({
        ...prev,
        attempts,
        bestScore
      }));
    } catch (err) {
      console.error('Error loading quiz attempts:', err);
      setQuizState(prev => ({
        ...prev,
        error: 'Failed to load previous attempts'
      }));
    }
  };

  // Quiz Handling Functions
  const handleQuizAnswer = (questionId, optionId) => {
    setQuizState(prev => ({
      ...prev,
      answers: {
        ...prev.answers,
        [questionId]: optionId
      }
    }));
  };

  const handleQuizSubmit = async () => {
    try {
      setQuizState(prev => ({ ...prev, loading: true, error: null }));
  
      const formattedAnswers = Object.entries(quizState.answers).map(([questionId, optionId]) => ({
        question_id: parseInt(questionId),
        option_id: parseInt(optionId)
      }));
  
      const response = await submitQuiz(content.flowId, {
        content_id: content.content_id,
        flow_item_id: content.itemId,
        answers: formattedAnswers
      });
  
      setQuizState(prev => ({
        ...prev,
        results: response.data,
        submitted: true,
        loading: false
      }));
  
      if (response.data.passed) {
        onComplete();
      }
    } catch (err) {
      console.error('Error submitting quiz:', err);
      setQuizState(prev => ({
        ...prev,
        loading: false,
        error: err.response?.data?.error || 'Failed to submit quiz'
      }));
    }
  };

  const handleRetryQuiz = () => {
    setQuizState(prev => ({
      ...prev,
      answers: {},
      submitted: false,
      results: null,
      error: null
    }));
  };

  // Content Type Renderers
  const renderTextContent = () => (
    <Box sx={{
      '& img': { maxWidth: '100%', height: 'auto' },
      '& table': { width: '100%', borderCollapse: 'collapse', my: 2 },
      '& th, & td': { border: '1px solid', borderColor: 'divider', p: 2 },
      '& code': {
        bgcolor: 'grey.100',
        p: 0.5,
        borderRadius: 1,
        fontSize: '0.875em'
      },
      '& pre': {
        bgcolor: 'grey.100',
        p: 2,
        borderRadius: 1,
        overflow: 'auto'
      }
    }}>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {content.markdown_content}
      </ReactMarkdown>
    </Box>
  );

  const renderVideoContent = () => (
    <Box sx={{ width: '100%', aspectRatio: '16/9', mb: 2 }}>
      {content.is_youtube ? (
        <iframe
          width="100%"
          height="100%"
          src={content.video_url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : (
        <video
          width="100%"
          height="100%"
          controls
          src={content.video_url}
        />
      )}
    </Box>
  );

  const renderQuizProgress = () => {
    const answeredCount = Object.keys(quizState.answers).length;
    const totalQuestions = content.questions?.length || 0;
    const progress = (answeredCount / totalQuestions) * 100;

    return (
      <Box sx={{ mt: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" color="text.secondary">
            Progress: {answeredCount} of {totalQuestions} questions answered
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {progress.toFixed(0)}%
          </Typography>
        </Box>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    );
  };

  const renderQuizContent = () => {
    if (!content.questions) {
      return <Typography>No questions available</Typography>;
    }

    return (
      <Box>
        {quizState.attempts.length > 0 && (
          <Alert severity="info" sx={{ mb: 3 }}>
            Your best score: {quizState.bestScore}% 
            {quizState.bestScore >= 70 ? ' (Passed)' : ' (Not passed yet)'}
          </Alert>
        )}

        {!quizState.submitted ? (
          <>
            {renderQuizProgress()}
            
            {content.questions.map((question, qIndex) => (
              <Card key={question.id} sx={{ mb: 4 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Question {qIndex + 1} of {content.questions.length}
                  </Typography>
                  
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {question.question_text}
                  </Typography>

                  {question.question_image && (
                    <Box
                      component="img"
                      src={question.question_image}
                      alt="Question illustration"
                      sx={{ maxWidth: '100%', height: 'auto', mb: 2 }}
                    />
                  )}

                  {question.question_video_url && (
                    <Box sx={{ width: '100%', aspectRatio: '16/9', mb: 2 }}>
                      <iframe
                        width="100%"
                        height="100%"
                        src={question.question_video_url}
                        frameBorder="0"
                        allowFullScreen
                      />
                    </Box>
                  )}

                  <RadioGroup
                    value={quizState.answers[question.id]?.toString() || ''}
                    onChange={(e) => handleQuizAnswer(question.id, e.target.value)}
                  >
                    {question.options.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.id.toString()}
                        control={<Radio />}
                        label={
                          <Box>
                            <Typography>{option.option_text}</Typography>
                            {option.option_image && (
                              <Box
                                component="img"
                                src={option.option_image}
                                alt="Option illustration"
                                sx={{ maxWidth: 200, height: 'auto', mt: 1 }}
                              />
                            )}
                          </Box>
                        }
                      />
                    ))}
                  </RadioGroup>
                </CardContent>
              </Card>
            ))}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                * You need 70% or higher to pass the quiz
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleQuizSubmit}
                disabled={quizState.loading || 
                  Object.keys(quizState.answers).length !== content.questions.length}
              >
                {quizState.loading ? <CircularProgress size={24} /> : 'Submit Quiz'}
              </Button>
            </Box>
          </>
        ) : (
          <Box>
            <Alert
              severity={quizState.results?.score >= 70 ? "success" : "error"}
              sx={{ mb: 3 }}
            >
              {quizState.results?.score >= 70 
                ? `Congratulations! You passed with ${quizState.results.score}%`
                : `You scored ${quizState.results.score}%. You need 70% to pass.`
              }
            </Alert>

            {/* Quiz Results Section */}
            {content.questions.map((question, index) => {
              const submission = quizState.results?.answer_submissions?.find(
                sub => sub.question === question.id
              );
              const selectedOption = question.options.find(
                opt => opt.id === submission?.selected_option
              );
              const correctOption = question.options.find(opt => opt.is_correct);

              return (
                <Card key={question.id} sx={{ mb: 2 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      {submission?.is_correct ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <ErrorIcon color="error" />
                      )}
                      <Typography variant="subtitle1">
                        Question {index + 1}: {submission?.is_correct ? 'Correct' : 'Incorrect'}
                      </Typography>
                    </Box>

                    <Typography variant="body1" gutterBottom>
                      {question.question_text}
                    </Typography>

                    {question.question_image && (
                      <Box
                        component="img"
                        src={question.question_image}
                        alt="Question illustration"
                        sx={{ maxWidth: '100%', height: 'auto', mb: 2 }}
                      />
                    )}

                    {question.question_video_url && (
                      <Box sx={{ width: '100%', aspectRatio: '16/9', mb: 2 }}>
                        <iframe
                          width="100%"
                          height="100%"
                          src={question.question_video_url}
                          frameBorder="0"
                          allowFullScreen
                        />
                      </Box>
                    )}

                    {/* Display all options with correct/incorrect indicators */}
                    {question.options.map(option => {
                      const isSelected = option.id === submission?.selected_option;
                      const isCorrect = option.is_correct;

                      return (
                        <Box
                          key={option.id}
                          sx={{
                            p: 2,
                            my: 1,
                            borderRadius: 1,
                            bgcolor: isSelected
                              ? (isCorrect ? 'success.light' : 'error.light')
                              : isCorrect
                                ? 'success.light'
                                : 'background.paper',
                            border: '1px solid',
                            borderColor: isSelected
                              ? (isCorrect ? 'success.main' : 'error.main')
                              : isCorrect
                                ? 'success.main'
                                : 'divider'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                            {isSelected && (
                              <Typography sx={{ minWidth: 100 }} color="text.secondary" variant="caption">
                                Your answer
                              </Typography>
                            )}
                            {isCorrect && (
                              <Typography sx={{ minWidth: 100 }} color="success.main" variant="caption">
                                Correct answer
                              </Typography>
                            )}
                          </Box>
                          
                          <Typography>
                            {option.option_text}
                          </Typography>

                          {option.option_image && (
                            <Box
                              component="img"
                              src={option.option_image}
                              alt="Option illustration"
                              sx={{ maxWidth: 200, height: 'auto', mt: 1 }}
                            />
                          )}
                        </Box>
                      );
                    })}

                    {question.explanation && (
                      <Box sx={{ mt: 2, bgcolor: 'grey.50', p: 2, borderRadius: 1 }}>
                        <Typography variant="subtitle2" color="primary">
                          Explanation:
                        </Typography>
                        <Typography variant="body2">
                          {question.explanation}
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              );
            })}

            {!quizState.results?.passed && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleRetryQuiz}
                startIcon={<PlayIcon />}
                sx={{ mt: 2 }}
              >
                Retry Quiz
              </Button>
            )}

            {/* Attempt History Section */}
            {quizState.attempts.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Attempt History
                </Typography>
                {quizState.attempts.map((attempt) => (
                  <Card key={attempt.id} sx={{ mb: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>
                          Attempt {attempt.attempt_number}:
                          <span style={{ 
                            color: attempt.passed ? '#2e7d32' : '#d32f2f',
                            marginLeft: '8px'
                          }}>
                            {attempt.score}%
                          </span>
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                        {new Date(attempt.created_at).toLocaleString()}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const renderContent = () => {
    switch (content?.content_type) {
      case 'TEXT':
        return renderTextContent();
      case 'VIDEO':
        return renderVideoContent();
      case 'QUIZ':
        return renderQuizContent();
      default:
        return (
          <Typography color="error">
            Unsupported content type: {content?.content_type}
          </Typography>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          {content?.content_type === 'VIDEO' && <VideoIcon />}
          {content?.content_type === 'TEXT' && <ArticleIcon />}
          {content?.content_type === 'QUIZ' && <QuizIcon />}
          <Typography>{content?.title || content?.content_title}</Typography>
          {currentCompletionStatus && (
            <Tooltip title="Content completed">
              <CheckCircleIcon color="success" />
            </Tooltip>
          )}
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        {quizState.error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setQuizState(prev => ({ ...prev, error: null }))}>
            {quizState.error}
          </Alert>
        )}

        {!content ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          renderContent()
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {!currentCompletionStatus && 
         content?.content_type !== 'QUIZ' && (
          <Button
            variant="contained"
            onClick={onComplete}
            color="primary"
          >
            Mark as Complete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ContentViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.shape({
    content_id: PropTypes.number,
    content_type: PropTypes.oneOf(['TEXT', 'VIDEO', 'QUIZ']),
    title: PropTypes.string,
    content_title: PropTypes.string,
    markdown_content: PropTypes.string,
    video_url: PropTypes.string,
    is_youtube: PropTypes.bool,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        question_text: PropTypes.string.isRequired,
        question_image: PropTypes.string,
        question_video_url: PropTypes.string,
        explanation: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            option_text: PropTypes.string.isRequired,
            option_image: PropTypes.string,
            is_correct: PropTypes.bool
          })
        ).isRequired
      })
    ),
    flowId: PropTypes.number,
    itemId: PropTypes.number
  }),
  onComplete: PropTypes.func.isRequired,
  currentCompletionStatus: PropTypes.bool
};

ContentViewer.defaultProps = {
  currentCompletionStatus: false
};

export default ContentViewer;