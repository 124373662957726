import React, { useState, useEffect, useCallback, useRef } from 'react';import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box, Tab, Tabs,
  CircularProgress,
  Alert,
  Snackbar,
  Button,
  TextField,
  Pagination,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Chip,
  Avatar,
  useMediaQuery
} from '@mui/material';
import TabPanel from '../components/TabPanel';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { 
  getDashboardStats, 
  listAttendanceReviews,
  updateAttendance,
  updateAttendanceReview,
  listStudentFeedbacks,
  respondToStudentFeedback,
  listCourseHolds,
  approveCourseHold,
  rejectCourseHold,
  listNotices,
  listLeaveRequests,
  approveLeaveRequest,
  rejectLeaveRequest,
  getLeaveRequestHistory,
  createNotice, processAttendanceReview, getUser,
  getCourse, getStudentCourse, deleteNotice, getCurrentUser, updateUserMe,
} from '../api';
import MessagingComponent from '../components/MessagingComponent';
import { formatDate } from '../utils/dateUtils';
import NoticeCard from './NoticeCard';
import {
  getAttendanceReviewHistory,
  getStudentFeedbackHistory,
  getCourseHoldHistory
} from '../api';
import TrainersTable from './TrainersTable';
import { useTheme } from '@mui/material/styles';



const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GlassCard = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  
  borderRadius: '10px',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transform: 'translate3d(0, 0, 0)',
  willchange: 'backdrop-filter',
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const GlowingAvatar = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  border: '4px solid transparent',
  borderRadius: '50%',
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F) border-box',
  WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
  WebkitMaskComposite: 'destination-out',
  maskComposite: 'exclude',
  boxShadow: '0 0 20px rgba(255, 27, 107, 0.5)',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GlassModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(15, 23, 42, 0.9)',

    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: theme.palette.common.white,
  },
}));

const GradientChip = styled(Chip)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const AdminDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dashboardData, setDashboardData] = useState({
    total_trainers: 0,
    total_attendance_reviews: 0,
    total_student_feedbacks: 0,
    total_course_holds: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();
  const [courseHolds, setCourseHolds] = useState([]);
  const [courseHoldModalOpen, setCourseHoldModalOpen] = useState(false);
  const [attendanceReviews, setAttendanceReviews] = useState([]);
  const [notices, setNotices] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [leaveRequestModalOpen, setLeaveRequestModalOpen] = useState(false);
  const [leaveRemarks, setLeaveRemarks] = useState('');
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  const [studentFeedbacks, setStudentFeedbacks] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [feedbackResponse, setFeedbackResponse] = useState('');
  const [feedbackStatus, setFeedbackStatus] = useState('');
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);
  const [trainerNames, setTrainerNames] = useState({});
  const [leaveRequestHistory, setLeaveRequestHistory] = useState([]);
  const [reviewHistoryModalOpen, setReviewHistoryModalOpen] = useState(false);
  const [feedbackHistoryModalOpen, setFeedbackHistoryModalOpen] = useState(false);
  const [courseHoldHistoryModalOpen, setCourseHoldHistoryModalOpen] = useState(false);
  const [reviewHistory, setReviewHistory] = useState([]);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [courseHoldHistory, setCourseHoldHistory] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [activeLeaveTab, setActiveLeaveTab] = useState(0);
  const [trainerDetails, setTrainerDetails] = useState({});
  const [newNotice, setNewNotice] = useState({
    title: '',
    content: '',
    audience: 'ALL',
    attachments: [],
    links: []
  });

  useEffect(() => {
    const fetchTrainerDetails = async () => {
      // Create a set of unique trainer IDs from both leave requests and selected request
      const trainerIds = new Set([
        ...leaveRequests.map(request => request.user),
        selectedLeaveRequest?.user
      ].filter(Boolean)); // filter out undefined/null values
      
      const detailsMap = { ...trainerDetails }; // Start with existing details
      
      for (const trainerId of trainerIds) {
        // Skip if we already have this trainer's details
        if (!detailsMap[trainerId]) {
          try {
            const response = await getUser(trainerId);
            detailsMap[trainerId] = response.data;
          } catch (error) {
            console.error(`Error fetching trainer details for ID ${trainerId}:`, error);
          }
        }
      }
      
      setTrainerDetails(detailsMap);
    };
  
    if (leaveRequests.length > 0 || selectedLeaveRequest) {
      fetchTrainerDetails();
    }
  }, [leaveRequests, selectedLeaveRequest]);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await getCurrentUser();
        console.log("User data received:", response);
        
        const userData = response.data;
        
        if (userData && userData.role) {
          setUserRole(userData.role);
          console.log("User role set to:", userData.role);
        } else {
          console.error("User role not found in the response");
          setUserRole('');
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
        setUserRole('');
      }
    };
  
    fetchUserRole();
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const today = new Date().toISOString().split('T')[0];
      const leaveRequestsResponse = await listLeaveRequests();
      const leaveRequestHistoryResponse = await getLeaveRequestHistory();
      const [
        dashboardStatsResponse,
        attendanceReviewsResponse,
        studentFeedbacksResponse,
        courseHoldsResponse,
        noticesResponse,
      ] = await Promise.all([
        getDashboardStats(today),
        listAttendanceReviews(),
        listStudentFeedbacks(),
        listCourseHolds(),
        listNotices(),
      ]);

      setDashboardData({
        total_trainers: dashboardStatsResponse.data.total_trainers,
        total_attendance_reviews: attendanceReviewsResponse.data.length,
        total_student_feedbacks: studentFeedbacksResponse.data.length,
        total_course_holds: courseHoldsResponse.data.length,
        total_leave_requests: leaveRequestsResponse.data.length,
        total_students: dashboardStatsResponse.data.total_students,
      active_courses: dashboardStatsResponse.data.active_courses,
      });
      setAttendanceReviews(attendanceReviewsResponse.data || []);
      setStudentFeedbacks(studentFeedbacksResponse.data || []);
      setCourseHolds(courseHoldsResponse.data || []);
      setNotices(noticesResponse.data || []);
      setLeaveRequests(leaveRequestsResponse.data || []);
      setLeaveRequestHistory(leaveRequestHistoryResponse.data || []);

      const detailedFeedbacks = await Promise.all(studentFeedbacksResponse.data.map(async (feedback) => {
        const [studentResponse, courseResponse] = await Promise.all([
          getUser(feedback.student),
          getCourse(feedback.course)
        ]);
        return {
          ...feedback,
          studentName: studentResponse.data.full_name || studentResponse.data.username,
          courseName: courseResponse.data.name
        };
      }));

      setStudentFeedbacks(detailedFeedbacks);

      const detailedReviews = await Promise.all(attendanceReviewsResponse.data.map(async (review) => {
        const trainerResponse = await getUser(review.trainer);
        return {
          ...review,
          trainerName: trainerResponse.data.full_name || trainerResponse.data.username,
        };
      }));

      setAttendanceReviews(detailedReviews);
      
      const detailedCourseHolds = await Promise.all(courseHoldsResponse.data.map(async (hold) => {
        const [studentCourseResponse, requestedByUser] = await Promise.all([
          getStudentCourse(hold.student_course),
          getUser(hold.requested_by)
        ]);
        return {
          ...hold,
          studentName: studentCourseResponse.data.student.full_name || studentCourseResponse.data.student.username || 'Unknown Student',
          courseName: studentCourseResponse.data.course.name || 'Unknown Course',
          requestedByName: requestedByUser.data.full_name || requestedByUser.data.username || 'Unknown User'
        };
      }));
      setCourseHolds(detailedCourseHolds);

      setLeaveRequests(leaveRequestsResponse.data || []);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to fetch dashboard data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteNotice = async (noticeId) => {
    try {
      await deleteNotice(noticeId);
      setNotices(notices.filter(notice => notice.id !== noticeId));
      setSnackbar({ open: true, message: 'Notice deleted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error deleting notice:', error);
      setSnackbar({ open: true, message: 'Failed to delete notice', severity: 'error' });
    }
  };

  const fetchReviewHistory = useCallback(async () => {
    try {
      const response = await getAttendanceReviewHistory();
      const detailedHistory = await Promise.all(response.data.map(async (review) => {
        const [student, trainer] = await Promise.all([
          getUser(review.student),
          getUser(review.trainer)
        ]);
        return {
          ...review,
          studentName: student.data.full_name || student.data.username,
          trainerName: trainer.data.full_name || trainer.data.username
        };
      }));
      setReviewHistory(detailedHistory);
    } catch (error) {
      console.error('Error fetching review history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch review history', severity: 'error' });
    }
  }, []);

  const fetchFeedbackHistory = useCallback(async () => {
    try {
      const response = await getStudentFeedbackHistory();
      const detailedHistory = await Promise.all(response.data.map(async (feedback) => {
        const [student, course, respondedBy] = await Promise.all([
          getUser(feedback.student),
          getCourse(feedback.course),
          getUser(feedback.responded_by)
        ]);
        return {
          ...feedback,
          studentName: student.data.full_name || student.data.username,
          courseName: course.data.name,
          responderName: respondedBy.data.full_name || respondedBy.data.username
        };
      }));
      setFeedbackHistory(detailedHistory);
    } catch (error) {
      console.error('Error fetching feedback history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch feedback history', severity: 'error' });
    }
  }, []);

  const fetchCourseHoldHistory = useCallback(async () => {
    try {
      const response = await getCourseHoldHistory();
      setCourseHoldHistory(response.data);
    } catch (error) {
      console.error('Error fetching course hold history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch course hold history', severity: 'error' });
    }
  }, []);

  const handleLeaveRequestAction = async (action) => {
    if (!selectedLeaveRequest) return;
  
    try {
      if (action === 'approve') {
        await approveLeaveRequest(selectedLeaveRequest.id, leaveRemarks);
      } else {
        await rejectLeaveRequest(selectedLeaveRequest.id, leaveRemarks);
      }
      setSnackbar({ open: true, message: `Leave request ${action}ed successfully`, severity: 'success' });
      setSelectedLeaveRequest(null);
      setLeaveRemarks('');
      fetchData();
    } catch (error) {
      console.error(`Error ${action}ing leave request:`, error);
      setSnackbar({ open: true, message: `Failed to ${action} leave request`, severity: 'error' });
    }
  };

  const handleCourseHoldAction = async (holdId, action) => {
    try {
      if (action === 'approve') {
        await approveCourseHold(holdId);
      } else {
        await rejectCourseHold(holdId);
      }
      setSnackbar({ open: true, message: `Course hold ${action === 'approve' ? 'approved' : 'rejected'} successfully`, severity: 'success' });
      fetchData();
    } catch (error) {
      console.error(`Error ${action}ing course hold:`, error);
      setSnackbar({ open: true, message: `Failed to ${action} course hold`, severity: 'error' });
    }
  };

  const handleUpdateAttendance = async (attendanceId, studentId, reviewId, newStatus, reviewDecision) => {
    try {
      const studentIdNumber = parseInt(studentId, 10);
  
      await updateAttendance(attendanceId, { 
        status: newStatus,
        student: studentIdNumber
      });
  
      await processAttendanceReview(reviewId, { status: reviewDecision });
  
      setSnackbar({ open: true, message: 'Attendance and review updated successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error updating attendance and review:', error);
      setSnackbar({ open: true, message: 'Failed to update attendance and review', severity: 'error' });
    }
  };

  const handleProcessReview = async (reviewId, newStatus) => {
    try {
      await processAttendanceReview(reviewId, newStatus);
      setSnackbar({ open: true, message: 'Review processed successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error processing review:', error);
      setSnackbar({ open: true, message: 'Failed to process review', severity: 'error' });
    }
  };

  const handleUpdateAttendanceReview = async (reviewId, newStatus) => {
    try {
      await updateAttendanceReview(reviewId, { status: newStatus });
      setSnackbar({ open: true, message: 'Attendance review updated successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error updating attendance review:', error);
      setSnackbar({ open: true, message: 'Failed to update attendance review', severity: 'error' });
    }
  };

  const handleRespondToFeedback = async () => {
    try {
      await respondToStudentFeedback(selectedFeedback.id, { 
        remarks: feedbackResponse,
        status: feedbackStatus
      });
      setSnackbar({ open: true, message: 'Response to student feedback sent successfully', severity: 'success' });
      handleCloseFeedbackModal();
      fetchData();
    } catch (error) {
      console.error('Error responding to student feedback:', error);
      setSnackbar({ open: true, message: 'Failed to send response to student feedback', severity: 'error' });
    }
  };

  
  const handleCreateNotice = async () => {
    try {
      const formData = new FormData();
      formData.append('title', newNotice.title);
      formData.append('content', newNotice.content);
      formData.append('audience', newNotice.audience);
      
      // Properly append each file with the correct field name
      newNotice.attachments.forEach((file, index) => {
        formData.append('attachments', file); // Remove the array syntax
      });
      
      newNotice.links.forEach((link, index) => {
        formData.append(`links[${index}][url]`, link.url);
        formData.append(`links[${index}][title]`, link.title);
      });
  
      // Add proper headers for multipart/form-data
      const response = await createNotice(formData);
      setSnackbar({ open: true, message: 'Notice created successfully', severity: 'success' });
      setNoticeModalOpen(false);
      setNewNotice({ title: '', content: '', audience: 'ALL', attachments: [], links: [] });
      fetchData();
    } catch (error) {
      console.error('Error creating notice:', error);
      setSnackbar({ open: true, message: 'Failed to create notice', severity: 'error' });
    }
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setNewNotice(prev => ({
      ...prev,
      attachments: [...prev.attachments, ...files]
    }));
  };
  const fileInputRef = useRef(null);
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = (index) => {
    setNewNotice(prev => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== index)
    }));
  };

  const handleOpenReviewModal = () => {
    setOpenReviewModal(true);
  };

  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
    setSelectedReview(null);
  };

  const handleOpenFeedbackModal = () => {
    setOpenFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setOpenFeedbackModal(false);
    setSelectedFeedback(null);
    setFeedbackResponse('');
    setFeedbackStatus('');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <GlassContainer maxWidth="sm">
        <Alert severity="error">{error}</Alert>
      </GlassContainer>
    );
  }

  return (
    <>
     
      <GlassContainer sx={{ paddingBottom: '400px'}}>
        <Typography variant="h4" gutterBottom color="white" align="left">Admin Dashboard</Typography>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
  <GlassCard>
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6" gutterBottom color="white">
        Total Trainers
      </Typography>
      <Typography variant="h3" color="white">
        {dashboardData.total_trainers}
      </Typography>
      <Typography variant="subtitle2" color="white" mt={1}>
        Total Students: {dashboardData.total_students}
      </Typography>
      <Typography variant="subtitle2" color="white">
        Active Courses: {dashboardData.active_courses}
      </Typography>
      <Typography variant="subtitle1" color="white" mt={2}>
        Leave Requests: {dashboardData.total_leave_requests}
      </Typography>
      <GradientButton onClick={() => setLeaveRequestModalOpen(true)}>
        View Leave Requests
      </GradientButton>
    </Box>
  </GlassCard>
</Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom color="white">
                  Attendance Reviews
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_attendance_reviews}
                </Typography>
                <Box mt={2}>
                  <GradientButton onClick={handleOpenReviewModal} sx={{ mr: 1 }}>
                    View Details
                  </GradientButton>
                  <GradientButton onClick={() => {
                    setReviewHistoryModalOpen(true);
                    fetchReviewHistory();
                  }}>
                    View History
                  </GradientButton>
                </Box>
              </Box>
            </GlassCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom color="white">
                  Student Feedbacks
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_student_feedbacks}
                </Typography>
                <Box mt={2}>
                  <GradientButton onClick={handleOpenFeedbackModal} sx={{ mr: 1 }}>
                    View Details
                  </GradientButton>
                  <GradientButton onClick={() => {
                    setFeedbackHistoryModalOpen(true);
                    fetchFeedbackHistory();
                  }}>
                    View History
                  </GradientButton>
                </Box>
              </Box>
            </GlassCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom color="white">
                  Course Hold Requests
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_course_holds}
                </Typography>
                <Box mt={2}>
                  <GradientButton onClick={() => setCourseHoldModalOpen(true)} sx={{ mr: 1 }}>
                    View Details
                  </GradientButton>
                  <GradientButton onClick={() => {
                    setCourseHoldHistoryModalOpen(true);
                    fetchCourseHoldHistory();
                  }}>
                    View History
                  </GradientButton>
                </Box>
              </Box>
            </GlassCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom color="white">
                  Create Notice
                </Typography>
                <Typography variant="h3" color="white">
                  +
                </Typography>
                <GradientButton onClick={() => setNoticeModalOpen(true)}>
                  Post New Notice
                </GradientButton>
              </Box>
            </GlassCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <NoticeCard 
                notices={notices} 
                userRole={userRole} 
                onDelete={handleDeleteNotice} 
              />
            </GlassCard>
          </Grid>
        </Grid>

        <Box mt={4}>
          <Typography variant="h5" gutterBottom color="white">Trainers</Typography>
          {isMobile ? (
            <Box sx={{ overflowX: 'auto' }}>
              <TrainersTable userRole={userRole} />
            </Box>
          ) : (
            <TrainersTable userRole={userRole} />
          )}
        </Box>

        <Box mt={4}>
          <Typography variant="h5" gutterBottom color="white">
            Messaging
          </Typography>
        
            <MessagingComponent />
          
        </Box>

        {/* Course Hold Modal */}
        <GlassModal 
          open={courseHoldModalOpen} 
          onClose={() => setCourseHoldModalOpen(false)} 
          fullWidth 
          maxWidth="md"
        >
          <DialogTitle>
            <Typography variant="h5" color="white">Course Hold Requests</Typography>
          </DialogTitle>
          <DialogContent>
            <List>
              {courseHolds.map((hold) => (
                <ListItem key={hold.id}>
                  <GlassCard sx={{ width: '100%' }}>
                    <Typography variant="h6" color="white">
                      {hold.requestedByName} - {hold.courseName}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2" color="white">
                          <strong>Student Course ID:</strong> {hold.student_course}
                        </Typography>
                        <Typography variant="body2" color="white">
                          <strong>Start Date:</strong> {hold.start_date}
                        </Typography>
                        <Typography variant="body2" color="white">
                          <strong>End Date:</strong> {hold.end_date}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2" color="white">
                          <strong>Requested by:</strong> {hold.requestedByName} (ID: {hold.requested_by})
                        </Typography>
                        <Typography variant="body2" color="white">
                          <strong>Created at:</strong> {new Date(hold.created_at).toLocaleString()}
                        </Typography>
                        <Typography variant="body2" color="white">
                          <strong>Updated at:</strong> {new Date(hold.updated_at).toLocaleString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="white">
                          <strong>Reason:</strong> {hold.reason}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                      <GradientChip 
                        label={hold.status} 
                        color={hold.status === 'PENDING' ? 'warning' : 'success'} 
                        variant="outlined"
                      />
                      {hold.status === 'PENDING' && (
                        <Box>
                          <GradientButton 
                            onClick={() => handleCourseHoldAction(hold.id, 'approve')} 
                            size="small" 
                            style={{ marginRight: '8px' }}
                          >
                            Approve
                          </GradientButton>
                          <GradientButton 
                            onClick={() => handleCourseHoldAction(hold.id, 'reject')} 
                            size="small"
                          >
                            Reject
                          </GradientButton>
                        </Box>
                      )}
                    </Box>
                  </GlassCard>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setCourseHoldModalOpen(false)}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Attendance Review Modal */}
        <GlassModal open={openReviewModal} onClose={() => setOpenReviewModal(false)} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Attendance Reviews</Typography>
          </DialogTitle>
          <DialogContent>
            <StyledTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Review ID</TableCell>
                    <TableCell>Attendance ID</TableCell>
                    <TableCell>Student</TableCell>
                    <TableCell>Trainer</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Current Status</TableCell>
                    <TableCell>Remark</TableCell>
                    <TableCell>Review Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendanceReviews.map((review) => (
                    <TableRow key={review.id}>
                      <TableCell>{review.id}</TableCell>
                      <TableCell>{review.attendance.id}</TableCell>
                      <TableCell>{review.attendance.student}</TableCell>
                      <TableCell>{review.trainerName}</TableCell>
                      <TableCell>{dayjs(review.attendance.date).format('MMMM D, YYYY')}</TableCell>
                      <TableCell>{review.attendance.status}</TableCell>
                      <TableCell>{review.remark}</TableCell>
                      <TableCell>{review.status}</TableCell>
                      <TableCell>
                        {review.status === 'PENDING' && (
                          <>
                            <GradientButton 
                              onClick={() => handleProcessReview(review.id, 'APPROVED')} 
                              size="small"
                              sx={{ mr: 1 }}
                            >
                              Approve
                            </GradientButton>
                            <GradientButton 
                              onClick={() => handleProcessReview(review.id, 'REJECTED')} 
                              size="small"
                            >
                              Reject
                            </GradientButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setOpenReviewModal(false)}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Student Feedback Modal */}
        <GlassModal open={openFeedbackModal} onClose={handleCloseFeedbackModal} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Student Feedbacks</Typography>
          </DialogTitle>
          <DialogContent>
            <StyledTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Student</TableCell>
                    <TableCell>Course</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Topic</TableCell>
                    <TableCell>Feedback</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentFeedbacks.map((feedback) => (
                    <TableRow key={feedback.id}>
                      <TableCell>{feedback.studentName}</TableCell>
                      <TableCell>{feedback.courseName}</TableCell>
                      <TableCell>{feedback.feedback_type}</TableCell>
                      <TableCell>{feedback.topic}</TableCell>
                      <TableCell>{feedback.content.substring(0, 50)}...</TableCell>
                      <TableCell>{feedback.status}</TableCell>
                      <TableCell>
                        <GradientButton
                          onClick={() => {
                            setSelectedFeedback(feedback);
                            setFeedbackResponse(feedback.admin_remarks || '');
                            setFeedbackStatus(feedback.status);
                          }}
                          size="small"
                        >
                          Respond
                        </GradientButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={handleCloseFeedbackModal}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Create Notice Modal */}
        <GlassModal open={noticeModalOpen} onClose={() => setNoticeModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Create New Notice</Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Title"
              value={newNotice.title}
              onChange={(e) => setNewNotice({ ...newNotice, title: e.target.value })}
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
            />
            <TextField
              fullWidth
              label="Content"
              value={newNotice.content}
              onChange={(e) => setNewNotice({ ...newNotice, content: e.target.value })}
              margin="normal"
              multiline
              rows={4}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel style={{ color: 'white' }}>Audience</InputLabel>
              <Select
                value={newNotice.audience}
                onChange={(e) => setNewNotice({ ...newNotice, audience: e.target.value })}
                style={{ color: 'white' }}
              >
                <MenuItem value="ALL">All</MenuItem>
                <MenuItem value="STUDENTS">Students</MenuItem>
                <MenuItem value="STUDENTS_TRAINERS">Students and Trainers</MenuItem>
                <MenuItem value="ADMINS_MANAGERS">Admins and Managers</MenuItem>
              </Select>
            </FormControl>
            
            <>
  <input
    ref={fileInputRef}
    accept="image/*,application/pdf"
    style={{ display: 'none' }}
    type="file"
    multiple
    onChange={handleFileSelect}
  />
  <GradientButton 
    onClick={handleUploadClick}
    sx={{ mt: 2, mb: 2 }}
  >
    Upload Attachments
  </GradientButton>
</>
            
            {newNotice.attachments.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle1" color="white">Attachments:</Typography>
                {newNotice.attachments.map((file, index) => (
                  <GradientChip
                    key={index}
                    label={file.name}
                    onDelete={() => handleRemoveFile(index)}
                    color="primary"
                    variant="outlined"
                    style={{ margin: '4px' }}
                  />
                ))}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setNoticeModalOpen(false)}>Cancel</GradientButton>
            <GradientButton 
              onClick={handleCreateNotice} 
              disabled={!newNotice.title || !newNotice.content}
            >
              Create Notice
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Leave Requests Modal */}
<GlassModal open={leaveRequestModalOpen} onClose={() => setLeaveRequestModalOpen(false)} fullWidth maxWidth="md">
  <DialogTitle>
    <Typography variant="h5" color="white">Leave Requests</Typography>
  </DialogTitle>
  <DialogContent>
    <Tabs 
      value={activeLeaveTab} 
      onChange={(event, newValue) => setActiveLeaveTab(newValue)} 
      textColor="inherit" 
      indicatorColor="secondary"
    >
      <Tab label="Pending Requests" />
      <Tab label="Request History" />
    </Tabs>
    
    <TabPanel value={activeLeaveTab} index={0}>
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Trainer</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaveRequests.map((request) => (
              <TableRow key={request.id}>
                <TableCell>
                  {trainerDetails[request.user]?.full_name || 
                   trainerDetails[request.user]?.username || 
                   'Loading...'}
                </TableCell>
                <TableCell>{new Date(request.start_date).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(request.end_date).toLocaleDateString()}</TableCell>
                <TableCell>{request.reason}</TableCell>
                <TableCell>{request.status}</TableCell>
                <TableCell>
                  {request.status === 'PENDING' && (
                    <GradientButton onClick={() => setSelectedLeaveRequest(request)} size="small">
                      Take Action
                    </GradientButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </TabPanel>
    
    <TabPanel value={activeLeaveTab} index={1}>
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Trainer</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Admin Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaveRequestHistory.map((request) => (
              <TableRow key={request.id}>
                <TableCell>
                  {request.trainer_name || request.user?.username || 'Unknown'}
                </TableCell>
                <TableCell>{new Date(request.start_date).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(request.end_date).toLocaleDateString()}</TableCell>
                <TableCell>{request.reason}</TableCell>
                <TableCell>{request.status}</TableCell>
                <TableCell>{request.admin_remarks}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </TabPanel>
  </DialogContent>
  <DialogActions>
    <GradientButton onClick={() => setLeaveRequestModalOpen(false)}>
      Close
    </GradientButton>
  </DialogActions>
</GlassModal>

        {/* Leave Action Dialog */}
{/* Leave Action Dialog */}
<GlassModal open={!!selectedLeaveRequest} onClose={() => setSelectedLeaveRequest(null)} fullWidth maxWidth="sm">
  <DialogTitle>
    <Typography variant="h5" color="white">Leave Request Action</Typography>
  </DialogTitle>
  <DialogContent>
    {selectedLeaveRequest && (
      <>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" gutterBottom color="white">
            Trainer: {trainerDetails[selectedLeaveRequest.user]?.full_name || 
                     trainerDetails[selectedLeaveRequest.user]?.username || 
                     'Loading...'}
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            From: {new Date(selectedLeaveRequest.start_date).toLocaleDateString()} 
            To: {new Date(selectedLeaveRequest.end_date).toLocaleDateString()}
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            Reason: {selectedLeaveRequest.reason}
          </Typography>
        </Box>

        {/* Attachments section */}
        {selectedLeaveRequest.attachments?.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              Attachments:
            </Typography>
            {selectedLeaveRequest.attachments.map((attachment, index) => (
              <Paper 
                key={attachment.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  p: 2,
                  mb: 1,
                  background: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px',
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" color="white">
                    Attachment {index + 1}
                  </Typography>
                </Box>
                <GradientButton
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = attachment.file;
                    link.download = `attachment-${index + 1}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                  size="small"
                >
                  Download
                </GradientButton>
              </Paper>
            ))}
          </Box>
        )}

        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          label="Remarks"
          value={leaveRemarks}
          onChange={(e) => setLeaveRemarks(e.target.value)}
          margin="normal"
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />
      </>
    )}
  </DialogContent>
  <DialogActions>
    <GradientButton onClick={() => setSelectedLeaveRequest(null)}>
      Cancel
    </GradientButton>
    <GradientButton onClick={() => handleLeaveRequestAction('approve')}>
      Approve
    </GradientButton>
    <GradientButton onClick={() => handleLeaveRequestAction('reject')}>
      Reject
    </GradientButton>
  </DialogActions>
</GlassModal>

        {/* Feedback Response Modal */}
        <GlassModal open={!!selectedFeedback} onClose={() => setSelectedFeedback(null)} fullWidth maxWidth="sm">
          <DialogTitle>
            <Typography variant="h5" color="white">Respond to Feedback</Typography>
          </DialogTitle>
          <DialogContent>
            {selectedFeedback && (
              <>
                <Typography variant="body1" gutterBottom color="white">
                  Student: {selectedFeedback.studentName}
                </Typography>
                <Typography variant="body1" gutterBottom color="white">
                  Course: {selectedFeedback.courseName}
                </Typography>
                <Typography variant="body1" gutterBottom color="white">
                  Type: {selectedFeedback.feedback_type}
                </Typography>
                <Typography variant="body1" gutterBottom color="white">
                  Topic: {selectedFeedback.topic}
                </Typography>
                <Typography variant="body1" gutterBottom color="white">
                  Feedback: {selectedFeedback.content}
                </Typography>
                <FormControl fullWidth margin="normal"><InputLabel style={{ color: 'white' }}>Status</InputLabel>
                  <Select
                    value={feedbackStatus}
                    onChange={(e) => setFeedbackStatus(e.target.value)}
                    style={{ color: 'white' }}
                  >
                    <MenuItem value="PENDING">Pending</MenuItem>
                    <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
                    <MenuItem value="RESOLVED">Resolved</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Your Response"
                  value={feedbackResponse}
                  onChange={(e) => setFeedbackResponse(e.target.value)}
                  margin="normal"
                  InputLabelProps={{ style: { color: 'white' } }}
                  InputProps={{ style: { color: 'white' } }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setSelectedFeedback(null)}>
              Cancel
            </GradientButton>
            <GradientButton onClick={handleRespondToFeedback} disabled={!feedbackResponse.trim()}>
              Send Response
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Attendance Review History Modal */}
        <GlassModal open={reviewHistoryModalOpen} onClose={() => setReviewHistoryModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Attendance Review History</Typography>
          </DialogTitle>
          <DialogContent>
            <List>
              {reviewHistory.map((review) => (
                <ListItem key={review.id}>
                  <GlassCard sx={{ width: '100%' }}>
                    <Typography variant="h6" color="white">
                      Review for Student: {review.studentName} (ID: {review.student})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Trainer: {review.trainerName} (ID: {review.trainer})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Status: {review.status}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Remark: {review.remark}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Processed at: {new Date(review.processed_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Processed by ID: {review.processed_by}
                    </Typography>
                  </GlassCard>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setReviewHistoryModalOpen(false)}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Student Feedback History Modal */}
        <GlassModal open={feedbackHistoryModalOpen} onClose={() => setFeedbackHistoryModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Student Feedback History</Typography>
          </DialogTitle>
          <DialogContent>
            <List>
              {feedbackHistory.map((feedback) => (
                <ListItem key={feedback.id}>
                  <GlassCard sx={{ width: '100%' }}>
                    <Typography variant="h6" color="white">
                      Feedback from {feedback.studentName} (ID: {feedback.student})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Course: {feedback.courseName} (ID: {feedback.course})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Type: {feedback.feedback_type}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Topic: {feedback.topic}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Content: {feedback.content}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Admin Remarks: {feedback.admin_remarks}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Responded by: {feedback.responderName} (ID: {feedback.responded_by})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Created at: {new Date(feedback.created_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Resolved at: {new Date(feedback.resolved_at).toLocaleString()}
                    </Typography>
                  </GlassCard>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setFeedbackHistoryModalOpen(false)}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Course Hold History Modal */}
        {/* Course Hold History Modal */}
<GlassModal open={courseHoldHistoryModalOpen} onClose={() => setCourseHoldHistoryModalOpen(false)} fullWidth maxWidth="md">
  <DialogTitle>
    <Typography variant="h5" color="white">Course Hold History</Typography>
  </DialogTitle>
  <DialogContent>
    <List>
      {courseHoldHistory.map((hold) => (
        <ListItem key={hold.id}>
          <GlassCard sx={{ width: '100%' }}>
            <Typography variant="h6" color="white">
              Hold for {hold.student?.full_name || 'Unknown Student'} 
              (ID: {hold.student?.id || 'N/A'})
            </Typography>
            <Typography variant="body2" color="white">
              Start Date: {hold.start_date || 'Not specified'}
            </Typography>
            <Typography variant="body2" color="white">
              End Date: {hold.end_date || 'Not specified'}
            </Typography>
            <Typography variant="body2" color="white">
              Reason: {hold.reason || 'No reason provided'}
            </Typography>
            <Typography variant="body2" color="white">
              Status: {hold.status || 'Unknown'}
            </Typography>
            <Typography variant="body2" color="white">
              Created at: {hold.created_at ? new Date(hold.created_at).toLocaleString() : 'Not available'}
            </Typography>
            <Typography variant="body2" color="white">
              Group Course Trainers: {
                hold.student?.group_course_trainers?.length > 0
                  ? hold.student.group_course_trainers.map(trainer => 
                      `${trainer.full_name || trainer.username || 'Unknown'} (ID: ${trainer.id || 'N/A'})`
                    ).join(', ')
                  : 'No trainers assigned'
              }
            </Typography>
          </GlassCard>
        </ListItem>
      ))}
    </List>
  </DialogContent>
  <DialogActions>
    <GradientButton onClick={() => setCourseHoldHistoryModalOpen(false)}>
      Close
    </GradientButton>
  </DialogActions>
</GlassModal>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            sx={{ 
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              '& .MuiAlert-icon': {
                color: 'white'
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </GlassContainer>
    </>
  );
};

export default AdminDashboard;