import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
  Alert,
  Button,
  styled,
  useTheme,
  useMediaQuery,
  IconButton,
  Fade,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  Timer as TimerIcon,
  EmojiEvents as TrophyIcon,
  Check as CheckIcon,
  Warning as WarningIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Flag as FlagIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { 
  getCompetition, 
  startCompetitionAttempt, 
  submitCompetitionAnswers, 
  getImageUrl 
} from '../api';

// Styled Components to match parent
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '15px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  }
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
  '&:disabled': {
    background: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

const TimerDisplay = styled(Box)(({ theme, warning }) => ({
  background: warning ? 'linear-gradient(45deg, #FF6B6B, #FFE66D)' : 'linear-gradient(45deg, #2196F3, #64B5F6)',
  padding: theme.spacing(2),
  borderRadius: '15px',
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  background: 'rgba(255, 255, 255, 0.1)',
  marginBottom: theme.spacing(2),
}));

const QuestionCard = styled(GlassCard)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  position: 'relative',
  overflow: 'visible',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #FF1B6B, #FF930F)',
    borderRadius: '15px 15px 0 0',
  },
}));

const OptionButton = styled(Button)(({ theme, selected }) => ({
    width: '100%',
    textAlign: 'left',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: '10px',
    background: selected ? 'rgba(255, 27, 107, 0.1)' : 'rgba(255, 255, 255, 0.05)',
    border: `2px solid ${selected ? '#FF1B6B' : 'rgba(255, 255, 255, 0.1)'}`,
    color: selected ? '#FF1B6B' : theme.palette.text.primary,
    transition: 'all 0.3s ease',
    textTransform: 'none', // This will prevent text from being transformed to uppercase
    '&:hover': {
      background: selected ? 'rgba(255, 27, 107, 0.15)' : 'rgba(255, 255, 255, 0.1)',
    }
  }));

const NavigationButton = styled(GradientButton)(({ theme, direction }) => ({
  minWidth: '120px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '100px',
  },
}));

const CompletionCard = styled(GlassCard)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4),
  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))',
}));

const CompetitionQuiz = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { id } = useParams();
  const navigate = useNavigate();

  // State management
  const [competition, setCompetition] = useState(null);
  const [attempt, setAttempt] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState(null);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [showQuestionNav, setShowQuestionNav] = useState(false);

  // Fetch competition data
  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        setLoading(true);
        const response = await getCompetition(id);
        setCompetition(response.data);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to load competition');
      } finally {
        setLoading(false);
      }
    };
    fetchCompetition();
  }, [id]);

  // Start attempt
  const startAttempt = useCallback(async () => {
    try {
      const response = await startCompetitionAttempt(id);
      setAttempt(response.data);
      setTimeLeft(competition.time_limit * 60);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to start attempt, Maybe you have already taken the quiz?');
    }
  }, [id, competition]);

  // Timer logic
  useEffect(() => {
    if (!timeLeft || timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft(time => {
        if (time <= 1) {
          clearInterval(intervalId);
          handleSubmit();
          return 0;
        }
        return time - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // Format time
  const formatTime = useMemo(() => {
    if (!timeLeft) return '00:00';
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [timeLeft]);

  // Handle answer selection
  const handleAnswerSelect = (questionId, optionId) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: optionId
    }));
  };

  // Submit quiz
  const handleSubmit = async () => {
    if (submitted) return;
    
    try {
      const answersArray = Object.entries(answers).map(([questionId, optionId]) => ({
        question_id: parseInt(questionId),
        selected_option_id: parseInt(optionId)
      }));

      const response = await submitCompetitionAnswers(id, answersArray);
      setResult(response.data);
      setSubmitted(true);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to submit answers');
    }
  };

  // Loading state
  if (loading) {
    return (
      <Box sx={{ 
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
      }}>
        <GlassCard sx={{ p: 4, maxWidth: 400, width: '100%' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ mb: 2, color: 'white' }}>
              Loading Quiz...
            </Typography>
            <LinearProgress />
          </Box>
        </GlassCard>
      </Box>
    );
  }

  // Error state
  if (error) {
    return (
      <Box sx={{ 
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
        p: 2
      }}>
        <GlassCard sx={{ p: 4, maxWidth: 400, width: '100%' }}>
          <Alert 
            severity="error" 
            sx={{ mb: 2 }}
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => navigate('/competitions')}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
          <GradientButton
            fullWidth
            onClick={() => navigate('/competitions')}
            startIcon={<ArrowBackIcon />}
          >
            Back to Competitions
          </GradientButton>
        </GlassCard>
      </Box>
    );
  }

  // Quiz completed state
  if (submitted && result) {
    return (
      <Box sx={{ 
        minHeight: '100vh',
        
        p: 2
      }}>
        <CompletionCard>
          <TrophyIcon sx={{ fontSize: 60, color: '#FFD700', mb: 2 }} />
          <Typography variant="h4" sx={{ color: 'white', mb: 3 }}>
            Quiz Completed!
          </Typography>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, mb: 4 }}>
            <Box sx={{ 
              p: 3, 
              borderRadius: 2, 
              background: 'rgba(255, 255, 255, 0.1)',
              textAlign: 'center'
            }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Score</Typography>
              <Typography variant="h4" sx={{ color: '#4CAF50' }}>
                {result.score}/{competition.questions.length}
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {Math.round((result.score / competition.questions.length) * 100)}% Correct
              </Typography>
            </Box>

            <Box sx={{ 
              p: 3, 
              borderRadius: 2, 
              background: 'rgba(255, 255, 255, 0.1)',
              textAlign: 'center'
            }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Time Taken</Typography>
              <Typography variant="h4" sx={{ color: '#2196F3' }}>
                {result.completion_time}
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                of {competition.time_limit} minutes
              </Typography>
            </Box>
          </Box>

          <GradientButton
            fullWidth
            onClick={() => navigate('/competitions')}
            startIcon={<ArrowBackIcon />}
          >
            Back to Competitions
          </GradientButton>
        </CompletionCard>
      </Box>
    );
  }

  // Start quiz state
  if (!attempt) {
    return (
      <Box sx={{ 
        minHeight: '100vh',
        
        p: 2
      }}>
        <GlassCard sx={{ maxWidth: 800, mx: 'auto' }}>
          <Typography variant="h4" sx={{ color: 'white', mb: 3 }}>
            {competition.title}
          </Typography>
          
          <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 4 }}>
            {competition.description}
          </Typography>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, mb: 4 }}>
            <Box sx={{ 
              p: 3, 
              borderRadius: 2, 
              background: 'rgba(255, 255, 255, 0.1)'
            }}>
              <TimerIcon sx={{ color: '#2196F3', mb: 1 }} />
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                Time Limit
              </Typography>
              <Typography variant="h6" sx={{ color: 'white' }}>
                {competition.time_limit} minutes
              </Typography>
            </Box>

            <Box sx={{ 
              p: 3, 
              borderRadius: 2, 
              background: 'rgba(255, 255, 255, 0.1)'
            }}>
              <CheckIcon sx={{ color: '#4CAF50', mb: 1 }} />
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                Questions
              </Typography>
              <Typography variant="h6" sx={{ color: 'white' }}>
              {competition.questions.length} total
              </Typography>
            </Box>
          </Box>

          <Alert 
            severity="info" 
            sx={{ mb: 4, '& .MuiAlert-message': { color: 'text.primary' } }}
            icon={<WarningIcon sx={{ color: theme.palette.info.main }} />}
          >
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
              Important Instructions
            </Typography>
            <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
              <li>Timer starts immediately when you begin</li>
              <li>Each question has only one correct answer</li>
              <li>You can review answers before final submission</li>
              <li>Quiz auto-submits when time expires</li>
            </ul>
          </Alert>

          <GradientButton
            fullWidth
            onClick={startAttempt}
            startIcon={<FlagIcon />}
            size="large"
          >
            Start Quiz
          </GradientButton>
        </GlassCard>
      </Box>
    );
  }

  // Active quiz state
  const currentQuestion = competition.questions[currentQuestionIndex];
  const timeWarning = timeLeft && timeLeft < 300; // Warning when less than 5 minutes

  return (
    <Box sx={{ 
      minHeight: '100vh',
      
      p: 2
    }}>
      <Box sx={{ maxWidth: 900, mx: 'auto' }}>
        {/* Timer and Progress Bar */}
        <GlassCard sx={{ mb: 3 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 2,
            mb: 2
          }}>
            <TimerDisplay warning={timeWarning}>
              <TimerIcon />
              <Typography variant="h6" component="span">
                {formatTime}
              </Typography>
            </TimerDisplay>

            <Typography sx={{ color: 'white' }}>
              Question {currentQuestionIndex + 1} of {competition.questions.length}
            </Typography>
          </Box>

          <ProgressWrapper>
            <LinearProgress 
              variant="determinate" 
              value={(currentQuestionIndex + 1) / competition.questions.length * 100}
              sx={{
                height: 8,
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '& .MuiLinearProgress-bar': {
                  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
                }
              }}
            />
          </ProgressWrapper>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            color: 'rgba(255, 255, 255, 0.7)',
            fontSize: '0.875rem'
          }}>
            <span>Progress</span>
            <span>{Math.round((Object.keys(answers).length / competition.questions.length) * 100)}% Complete</span>
          </Box>
        </GlassCard>

        {/* Question Card */}
        <QuestionCard>
          <CardContent>
            <Typography variant="h6" sx={{ color: 'white', mb: 3 }}>
              {currentQuestion.question_text}
            </Typography>

            {currentQuestion.image && (
              <Box sx={{ mb: 3, textAlign: 'center' }}>
                <img 
                  src={getImageUrl(currentQuestion.image)}
                  alt="Question"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    borderRadius: '8px',
                    border: '1px solid rgba(255, 255, 255, 0.1)'
                  }}
                />
              </Box>
            )}

            <Box sx={{ mb: 3 }}>
              {currentQuestion.options.map((option) => (
                <OptionButton
                  key={option.id}
                  selected={answers[currentQuestion.id] === option.id}
                  onClick={() => handleAnswerSelect(currentQuestion.id, option.id)}
                  fullWidth
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Box 
                      sx={{ 
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        border: '2px solid',
                        borderColor: answers[currentQuestion.id] === option.id ? '#FF1B6B' : 'rgba(255, 255, 255, 0.3)',
                        mr: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexShrink: 0
                      }}
                    >
                      {answers[currentQuestion.id] === option.id && (
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            bgcolor: '#FF1B6B'
                          }}
                        />
                      )}
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography>{option.option_text}</Typography>
                      {option.image && (
                        <Box sx={{ mt: 1 }}>
                          <img
                            src={getImageUrl(option.image)}
                            alt="Option"
                            style={{
                              maxWidth: '100%',
                              height: 'auto',
                              borderRadius: '4px'
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </OptionButton>
              ))}
            </Box>
          </CardContent>
        </QuestionCard>

        {/* Navigation Buttons */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          gap: 2,
          flexWrap: 'wrap'
        }}>
          <NavigationButton
            disabled={currentQuestionIndex === 0}
            onClick={() => setCurrentQuestionIndex(prev => prev - 1)}
            startIcon={<ArrowBackIcon />}
          >
            Previous
          </NavigationButton>

          {currentQuestionIndex === competition.questions.length - 1 ? (
            <GradientButton
              onClick={() => setConfirmSubmit(true)}
              startIcon={<FlagIcon />}
            >
              Submit Quiz
            </GradientButton>
          ) : (
            <NavigationButton
              onClick={() => setCurrentQuestionIndex(prev => prev + 1)}
              endIcon={<ArrowForwardIcon />}
            >
              Next
            </NavigationButton>
          )}
        </Box>
      </Box>

      {/* Submit Confirmation Dialog */}
      <Dialog
  open={confirmSubmit}
  onClose={() => setConfirmSubmit(false)}
  PaperProps={{
    sx: {
      background: 'rgba(255, 255, 255, 0.95)',
      backdropFilter: 'blur(10px)',
      borderRadius: '15px',
      maxWidth: '400px',
    }
  }}
>
  <DialogTitle sx={{ color: '#000', fontWeight: 600 }}>
    Confirm Submission
    <IconButton
      onClick={() => setConfirmSubmit(false)}
      sx={{ 
        position: 'absolute', 
        right: 8, 
        top: 8,
        color: 'rgba(0, 0, 0, 0.7)' 
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
    <Typography variant="body1" sx={{ mb: 2, color: '#000' }}>
      Are you sure you want to submit your quiz?
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
        • Answered: {Object.keys(answers).length} of {competition.questions.length} questions
      </Typography>
      <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
        • Time remaining: {formatTime}
      </Typography>
    </Box>
  </DialogContent>
  <DialogActions sx={{ p: 3 }}>
    <Button
      onClick={() => setConfirmSubmit(false)}
      sx={{ color: 'rgba(0, 0, 0, 0.7)' }}
    >
      Review Answers
    </Button>
    <GradientButton
      onClick={handleSubmit}
      startIcon={<FlagIcon />}
    >
      Submit Quiz
    </GradientButton>
  </DialogActions>
</Dialog>
    </Box>
  );
};

export default CompetitionQuiz;