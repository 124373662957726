import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Pagination,
  Box,
  Link,
  Skeleton,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { listTrainers, getTrainerOccupation } from '../api';

const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  overflowX: 'auto',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    color: theme.palette.common.white,
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.common.white,
  },
}));

const GradientLink = styled(Link)(({ theme }) => ({
    background: 'linear-gradient(45deg,#ffa500, #FF930F)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text', // Fallback for other browsers
    textFillColor: 'transparent', // Non-webkit fallback
    fontWeight: 'bold', // Optional: Improve visibility
    transition: 'all 0.3s ease-in-out',
    textDecoration: 'none', // Prevent default link underline
    '&:hover': {
      textShadow: '0 0 0px rgba(255, 147, 15, 0.5)',
      transform: 'scale(1.05)', // Slight scaling on hover
    },
  }));

const TrainersTable = ({ userRole }) => {
  const [trainers, setTrainers] = useState({ results: [], count: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [trainerOccupiedHours, setTrainerOccupiedHours] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchTrainers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const today = new Date().toISOString().split('T')[0];
      const trainersResponse = await listTrainers({ page, page_size: pageSize, search: searchTerm });
      setTrainers(trainersResponse);
      setTotalPages(Math.ceil(trainersResponse.count / pageSize));

      const occupiedHours = {};
      for (const trainer of trainersResponse.results) {
        const occupationResponse = await getTrainerOccupation(trainer.user.id, today);
        occupiedHours[trainer.user.id] = occupationResponse.data.occupied_hours || 0;
      }
      setTrainerOccupiedHours(occupiedHours);
    } catch (err) {
      console.error('Error fetching trainers:', err);
      setError('Failed to fetch trainers. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, searchTerm]);

  useEffect(() => {
    fetchTrainers();
  }, [fetchTrainers]);

  const handleTrainerClick = (trainerId) => {
    navigate(`/trainers/${trainerId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setPage(1);
    fetchTrainers();
  };

  const renderTableContent = () => {
    const rows = [];

    for (let i = 0; i < pageSize; i++) {
      const trainer = trainers.results[i];
      
      if (loading) {
        rows.push(
          <TableRow key={`skeleton-${i}`}>
            {!isMobile && <TableCell><Skeleton animation="wave" /></TableCell>}
            <TableCell><Skeleton animation="wave" /></TableCell>
            {!isMobile && <TableCell><Skeleton animation="wave" /></TableCell>}
            <TableCell><Skeleton animation="wave" /></TableCell>
            <TableCell><Skeleton animation="wave" /></TableCell>
            {!isMobile && <TableCell><Skeleton animation="wave" /></TableCell>}
            <TableCell><Skeleton animation="wave" /></TableCell>
          </TableRow>
        );
      } else if (trainer) {
        rows.push(
          <TableRow key={trainer.id}>
            {!isMobile && <TableCell>{(page - 1) * pageSize + i + 1}</TableCell>}
            <TableCell>
              <GradientLink
                component="button"
                variant="body2"
                onClick={() => handleTrainerClick(trainer.id)}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {trainer.user.full_name || trainer.user.username || 'N/A'}
              </GradientLink>
            </TableCell>
            {!isMobile && <TableCell>{trainer.user.phone || 'N/A'}</TableCell>}
            <TableCell>{trainer.contract_type || 'N/A'}</TableCell>
            <TableCell>{trainer.approved_hours || 'N/A'}</TableCell>
            {!isMobile && <TableCell>{userRole === 'ADMIN' ? (trainer.salary || 'N/A') : 'Private'}</TableCell>}
            <TableCell>{trainerOccupiedHours[trainer.user.id] || 'N/A'}</TableCell>
          </TableRow>
        );
      } else {
        rows.push(
          <TableRow key={`empty-${i}`}>
            <TableCell colSpan={isMobile ? 4 : 7}></TableCell>
          </TableRow>
        );
      }
    }

    return rows;
  };

  return (
    <GlassContainer>
      <form onSubmit={handleSearchSubmit}>
        <StyledTextField
          fullWidth
          label="Search Trainers"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </form>

      
        <Typography variant="h6" gutterBottom color="white" p={2}>
          Trainers
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              {!isMobile && <TableCell>S.No</TableCell>}
              <TableCell>Name</TableCell>
              {!isMobile && <TableCell>Phone</TableCell>}
              <TableCell>Contract Type</TableCell>
              <TableCell>Approved Hours</TableCell>
              {!isMobile && <TableCell>Salary</TableCell>}
              <TableCell>Occupied Hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableContent()}
          </TableBody>
        </Table>
      
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
          sx={{
            '& .MuiPaginationItem-root': {
              color: 'white',
            },
          }}
        />
      </Box>
    </GlassContainer>
  );
};

export default TrainersTable;