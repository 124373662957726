import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, 
  Select, MenuItem, FormControl, InputLabel, Grid, Typography, Box 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { listCourses } from '../api';

const GradientBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  background: 'linear-gradient(45deg, #1a237e, #311b92, #4a148c)',
});

const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const GlassInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '8px',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.common.white,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.common.white,
  },
}));

const GlassSelect = styled(Select)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.5)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiSelect-icon': {
    color: theme.palette.common.white,
  },
  '& .MuiSelect-select': {
    color: theme.palette.common.white,
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const AddAuditModal = ({ open, onClose, onSubmit }) => {
  const [auditData, setAuditData] = useState({
    audit_date: '',
    class_date: '',
    overall_score: '',
    student_name: '',
    course: '',
    grammar_theory_covered: '',
    vocabulary_covered: '',
    speaking_activity: '',
    feedback_shared: '',
    assessment_of_student: '',
    error_rectification_done: '',
    webcam_on: '',
    class_on_portal: '',
    login_on_time: '',
    full_class_duration: '',
    session_on_time: '',
    use_of_whiteboard: '',
    study_material_shared: '',
    feedback: ''
  });
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await listCourses();
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, []);

  const handleChange = (e) => {
    setAuditData({ ...auditData, [e.target.name]: e.target.value });
  };

  
  const renderSelect = (name, label, options) => (
    <FormControl fullWidth size="small">
      <InputLabel sx={{ color: 'white' }}>{label}</InputLabel>
      <GlassSelect name={name} value={auditData[name]} onChange={handleChange}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </GlassSelect>
    </FormControl>
  );

  return (
    <>
      <GradientBackground />
      <GlassDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography variant="h5" color="white">Add New Audit</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <GlassInput
                fullWidth
                margin="normal"
                label="Audit Date"
                type="date"
                name="audit_date"
                value={auditData.audit_date}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <GlassInput
                fullWidth
                margin="normal"
                label="Class Date"
                type="date"
                name="class_date"
                value={auditData.class_date}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <GlassInput
                fullWidth
                margin="normal"
                label="Overall Score"
                type="number"
                name="overall_score"
                value={auditData.overall_score}
                onChange={handleChange}
                inputProps={{ min: 0, max: 10 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <GlassInput
                fullWidth
                margin="normal"
                label="Student Name"
                name="student_name"
                value={auditData.student_name}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              {renderSelect('course', 'Course', courses.map(course => ({ value: course.id, label: course.name })))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="white">Audit Parameters</Typography>
            </Grid>
            {[
              ['grammar_theory_covered', 'Grammar/Theory'],
              ['vocabulary_covered', 'Vocabulary'],
              ['speaking_activity', 'Speaking'],
              ['feedback_shared', 'Feedback'],
              ['assessment_of_student', 'Assessment'],
              ['error_rectification_done', 'Error Rectification'],
              ['webcam_on', 'Webcam'],
              ['class_on_portal', 'Class on Portal'],
              ['login_on_time', 'Login Time'],
              ['full_class_duration', 'Full Duration'],
              ['session_on_time', 'Session Time'],
              ['use_of_whiteboard', 'Whiteboard'],
              ['study_material_shared', 'Study Material'],
            ].map(([name, label]) => (
              <Grid item xs={6} sm={4} md={3} key={name}>
                {renderSelect(name, label, [
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'NA', label: 'N/A' }
                ])}
              </Grid>
            ))}
            <Grid item xs={12}>
              <GlassInput
                fullWidth
                margin="normal"
                label="Feedback"
                multiline
                rows={4}
                name="feedback"
                value={auditData.feedback}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={onClose}>Cancel</GradientButton>
          <GradientButton onClick={() => onSubmit(auditData)} color="primary">Submit</GradientButton>
        </DialogActions>
      </GlassDialog>
    </>
  );
};

export default AddAuditModal;