import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, 
  Typography, Grid, Chip, Divider, Box, Paper, useTheme, useMediaQuery 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getUser } from '../api';

const GlassBox = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const GradientBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  background: 'linear-gradient(45deg, #1a237e, #311b92, #4a148c)',
});

const AuditDetailsModal = ({ open, onClose, audit, onAddRemarks }) => {
  const [newRemarks, setNewRemarks] = useState('');
  const [auditorName, setAuditorName] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (audit && audit.auditor) {
      fetchAuditorName(audit.auditor);
    }
  }, [audit]);

  const fetchAuditorName = async (auditorId) => {
    try {
      const response = await getUser(auditorId);
      const userData = response.data;
      setAuditorName(`${userData.full_name}`);
    } catch (error) {
      console.error('Error fetching auditor name:', error);
      setAuditorName('Unknown');
    }
  };

  const handleAddRemarks = () => {
    onAddRemarks(audit.id, newRemarks);
    setNewRemarks('');
  };

  const renderAuditField = (label, value) => (
    <Grid item xs={12} sm={4}>
      <Typography variant="body2" color="white"><strong>{label}:</strong> {value || 'N/A'}</Typography>
    </Grid>
  );

  const renderParameterChip = (label, value) => (
    <Grid item xs={12} sm={6} md={4}>
      <Chip 
        label={`${label}: ${value}`}
        size="small" 
        color={value === 'YES' ? 'success' : value === 'NO' ? 'error' : 'default'}
        sx={{ 
          width: '100%', 
          justifyContent: 'flex-start', 
          height: 'auto', 
          '& .MuiChip-label': { 
            display: 'block', 
            whiteSpace: 'normal', 
            textAlign: 'left',
            padding: '8px'
          },
          color: 'white',
          borderColor: 'white'
        }}
      />
    </Grid>
  );

  const getScoreColor = (score) => {
    if (score < 7) return 'error.main';
    if (score < 10) return 'warning.main';
    return 'success.main';
  };

  if (!audit) return null;

  return (
    <>
      <GradientBackground />
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 128, 0.8)', 

            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          },
        }}
      >
      <DialogTitle sx={{ color: 'white' }}>Audit Details</DialogTitle>
      <DialogContent>
        <GlassBox sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            {renderAuditField('Audit Date', audit.audit_date ? new Date(audit.audit_date).toLocaleDateString() : 'N/A')}
            {renderAuditField('Class Date', audit.class_date ? new Date(audit.class_date).toLocaleDateString() : 'N/A')}
            {renderAuditField('Student', audit.student_name || 'Not specified')}
            {renderAuditField('Course', audit.course ? audit.course.name : 'Not specified')}
            {renderAuditField('Auditor', auditorName)}
          </Grid>
        </GlassBox>

        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Typography variant="h6" gutterBottom color="white">Parameters</Typography>
            <Grid container spacing={2} justifyContent="flex-start">
              {renderParameterChip('Grammar/Theory', audit.grammar_theory_covered)}
              {renderParameterChip('Vocabulary', audit.vocabulary_covered)}
              {renderParameterChip('Speaking', audit.speaking_activity)}
              {renderParameterChip('Feedback', audit.feedback_shared)}
              {renderParameterChip('Assessment', audit.assessment_of_student)}
              {renderParameterChip('Error Rectification', audit.error_rectification_done)}
              {renderParameterChip('Webcam', audit.webcam_on)}
              {renderParameterChip('Portal', audit.class_on_portal)}
              {renderParameterChip('Login Time', audit.login_on_time)}
              {renderParameterChip('Full Duration', audit.full_class_duration)}
              {renderParameterChip('Session Time', audit.session_on_time)}
              {renderParameterChip('Whiteboard', audit.use_of_whiteboard)}
              {renderParameterChip('Study Material', audit.study_material_shared)}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <GlassBox 
              bgcolor={getScoreColor(audit.overall_score)}
              p={2}
              borderRadius={2}
              textAlign="center"
              width="100%"
            >
              <Typography variant="h6" color="white">Overall Score</Typography>
              <Typography variant="h2" color="white">{audit.overall_score}</Typography>
            </GlassBox>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />

        <Typography variant="h6" gutterBottom color="white"><strong>Feedback:</strong></Typography>
        <Typography variant="body1" color="white">{audit.feedback || 'No feedback provided'}</Typography>

        {audit.trainer_remarks && (
          <GlassBox sx={{ my: 2 }}>
            <Typography variant="h6" gutterBottom color="white"><strong>Trainer Remarks:</strong></Typography>
            <Typography variant="body1" color="white">{audit.trainer_remarks}</Typography>
          </GlassBox>
        )}
        
        <StyledTextField
          fullWidth
          margin="normal"
          label="Add New Remarks"
          multiline
          rows={3}
          value={newRemarks}
          onChange={(e) => setNewRemarks(e.target.value)}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: 'white' }}>Close</Button>
        <GradientButton onClick={handleAddRemarks} disabled={!newRemarks.trim()}>
          Add Remarks
        </GradientButton>
      </DialogActions>
      </Dialog>
    </>
  );
};

export default AuditDetailsModal;