import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Button,
  TextField,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Snackbar,
  Alert,
  Avatar,
  ListItemIcon,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  getUser,
  getStudentCourses,
  getStudyMaterialsForStudent,
  listStudentFeedbacks1,
  getCourseHoldHistory1,
  updateUserMe,getCurrentUser,
  getStudentAttendances,
  createCourseHold, getStudentCourseFlows
} from '../api';
import AttendanceCalendar from '../components/AttendanceCalendar';
import { Description as DescriptionIcon } from '@mui/icons-material';
import AttendanceManagement from '../components/AttendanceManagement';
import StudentProgress from '../components/StudentProgress'; 
import { 
  listSyllabusItems, 
  customizeStudentFlow,
  getStudentCourseFlow,
  listCourseFlows 
} from '../api';
import {
  
  ListItemSecondaryAction,
  IconButton,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { getTrainerAssignmentHistory } from '../api';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { PersonAdd as PersonAddIcon, Person as PersonIcon } from '@mui/icons-material';

const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
 
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    color: theme.palette.common.white,
  },
  '& .Mui-selected': {
    color: theme.palette.primary.main,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const StudentDetails = () => {
  const { studentId } = useParams();
  const [studentData, setStudentData] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [holdHistory, setHoldHistory] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [tabValue, setTabValue] = useState(0);
  const [newHold, setNewHold] = useState({ start_date: null, end_date: null, reason: '' });
  const [userRole, setUserRole] = useState('');
  const [assignmentHistory, setAssignmentHistory] = useState([]);


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchUserRole = useCallback(async () => {
    try {
      const response = await getCurrentUser();
      setUserRole(response.data.role);
    } catch (error) {
      console.error('Error fetching user role:', error);
      setUserRole('');
    }
  }, []);

  useEffect(() => {
    fetchUserRole();
  }, [fetchUserRole]);

  const fetchStudentData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      // First fetch student courses
      const coursesResponse = await getStudentCourses(studentId);
      if (coursesResponse && coursesResponse.data) {
        setCourseData(coursesResponse.data);
        
        // Then fetch assignment history for each course
        const historyPromises = coursesResponse.data.map(course => 
          getTrainerAssignmentHistory(course.id)
            .then(response => response.data)
            .catch(err => {
              console.error(`Error fetching history for course ${course.id}:`, err);
              return [];
            })
        );

        const allHistories = await Promise.all(historyPromises);
        // Combine all histories into one array
        const combinedHistory = allHistories.flat();
        setAssignmentHistory(combinedHistory);
      }

      // Rest of your existing API calls
      const otherApiCalls = [
        { call: () => getUser(studentId), setter: setStudentData },
        { call: () => getStudyMaterialsForStudent(studentId), setter: setStudyMaterials },
        { call: () => listStudentFeedbacks1(studentId), setter: setFeedbackHistory },
        { call: () => getCourseHoldHistory1(studentId), setter: setHoldHistory },
        { call: () => getStudentAttendances({ student: studentId }), setter: setAttendanceData }
      ];

      await Promise.all(
        otherApiCalls.map(async ({ call, setter }) => {
          try {
            const response = await call();
            if (response && response.data) {
              setter(response.data);
            } else {
              console.warn('No data received for one of the API calls');
              setter([]);
            }
          } catch (err) {
            console.error('Error in individual API call:', err);
            setter([]);
          }
        })
      );
    } catch (err) {
      console.error('Error fetching student data:', err);
      setError('Failed to fetch student data. Please try again later.');
    } finally {
      setLoading(false);
    }
}, [studentId]);
  
  
  useEffect(() => {
    if (studentId) {
      fetchStudentData();
    }
  }, [fetchStudentData, studentId]);


  

  const CourseFlowCustomization = ({ studentId, courses }) => {
    const [availableItems, setAvailableItems] = useState([]);
    const [studentFlows, setStudentFlows] = useState([]);
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [currentFlowItems, setCurrentFlowItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [expandedItem, setExpandedItem] = useState(null);
  
    // First, fetch the student's course flows
    useEffect(() => {
      const fetchStudentFlows = async () => {
        setLoading(true);
        try {
          const response = await getStudentCourseFlows(studentId);
          console.log("Student flows:", response.data); // Debug log
          setStudentFlows(response.data);
          if (response.data.length === 1) {
            setSelectedFlow(response.data[0].id);
          }
        } catch (err) {
          console.error('Error fetching student flows:', err);
          setError('Failed to fetch student flows');
        } finally {
          setLoading(false);
        }
      };
  
      fetchStudentFlows();
    }, [studentId]);
  
    // Fetch available syllabus items
    useEffect(() => {
      const fetchAvailableItems = async () => {
        try {
          const response = await listSyllabusItems();
          console.log("Available items:", response.data); // Debug log
          setAvailableItems(response.data);
        } catch (err) {
          console.error('Error fetching syllabus items:', err);
          setError('Failed to fetch available items');
        }
      };
  
      fetchAvailableItems();
    }, []);
  
    // Fetch current flow items when a flow is selected
    useEffect(() => {
      const fetchCurrentFlow = async () => {
        if (!selectedFlow) return;
        
        setLoading(true);
        try {
          const response = await getStudentCourseFlow(selectedFlow);
          console.log("Current flow data:", response.data); // Debug log
          
          // Extract syllabus items from flow_items
          const items = response.data.flow_items.map(item => item.syllabus_item);
          setCurrentFlowItems(items);
        } catch (err) {
          console.error('Error fetching current flow:', err);
          setError('Failed to fetch current flow items');
        } finally {
          setLoading(false);
        }
      };
  
      fetchCurrentFlow();
    }, [selectedFlow]);
  
    const handleAddItem = async (itemId) => {
      if (!selectedFlow) return;
  
      try {
        console.log("Adding item:", itemId); // Debug log
        await customizeStudentFlow(selectedFlow, [itemId], []);
        
        // Update local state
        const newItem = availableItems.find(item => item.id === itemId);
        setCurrentFlowItems(prev => [...prev, newItem]);
        
        // Refresh the flow data
        const response = await getStudentCourseFlow(selectedFlow);
        const items = response.data.flow_items.map(item => item.syllabus_item);
        setCurrentFlowItems(items);
        
        setSnackbar({ open: true, message: 'Item added successfully', severity: 'success' });
      } catch (err) {
        console.error('Error adding item:', err);
        setSnackbar({ open: true, message: 'Failed to add item', severity: 'error' });
      }
    };

    const handleItemClick = (itemId) => {
      setExpandedItem(expandedItem === itemId ? null : itemId);
    };
  
    const handleRemoveItem = async (itemId) => {
      if (!selectedFlow) return;
  
      try {
        console.log("Removing item:", itemId); // Debug log
        await customizeStudentFlow(selectedFlow, [], [itemId]);
        
        // Update local state
        setCurrentFlowItems(prev => prev.filter(item => item.id !== itemId));
        
        // Refresh the flow data
        const response = await getStudentCourseFlow(selectedFlow);
        const items = response.data.flow_items.map(item => item.syllabus_item);
        setCurrentFlowItems(items);
        
        setSnackbar({ open: true, message: 'Item removed successfully', severity: 'success' });
      } catch (err) {
        console.error('Error removing item:', err);
        setSnackbar({ open: true, message: 'Failed to remove item', severity: 'error' });
      }
    };
  
    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;
  
    return (
      <GlassContainer>
        <Typography variant="h6" gutterBottom color="white">
          Customize Course Flow
        </Typography>
  
        <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
          <InputLabel id="flow-select-label" sx={{ color: 'white' }}>Select Course Flow</InputLabel>
          <Select
            labelId="flow-select-label"
            value={selectedFlow || ''}
            onChange={(e) => setSelectedFlow(e.target.value)}
            label="Select Course Flow"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.87)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
            }}
          >
            {studentFlows.map(flow => (
              <MenuItem key={flow.id} value={flow.id}>
                {flow.base_course_flow.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedFlow && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              Available Items
            </Typography>
            <List>
              {availableItems
                .filter(item => !currentFlowItems.some(current => current.id === item.id))
                .map(item => (
                  <Box key={item.id} sx={{ mb: 2 }}>
                    <ListItem 
                      sx={{
                        border: '1px solid rgba(255, 255, 255, 0.12)',
                        borderRadius: expandedItem === item.id ? '8px 8px 0 0' : '8px',
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleItemClick(item.id)}
                    >
                      <ListItemText 
                        primary={
                          <Typography color="white" sx={{ fontWeight: 'bold' }}>
                            {item.title}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton 
                          edge="end" 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddItem(item.id);
                          }}
                          sx={{ color: 'white' }}
                        >
                          <AddIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {expandedItem === item.id && (
                      <Box 
                        sx={{ 
                          p: 2,
                          bgcolor: 'rgba(255, 255, 255, 0.03)',
                          borderRadius: '0 0 8px 8px',
                          border: '1px solid rgba(255, 255, 255, 0.12)',
                          borderTop: 'none',
                        }}
                      >
                        <Typography variant="subtitle2" color="white" gutterBottom>
                          Contents:
                        </Typography>
                        {item.item_contents?.length > 0 ? (
                          item.item_contents.map((content) => (
                            <Box 
                              key={content.id} 
                              sx={{ 
                                pl: 2, 
                                py: 0.5,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography color="rgba(255, 255, 255, 0.7)" variant="body2">
                                {content.content.content_type}
                              </Typography>
                              {content.content.content_type === 'QUIZ' && (
                                <Typography color="rgba(255, 255, 255, 0.6)" variant="body2">
                                  {content.content.questions?.length || 0} Questions
                                </Typography>
                              )}
                            </Box>
                          ))
                        ) : (
                          <Typography color="rgba(255, 255, 255, 0.5)" variant="body2">
                            No contents available
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
            </List>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              Current Flow Items
            </Typography>
            <List>
              {currentFlowItems.map(item => (
                <Box key={item.id} sx={{ mb: 2 }}>
                  <ListItem 
                    sx={{
                      border: '1px solid rgba(255, 255, 255, 0.12)',
                      borderRadius: expandedItem === item.id ? '8px 8px 0 0' : '8px',
                      bgcolor: 'rgba(255, 255, 255, 0.05)',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleItemClick(item.id)}
                  >
                    <ListItemText 
                      primary={
                        <Typography color="white" sx={{ fontWeight: 'bold' }}>
                          {item.title}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton 
                        edge="end" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveItem(item.id);
                        }}
                        sx={{ color: 'white' }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {expandedItem === item.id && (
                    <Box 
                      sx={{ 
                        p: 2,
                        bgcolor: 'rgba(255, 255, 255, 0.03)',
                        borderRadius: '0 0 8px 8px',
                        border: '1px solid rgba(255, 255, 255, 0.12)',
                        borderTop: 'none',
                      }}
                    >
                      <Typography variant="subtitle2" color="white" gutterBottom>
                        Contents:
                      </Typography>
                      {item.item_contents?.length > 0 ? (
                        item.item_contents.map((content) => (
                          <Box 
                            key={content.id} 
                            sx={{ 
                              pl: 2, 
                              py: 0.5,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <Typography color="rgba(255, 255, 255, 0.7)" variant="body2">
                              {content.content.content_type}
                            </Typography>
                            {content.content.content_type === 'QUIZ' && (
                              <Typography color="rgba(255, 255, 255, 0.6)" variant="body2">
                                {content.content.questions?.length || 0} Questions
                              </Typography>
                            )}
                          </Box>
                        ))
                      ) : (
                        <Typography color="rgba(255, 255, 255, 0.5)" variant="body2">
                          No contents available
                          </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </GlassContainer>
  );
};

  const handleEditProfile = () => {
    if (!studentData) return;
    
    setEditMode(true);
    setUpdatedProfile({
      first_name: studentData.first_name || '',
      last_name: studentData.last_name || '',
      email: studentData.email || '',
      phone: studentData.phone || '',
      city: studentData.city || '',
      about_me: studentData.about_me || '',
      date_of_birth: studentData.date_of_birth || dayjs().format('YYYY-MM-DD'),
    });
  };

  const handleMaterialClick = (material) => {
    setSelectedMaterial(material);
  };

  const handleCloseMaterialDialog = () => {
    setSelectedMaterial(null);
  };

  const handleSaveProfile = async () => {
    try {
      const response = await updateUserMe(updatedProfile);
      if (response && response.data) {
        setStudentData({ ...studentData, ...response.data });
        setEditMode(false);
        setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
      } else {
        throw new Error('No data received from update');
      }
    } catch (err) {
      console.error('Error updating profile:', err);
      setSnackbar({ 
        open: true, 
        message: 'Failed to update profile. Please try again.', 
        severity: 'error' 
      });
    }
  };

  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleApplyHold = async () => {
    try {
      const response = await createCourseHold({
        student: studentId,
        start_date: newHold.start_date.format('YYYY-MM-DD'),
        end_date: newHold.end_date.format('YYYY-MM-DD'),
        reason: newHold.reason,
      });
      if (response && response.data) {
        setHoldHistory([...holdHistory, response.data]);
        setSnackbar({ open: true, message: 'Hold applied successfully', severity: 'success' });
        setNewHold({ start_date: null, end_date: null, reason: '' });
      }
    } catch (error) {
      console.error('Error applying hold:', error);
      setSnackbar({ open: true, message: 'Failed to apply hold', severity: 'error' });
    }
  };

  

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <GlassContainer maxWidth="sm">
        <Alert severity="error">{error}</Alert>
      </GlassContainer>
    );
  }

  return (
    <GlassContainer  sx={{ paddingBottom: '400px'}}>
      <Typography variant="h4" gutterBottom color="white">Student Details</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <GlassContainer>
            <Typography variant="h6" gutterBottom color="white">Profile</Typography>
            <Box display="flex" justifyContent="center" mb={2}>
              <Avatar
                src={studentData.profile_image}
                alt={studentData.full_name}
                sx={{ width: 100, height: 100 }}
              />
            </Box>
            {editMode ? (
              <>
                <StyledTextField
                  fullWidth
                  label="First Name"
                  value={updatedProfile.first_name}
                  onChange={(e) => setUpdatedProfile({ ...updatedProfile, first_name: e.target.value })}
                  margin="normal"
                />
                <StyledTextField
                  fullWidth
                  label="Last Name"
                  value={updatedProfile.last_name}
                  onChange={(e) => setUpdatedProfile({ ...updatedProfile, last_name: e.target.value })}
                  margin="normal"
                />
                <StyledTextField
                  fullWidth
                  label="Email"
                  value={updatedProfile.email}
                  onChange={(e) => setUpdatedProfile({ ...updatedProfile, email: e.target.value })}
                  margin="normal"
                />
                <StyledTextField
                  fullWidth
                  label="Phone"
                  value={updatedProfile.phone}
                  onChange={(e) => setUpdatedProfile({ ...updatedProfile, phone: e.target.value })}
                  margin="normal"
                />
                <StyledTextField
                  fullWidth
                  label="City"
                  value={updatedProfile.city}
                  onChange={(e) => setUpdatedProfile({ ...updatedProfile, city: e.target.value })}
                  margin="normal"
                />
                <StyledTextField
                  fullWidth
                  label="About Me"
                  value={updatedProfile.about_me}
                  onChange={(e) => setUpdatedProfile({ ...updatedProfile, about_me: e.target.value })}
                  margin="normal"
                  multiline
                  rows={3}
                />
                <DatePicker
                  label="Date of Birth"
                  value={dayjs(updatedProfile.date_of_birth)}
                  onChange={(date) => setUpdatedProfile({ ...updatedProfile, date_of_birth: date.format('YYYY-MM-DD') })}
                  renderInput={(params) => <StyledTextField {...params} fullWidth margin="normal" />}
                />
                <GradientButton onClick={handleSaveProfile} sx={{ mt: 2 }}>
                  Save Changes
                </GradientButton>
              </>
            ) : (
              <>
                <Typography color="white"><strong>Name:</strong> {`${studentData.full_name}`}</Typography>
                <Typography color="white"><strong>Email:</strong> {studentData.email}</Typography>
                <Typography color="white"><strong>Phone:</strong> {studentData.phone}</Typography>
                <Typography color="white"><strong>City:</strong> {studentData.city}</Typography>
                <Typography color="white"><strong>About:</strong> {studentData.about_me}</Typography>
                <Typography color="white"><strong>Date of Birth:</strong> {studentData.date_of_birth ? dayjs(studentData.date_of_birth).format('MMMM D, YYYY') : 'Not set'}</Typography>
                <GradientButton onClick={handleEditProfile} sx={{ mt: 2 }}>
                  Edit Profile
                </GradientButton>
              </>
            )}
          </GlassContainer>
        </Grid>
        <Grid item xs={12} md={8}>
          <GlassContainer>
            <Typography variant="h6" gutterBottom color="white">Course Information</Typography>
            <Grid container spacing={2}>
              {courseData.map((course) => (
                <Grid item xs={12} sm={6} key={course.id}>
                  <Box mb={2}>
                    <Typography color="white"><strong>Course:</strong> {course.course.name}</Typography>
                    <Typography color="white"><strong>Start Date:</strong> {dayjs(course.start_date).format('MMMM D, YYYY')}</Typography>
                    <Typography color="white"><strong>End Date:</strong> {dayjs(course.end_date).format('MMMM D, YYYY')}</Typography>
                    <Typography color="white"><strong>Class Time:</strong> {course.class_time || 'Not specified'}</Typography>
                    <Typography color="white"><strong>Trainer:</strong> {course.trainer ? course.trainer.full_name : 'Not assigned'}</Typography>
                    <Box mt={1}>
                      <GradientButton 
                        onClick={() => window.open(course.bbb_join_url, '_blank')}
                      >
                        Join Class Room
                      </GradientButton>
                    </Box>
                    <Divider sx={{ my: 1, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </GlassContainer>
        </Grid>
      </Grid>
      
      <Box mt={4}>
        <StyledTabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Class Content" />
          <Tab label="Study Materials" />
     
          <Tab label="Feedback History" />
          <Tab label="Hold History" />
          <Tab label="Attendance History" />
          <Tab label="Progress" />
          <Tab label="Course Flow" />
          <Tab label="Assignment History" />
        </StyledTabs>
        
        <TabPanel value={tabValue} index={0}>
          <Typography variant="h6" gutterBottom color="white">Class Content</Typography>
          <List>
            {attendanceData.map((attendance) => (
              <ListItem key={attendance.id}>
                <ListItemText
                  primary={<Typography color="white">{`Date: ${dayjs(attendance.timestamp).format('MMMM D, YYYY HH:mm')}`}</Typography>}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="rgba(255, 255, 255, 0.7)">
                        Class Content: {attendance.class_content || 'No content available'}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="rgba(255, 255, 255, 0.5)">
                        Student Feedback: {attendance.student_feedback}
                      </Typography>
                    </>
                  }
                />
                <Chip 
                  label={attendance.status} 
                  color={attendance.status === 'PRESENT' ? 'success' : 'default'}
                  sx={{ color: 'white', borderColor: 'white' }}
                />
              </ListItem>
            ))}
          </List>
        </TabPanel>
        
        <TabPanel value={tabValue} index={1}>
          <Typography variant="h6" gutterBottom color="white">Study Materials</Typography>
          <List>
            {studyMaterials.map((material) => (
              <ListItem 
                key={material.id} 
                button 
                onClick={() => handleMaterialClick(material)}
              >
                <ListItemIcon>
                  <DescriptionIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText 
                  primary={<Typography color="white">{material.topic}</Typography>}
                  secondary={
                    <Typography color="rgba(255, 255, 255, 0.7)">
                      Available until: {dayjs(material.expiry_date).format('MMMM D, YYYY')}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </TabPanel><TabPanel value={tabValue} index={6}>
  <CourseFlowCustomization 
    studentId={studentId}
    courses={courseData} 
  />
</TabPanel>
        <TabPanel value={tabValue} index={5}>
  <Typography variant="h6" gutterBottom color="white">Progress Tracking</Typography>
  <StudentProgress studentId={studentId} />
</TabPanel>
        
        <TabPanel value={tabValue} index={2}>
          <Typography variant="h6" gutterBottom color="white">Feedback History</Typography>
          <List>
            {feedbackHistory.map((feedback) => (
              <ListItem key={feedback.id}>
                <ListItemText
                  primary={<Typography color="white">{feedback.topic}</Typography>}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="rgba(255, 255, 255, 0.7)">
                        {feedback.content}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="rgba(255, 255, 255, 0.5)">
                        Status: {feedback.status}
                      </Typography>
                    </>
                  }
                />
                <Chip 
                  label={feedback.status} 
                  color={feedback.status === 'RESOLVED' ? 'success' : 'default'}
                  sx={{ color: 'white', borderColor: 'white' }}
                />
              </ListItem>
            ))}
          </List>
        </TabPanel><TabPanel value={tabValue} index={7}> {/* Adjust index based on your existing tabs */}
  <Typography variant="h6" gutterBottom color="white">Trainer Assignment History</Typography>
  <Timeline position="alternate">
    {assignmentHistory.map((record, index) => (
      <TimelineItem key={record.id}>
        <TimelineSeparator>
          <TimelineDot sx={{ 
            bgcolor: record.unassignment_date ? '#f44336' : '#4caf50',
            color: 'white'
          }}>
            {record.unassignment_date ? <PersonIcon /> : <PersonAddIcon />}
          </TimelineDot>
          {index < assignmentHistory.length - 1 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <GlassContainer>
            <Typography color="white" variant="h6" component="div">
              {record.reason}
            </Typography>
            <Typography color="white">
              Trainer: {record.trainer_name}
            </Typography>
            <Typography color="white">
              Course: {record.course_name}
            </Typography>
            <Typography color="rgba(255, 255, 255, 0.7)">
              Assigned: {dayjs(record.assignment_date).format('MMMM D, YYYY')}
            </Typography>
            {record.unassignment_date && (
              <Typography color="rgba(255, 255, 255, 0.7)">
                Unassigned: {dayjs(record.unassignment_date).format('MMMM D, YYYY')}
              </Typography>
            )}
            {record.notes && (
              <Typography color="rgba(255, 255, 255, 0.7)" variant="body2" sx={{ mt: 1 }}>
                Notes: {record.notes}
              </Typography>
            )}
          </GlassContainer>
        </TimelineContent>
      </TimelineItem>
    ))}
  </Timeline>
</TabPanel>
        
        <TabPanel value={tabValue} index={3}>
          <Typography variant="h6" gutterBottom color="white">Hold History</Typography>
          <List>
            {holdHistory.map((hold) => (
              <ListItem key={hold.id}>
                <ListItemText
                  primary={
                    <Typography color="white">
                      Hold from {dayjs(hold.start_date).format('MMMM D, YYYY')} to {dayjs(hold.end_date).format('MMMM D, YYYY')}
                    </Typography>
                  }
                  secondary={
                    <Typography color="rgba(255, 255, 255, 0.7)">
                      Reason: {hold.reason}
                    </Typography>
                  }
                />
                <Chip 
                  label={hold.status} 
                  color={hold.status === 'APPROVED' ? 'success' : 'default'}
                  sx={{ color: 'white', borderColor: 'white' }}
                />
              </ListItem>
            ))}
          </List>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom color="white">Apply New Hold</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Hold Start Date"
                  value={newHold.start_date}
                  onChange={(date) => setNewHold({ ...newHold, start_date: date })}
                  renderInput={(params) => <StyledTextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Hold End Date"
                  value={newHold.end_date}
                  onChange={(date) => setNewHold({ ...newHold, end_date: date })}
                  renderInput={(params) => <StyledTextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label="Hold Reason"
                  value={newHold.reason}
                  onChange={(e) => setNewHold({ ...newHold, reason: e.target.value })}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <GradientButton 
                  onClick={handleApplyHold} 
                  disabled={!newHold.start_date || !newHold.end_date || !newHold.reason}
                >
                  Apply Hold
                </GradientButton>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        
        <TabPanel value={tabValue} index={4}>
        <Typography variant="h6" gutterBottom color="white">Attendance History</Typography>
        <AttendanceManagement
          studentId={studentId}
          trainerId={courseData[0]?.trainer?.id}
          userRole={userRole} // Pass the user role from your authentication context
        />
      </TabPanel>
      </Box>
      
      <Dialog 
        open={Boolean(selectedMaterial)} 
        onClose={handleCloseMaterialDialog} 
        fullWidth 
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 18, 0.8)',
            backdropFilter: 'blur(10px)',
            color: 'white',
          },
        }}
      >
        <DialogTitle>{selectedMaterial?.topic}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedMaterial?.course?.name}</Typography>
          <Typography variant="body2">
            Available until: {dayjs(selectedMaterial?.expiry_date).format('MMMM D, YYYY')}
          </Typography>
          <List>
            {selectedMaterial?.files.map((file) => (
              <ListItem key={file.id}>
                <ListItemIcon>
                  <DescriptionIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary={file.file_name} />
                <Box>
                  {file.file_type.startsWith('image/') ? (
                    <img src={file.file} alt={file.file_name} style={{ maxWidth: '100%', maxHeight: '300px' }} />
                  ) : file.file_type === 'application/pdf' ? (
                    <iframe src={file.file} width="100%" height="500px" title={file.file_name} />
                  ) : (
                    <Typography variant="body2">Preview not available for this file type.</Typography>
                  )}
                </Box>
                <GradientButton href={file.file} target="_blank" rel="noopener noreferrer">
                  Download
                </GradientButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={handleCloseMaterialDialog}>
            Close
          </GradientButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassContainer>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}</Box>
      )}
    </div>
  );
};

export default StudentDetails;