import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Button, CircularProgress, Alert, Link, Dialog,
  DialogTitle, DialogContent, DialogActions, TextField, Snackbar, Grid,
  useTheme, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { 
  getSalaryHistory, 
  downloadSalarySlip, 
  getCurrentUser, 
  handleApiError, 
  requestLeave, 
  listLeaveRequests, 
  getLeaveHistory,
  getLeaveRequestHistory,
  listTrainerAudits,
  addTrainerRemarks, 
  getCurrentMonthBaseSalary
} from '../api';
import SalaryBreakdownModal from './SalaryBreakdownModal';
import AuditDetailsModal from '../components/AuditDetailsModal';
import AddAuditModal from '../components/AddAuditModal';
import AuditHistoryModal from '../components/AuditHistoryModal';

const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

function MyEmploymentPage() {
  const [salaryHistory, setSalaryHistory] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [leaveRequestHistory, setLeaveRequestHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedSalary, setSelectedSalary] = useState(null);
  const [salaryModalOpen, setSalaryModalOpen] = useState(false);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [leaveFormData, setLeaveFormData] = useState({
    start_date: '',
    end_date: '',
    reason: '',
    uploaded_files: []
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [audits, setAudits] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]); 
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [auditDetailsModalOpen, setAuditDetailsModalOpen] = useState(false);
  const [currentMonthSalary, setCurrentMonthSalary] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchCurrentUserAndData();
  }, []);
  
  const fetchCurrentUserAndData = async () => {
    try {
      setLoading(true);
      const userResponse = await getCurrentUser();
      setCurrentUser(userResponse.data);
  
      const [
        historyResponse, 
        leaveRequestsResponse, 
        leaveHistoryResponse, 
        leaveRequestHistoryResponse,
        currentMonthSalaryResponse
      ] = await Promise.all([
        getSalaryHistory(userResponse.data.id),
        listLeaveRequests(),
        getLeaveHistory(),
        getLeaveRequestHistory(),
        getCurrentMonthBaseSalary(userResponse.data.id)
      ]);
  
      console.log('Current Month Salary Response:', currentMonthSalaryResponse);
  
      setSalaryHistory(historyResponse.data || []);
      setLeaveRequests(leaveRequestsResponse.data || []);
      setLeaveHistory(leaveHistoryResponse.data || []);
      setLeaveRequestHistory(leaveRequestHistoryResponse.data || []);
      
      // Check if currentMonthSalaryResponse has a 'data' property
      if (currentMonthSalaryResponse && currentMonthSalaryResponse.data) {
        setCurrentMonthSalary(currentMonthSalaryResponse.data);
      } else {
        // If there's no 'data' property, use the response directly
        setCurrentMonthSalary(currentMonthSalaryResponse);
      }
  
      console.log('Current Month Salary State:', currentMonthSalaryResponse.data || currentMonthSalaryResponse);
  
      if (userResponse.data.role === 'TRAINER') {
        const auditsResponse = await listTrainerAudits(userResponse.data.id);
        setAudits(auditsResponse.data || []);
      }
    } catch (error) {
      console.error('Error in fetchCurrentUserAndData:', error);
      setError(handleApiError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSlip = async (year, month) => {
    try {
      if (!currentUser) {
        throw new Error('Current user not found');
      }
      const response = await downloadSalarySlip(currentUser.id, { year, month });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `salary_slip_${year}_${month}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to download salary slip', severity: 'error' });
    }
  };

  const handleMonthClick = (salary) => {
    setSelectedSalary(salary);
    setSalaryModalOpen(true);
  };

  const handleLeaveRequest = async () => {
    try {
      const formData = new FormData();
      formData.append('start_date', leaveFormData.start_date);
      formData.append('end_date', leaveFormData.end_date);
      formData.append('reason', leaveFormData.reason);
      
      // Append each file to the FormData
      leaveFormData.uploaded_files.forEach((file, index) => {
        formData.append(`uploaded_files`, file);
      });
  
      await requestLeave(formData);
      setSnackbar({ open: true, message: 'Leave request submitted successfully', severity: 'success' });
      setLeaveDialogOpen(false);
      fetchCurrentUserAndData();
    } catch (error) {
      const errorMessage = handleApiError(error);
      setSnackbar({ 
        open: true, 
        message: errorMessage || 'Failed to submit leave request. Please try again.', 
        severity: 'error' 
      });
    }
  };

  const handleLeaveFormChange = (event) => {
    const { name, value, files } = event.target;
    if (name === 'uploaded_files') {
      // Update both the files and their names
      setLeaveFormData(prev => ({ ...prev, [name]: Array.from(files) }));
      setSelectedFileNames(Array.from(files).map(file => file.name));
    } else {
      setLeaveFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAddRemarks = async (auditId, remarks) => {
    try {
      await addTrainerRemarks(auditId, remarks);
      fetchCurrentUserAndData();
      setAuditDetailsModalOpen(false);
      setSnackbar({ open: true, message: 'Remarks added successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: handleApiError(error), severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    );
  }

  return (
    <GlassContainer  sx={{ paddingBottom: '400px' }}>
      <Typography variant="h4" gutterBottom color="white">My Employment</Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      
      <Box my={4}>
      <Typography variant="h5" gutterBottom color="white">Current Month's Base Salary (Tentative)</Typography>
      {currentMonthSalary ? (
        <GlassContainer sx={{ p: 2 }}>
          <Typography variant="h6" color="white">
            Current Month Base Salary: {currentMonthSalary.current_month_base_salary || 'N/A'}
          </Typography>
         
          <Typography color="white">
            Hours Worked: {currentMonthSalary.calculation_details?.hours_worked || 'N/A'}
          </Typography>
          <Typography color="white">
            As of: {currentMonthSalary.calculation_details?.as_of_date 
              ? new Date(currentMonthSalary.calculation_details.as_of_date).toLocaleDateString() 
              : 'N/A'}
          </Typography>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" sx={{ mt: 2, fontStyle: 'italic' }}>
            Note: This is a tentative amount and does not include compensation for group classes, or any deductions or incentives
          </Typography>
        </GlassContainer>
      ) : (
        <Typography color="white">Current month's salary information not available.</Typography>
      )}
    </Box>

      <Box my={4}>
        <Typography variant="h5" gutterBottom color="white">Salary History</Typography>
        {salaryHistory.length > 0 ? (
          <GlassTableContainer sx={{ maxHeight: 400, overflow: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Month</TableCell>
                  <TableCell>Total Salary</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salaryHistory.map((salary) => (
                  <TableRow key={`${salary.year}-${salary.month}`}>
                    <TableCell>{salary.year}</TableCell>
                    <TableCell>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => handleMonthClick(salary)}
                        sx={{ color: 'white', textDecoration: 'none' }}
                      >
                        {dayjs().month(salary.month - 1).format('MMMM')}
                      </Link>
                    </TableCell>
                    <TableCell>₹{parseFloat(salary.total_salary).toFixed(2)}</TableCell>
                    <TableCell>
                      <GradientButton 
                        onClick={() => handleDownloadSlip(salary.year, salary.month)}
                      >
                        Download Slip
                      </GradientButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </GlassTableContainer>
        ) : (
          <Typography color="white">No salary history available.</Typography>
        )}
      </Box>
      
      <Box my={4}>
        <Typography variant="h5" gutterBottom color="white">Leave Requests</Typography>
        <GradientButton onClick={() => setLeaveDialogOpen(true)} sx={{ mb: 2 }}>
          Request Leave
        </GradientButton>
        <GlassTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveRequests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{new Date(request.start_date).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(request.end_date).toLocaleDateString()}</TableCell>
                  <TableCell>{request.reason}</TableCell>
                  <TableCell>{request.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GlassTableContainer>
      </Box>

      <Box my={4}>
        <Typography variant="h5" gutterBottom color="white">Leave Request History</Typography>
        <GlassTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Admin Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveRequestHistory.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{new Date(request.start_date).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(request.end_date).toLocaleDateString()}</TableCell>
                  <TableCell>{request.reason}</TableCell>
                  <TableCell>{request.status}</TableCell>
                  <TableCell>{request.admin_remarks || '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GlassTableContainer>
      </Box>

      <Box my={4}>
        <Typography variant="h5" gutterBottom color="white">Leave History</Typography>
        <GlassTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Month</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Leaves Taken</TableCell>
                <TableCell>Leaves Remaining</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveHistory.map((history) => (
                <TableRow key={`${history.year}-${history.month}`}>
                  <TableCell>{dayjs().month(history.month - 1).format('MMMM')}</TableCell>
                  <TableCell>{history.year}</TableCell>
                  <TableCell>{history.leaves_taken}</TableCell>
                  <TableCell>{history.leaves_remaining}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GlassTableContainer>
      </Box>

      {currentUser && currentUser.role === 'TRAINER' && (
        <Box my={4}>
          <Typography variant="h5" gutterBottom color="white">My Audits</Typography>
          <GlassTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Audit Date</TableCell>
                  <TableCell>Overall Score</TableCell>
                  <TableCell>Auditor</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {audits.map((audit) => (
                  <TableRow key={audit.id}>
                    <TableCell>{new Date(audit.audit_date).toLocaleDateString()}</TableCell>
                    <TableCell>{audit.overall_score}</TableCell>
                    <TableCell>{audit.auditor.full_name}</TableCell>
                    <TableCell>
                      <GradientButton
                        onClick={() => {
                          setSelectedAudit(audit);
                          setAuditDetailsModalOpen(true);
                        }}
                      >
                        View Details
                      </GradientButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </GlassTableContainer>
        </Box>
      )}

      <SalaryBreakdownModal
        open={salaryModalOpen}
        onClose={() => setSalaryModalOpen(false)}
        salaryData={selectedSalary}
      />

      <Dialog 
        open={leaveDialogOpen} 
        onClose={() => setLeaveDialogOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 18, 0.8)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <DialogTitle sx={{ color: 'white' }}>Request Leave</DialogTitle>
        <DialogContent>
          <StyledTextField
            fullWidth
            label="Start Date"
            type="date"
            name="start_date"
            value={leaveFormData.start_date}
            onChange={handleLeaveFormChange}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          <StyledTextField
            fullWidth
            label="End Date"
            type="date"
            name="end_date"
            value={leaveFormData.end_date}
            onChange={handleLeaveFormChange}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          <StyledTextField
            fullWidth
            label="Reason"
            multiline
            rows={4}
            name="reason"
            value={leaveFormData.reason}
            onChange={handleLeaveFormChange}
            margin="normal"
          />
          <Box sx={{ mt: 2, mb: 1 }}>
        <input
          accept="image/*,application/pdf"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          name="uploaded_files"
          onChange={handleLeaveFormChange}
        />
        <label htmlFor="raised-button-file">
          <GradientButton component="span" fullWidth>
            Upload Attachments
          </GradientButton>
        </label>
      </Box>
      
      {/* Display selected files */}
      {selectedFileNames.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'white', mb: 1 }}>
            Selected Files:
          </Typography>
          {selectedFileNames.map((fileName, index) => (
            <Typography 
              key={index} 
              variant="body2" 
              sx={{ 
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '0.875rem'
              }}
            >
              {fileName}
            </Typography>
          ))}
        </Box>
      )}
    </DialogContent>
        <DialogActions>
          <Button onClick={() => setLeaveDialogOpen(false)} sx={{ color: 'white' }}>Cancel</Button>
          <GradientButton onClick={handleLeaveRequest}>
            Submit Request
          </GradientButton>
        </DialogActions>
      </Dialog>

      <AuditDetailsModal
        open={auditDetailsModalOpen}
        onClose={() => setAuditDetailsModalOpen(false)}
        audit={selectedAudit}
        onAddRemarks={handleAddRemarks}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassContainer>
  );
}

export default MyEmploymentPage;