import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAttendance, updateAttendance } from '../api';
import { formatDate } from '../utils/dateUtils';
import AttendanceCalendar from './AttendanceCalendar';

const GlassContainer = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '15px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  padding: theme.spacing(3),
  color: theme.palette.text.primary,
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.03)',
  borderRadius: '10px',
  '& .MuiTableCell-root': {
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const ClassContentSection = ({ studentId }) => {
  const [classContents, setClassContents] = useState([]);
  const [displayedContents, setDisplayedContents] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchClassContents = async () => {
      try {
        const response = await getAttendance({ student: studentId });
        const contentsWithClassContent = response.data.filter(attendance => attendance.class_content);
        setClassContents(contentsWithClassContent);
        setDisplayedContents(contentsWithClassContent.slice(0, 5));
      } catch (error) {
        console.error('Error fetching class contents:', error);
        setSnackbar({ open: true, message: 'Failed to fetch class contents', severity: 'error' });
      }
    };

    fetchClassContents();
  }, [studentId]);

  const handleFeedback = async (attendanceId, feedback) => {
    try {
      const attendanceToUpdate = classContents.find(content => content.id === attendanceId);
      if (!attendanceToUpdate) {
        throw new Error('Attendance record not found');
      }

      await updateAttendance(attendanceId, {
        student: studentId,
        status: attendanceToUpdate.status,
        student_feedback: feedback
      });

      const updatedContents = classContents.map(content => 
        content.id === attendanceId ? { ...content, student_feedback: feedback } : content
      );
      setClassContents(updatedContents);
      setDisplayedContents(showAll ? updatedContents : updatedContents.slice(0, 5));
      setSnackbar({ open: true, message: `Class content ${feedback.toLowerCase()}ed`, severity: 'success' });
    } catch (error) {
      console.error('Error updating feedback:', error);
      setSnackbar({ open: true, message: 'Failed to update feedback', severity: 'error' });
    }
  };

  const handleShowMore = () => {
    setShowAll(true);
    setDisplayedContents(classContents);
  };

  return (
    <GlassContainer>
      <Typography variant="h6" gutterBottom color="white">Class Content Updates</Typography>
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: 'white' }}>Date</TableCell>
              <TableCell style={{ color: 'white' }}>Class Content</TableCell>
              <TableCell style={{ color: 'white' }}>Status</TableCell>
              <TableCell style={{ color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedContents.map((content) => (
              <TableRow key={content.id}>
                <TableCell style={{ color: 'white' }}>{formatDate(content.timestamp)}</TableCell>
                <TableCell style={{ color: 'white' }}>{content.class_content}</TableCell>
                <TableCell style={{ color: 'white' }}>{content.student_feedback || 'Pending'}</TableCell>
                <TableCell>
                  {content.student_feedback === 'NO_ACTION' && (
                    <Box display="flex" gap={1}>
                      <GradientButton 
                        onClick={() => handleFeedback(content.id, 'ACCEPTED')} 
                        size="small" 
                      >
                        Accept
                      </GradientButton>
                      <GradientButton 
                        onClick={() => handleFeedback(content.id, 'REJECTED')} 
                        size="small"
                      >
                        Reject
                      </GradientButton>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {!showAll && classContents.length > 5 && (
        <Box mt={2} display="flex" justifyContent="center">
          <GradientButton onClick={handleShowMore}>
            Show More
          </GradientButton>
        </Box>
      )}
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassContainer>
  );
};

export default ClassContentSection;