import axios from 'axios';
import axiosInstance from 'axios'

const BASE_URL = 'https://dj.nilconnect.in'; // or your actual backend URL
const API_URL = `${BASE_URL}/api`;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true, // You might not need this for token auth
  headers: {
    'Content-Type': 'application/json',
  }
});
export const BASE1_URL = 'https://dj.nilconnect.in';

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const fetchCSRFToken = async () => {
  try {
    await api.get('/get-csrf-token/');
    console.log('CSRF token fetched successfully');
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
};

const FRONTEND_URL = window.location.origin;
// Authentication
export const login = async (username, password) => {
  try {
    const response = await api.post('/auth/login/', { username, password });
    console.log('Login response:', response.data);
    
    if (response.data && response.data.key) {
      const { key } = response.data;
      localStorage.setItem('token', key);
      api.defaults.headers.common['Authorization'] = `Token ${key}`;
    } else {
      throw new Error('No token received from server');
    }
    
    return response;
  } catch (error) {
    console.error('Login error:', error);
    if (error.response) {
      console.error('Error status:', error.response.status);
      console.error('Error data:', error.response.data);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
    throw error;
  }
};

export const getImageUrl = (imageUrl) => {
  if (!imageUrl) return '';
  if (imageUrl.startsWith('http')) return imageUrl;
  return `${BASE_URL}${imageUrl}`;
};
export const checkTokenValidity = async () => {
  try {
    await getCurrentUser();
    return true;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      delete api.defaults.headers.common['Authorization'];
    }
    return false;
  }
};

// Trainer Audits
export const getTrainerAudits = (trainerId) => {
  return api.get('/trainer-audits/trainer_audits/', { params: { trainer_id: trainerId } });
};
export const startTokenValidityCheck = (intervalInMinutes = 5) => {
  setInterval(async () => {
    const isValid = await checkTokenValidity();
    if (!isValid) {
      // Optionally, you can trigger a logout action or redirect to login page
      console.log('Token is no longer valid');
    }
  }, intervalInMinutes * 60 * 1000);
};
export const register = (userData) => {
  const registrationData = {
    ...userData,
    role: 'STUDENT'  // Always set role to STUDENT
  };
  return api.post('/register/', registrationData);
};

export const logout = () => api.post('/accounts/logout/');
export const googleAuth = (idToken) => api.post('/auth/google/', { id_token: idToken });

export const updateUserMe = (formData) => {
  return api.put('/me/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getTrainerMonthlyHours = async (trainerId, year, month) => {
  try {
    const response = await api.get(`/trainers/${trainerId}/monthly_hours/`, {
      params: { year, month }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trainer monthly hours:', error);
    throw error;
  }
};
// User Management
export const getCurrentUser = () => api.get('/me/');
export const getUser = (id) => api.get(`/users/${id}/`);
export const updateUser = (id, userData) => api.put(`/users/${id}/`, userData);



export const listUsers = async ({ page = 1, page_size = 10, roles = [], search = '' } = {}) => {
  const params = new URLSearchParams({
    page: page,
    page_size: page_size,
  });

  if (roles && roles.length > 0) {
    roles.forEach(role => params.append('role', role));
  }

  if (search) {
    params.append('search', search);
  }

  const response = await api.get(`/users/?${params.toString()}`);
  return response.data;
};
export const createUser = (userData) => api.post('/users/', userData);

export const changeUserRole = (id, role) => api.patch(`/users/${id}/change_role/`, { role });
export const assignCoordinator = (userId, coordinatorId) => 
  api.post(`/users/${userId}/assign_coordinator/`, { coordinator_id: coordinatorId });
export const removeCoordinator = (userId) => api.post(`/users/${userId}/remove_coordinator/`);

// Trainer Management
export const listTrainers = async ({ page = 1, page_size = 10, search = '' }) => {
  const params = new URLSearchParams({
    page,
    page_size,
    search,
  });

  try {
    const response = await api.get(`/trainers/?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching trainers:', error);
    throw error;
  }
};

export const getUserCompetitionRankings = (userId) => {
  return api.get(`/users/${userId}/competition-rankings/`);
};

// Also add a new function to get competition-specific rankings
export const getCompetitionRankings = (competitionId, page = 1, page_size = 10) =>
  api.get(`/competitions/${competitionId}/leaderboard/`, {
    params: {
      page: page,
      page_size: page_size
    }
  });


export const getCurrentMonthBaseSalary = async (userId) => {
  try {
    const response = await api.get(`/users/${userId}/current_month_base_salary/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching current month base salary:', error);
    throw error;
  }
};

// Add this with your other salary-related API functions
export const getDetailedSalaryCalculation = (userId, params) => 
  api.get(`/users/${userId}/detailed_salary_calculation/`, { 
    params: {
      year: params.year,
      month: params.month
    }
  });
export const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`/users/${userId}/delete_user/`);
    
    if (response.status === 200) {
      console.log('User deleted successfully');
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting user:', error.response?.data || error.message);
    throw error;
  }
};
export const getTrainer = (id) => api.get(`/trainers/${id}/`).catch(error => {
  console.error('Error fetching trainer:', error.response?.data || error.message);
  throw error;
});
export const updateTrainer = (id, trainerData) => {
  // Check if trainerData is FormData
  const isFormData = trainerData instanceof FormData;
  
  const config = {
    headers: isFormData ? {
      'Content-Type': 'multipart/form-data'
    } : {
      'Content-Type': 'application/json'
    }
  };

  return api.put(`/trainers/${id}/`, trainerData, config);
};

export const updateTrainerUser = (userId, userData) => {
  return api.put(`/users/${userId}/`, userData);
};
export const createTrainer = (trainerData) => api.post('/trainers/', trainerData);
export const updateTrainerMeetingLinks = (id, linkData) => api.patch(`/trainers/${id}/update_meeting_links/`, linkData);

export const assignTrainerToCourse = (courseId, trainerId) => 
  api.post(`/student-courses/${courseId}/assign_trainer/`, { trainer_id: trainerId });

export const searchUserByEmail = (email) => {
  return api.get('/users/search_by_email/', { params: { email } });
};

// Student Management
export const listStudents = () => api.get('/student-profiles/');
export const getStudent = (id) => api.get(`/student-profiles/${id}/`);
export const updateStudent = (id, userData) => api.put(`/users/${id}/`, userData);
export const createStudentProfile = (studentData) => api.post('/student-profiles/', studentData);

// Coordinator Management
export const listCoordinators = () => {
  return api.get('/coordinators/').then(response => response.data);
};
export const getCoordinator = (id) => api.get(`/coordinators/${id}/`);
export const createCoordinator = (formData) => {
  return api.post('/coordinators/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateCoordinator = (id, formData) => {
  return api.put(`/coordinators/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const deleteCoordinator = (id) => api.delete(`/coordinators/${id}/`);

// Course Management
export const listCourses = () => api.get('/courses/');
export const getCourse = (id) => api.get(`/courses/${id}/`);
export const createCourse = (courseData) => api.post('/courses/', courseData);
export const updateCourse = (id, courseData) => api.put(`/courses/${id}/`, courseData);
export const deleteCourse = (id) => api.delete(`/courses/${id}/`);
export const assignTrainersToCourse = (courseId, trainerAssignments) => 
  api.post(`/courses/${courseId}/assign_trainers/`, { trainer_assignments: trainerAssignments });
export const assignTrainerToGroupCourse = (courseId, trainerAssignments) => 
  api.post(`/courses/${courseId}/assign_trainers/`, { trainer_assignments: trainerAssignments });
export const assignStudentToGroupCourse = (courseId, studentId, startDate, endDate) => 
  api.post('/student-courses/', { 
    course: courseId, 
    student: studentId, 
    start_date: startDate, 
    end_date: endDate 
  });

  export const listGroupCourses = () => {
    return api.get('/courses/', { params: { is_group_class: true } });
  };

  export const getTrainerWorkingWindows = (userId) => 
    api.get('/trainer-working-windows/for_trainer/', { 
      params: { trainer_id: userId }  // Using user_id instead of trainer.id
    });
  
    export const createWorkingWindow = (trainerId, windowData) => {
      const payload = {
        ...windowData,
        trainer_id: trainerId  // Add trainer_id to payload if admin/manager is creating
      };
      return api.post('/trainer-working-windows/', payload);
    };
  
  export const updateWorkingWindow = (windowId, windowData) => 
    api.put(`/trainer-working-windows/${windowId}/`, windowData);
  
  export const deleteWorkingWindow = (windowId) => 
    api.delete(`/trainer-working-windows/${windowId}/`);
// Student Courses
export const listStudentCourses = (params) => api.get('/student-courses/', { params });
export const getStudentCourse = (id) => api.get(`/student-courses/${id}/`);
export const getStudentCourses = (studentId) => api.get('/student-courses/', { params: { student: studentId } });
export const createStudentCourse = (studentCourseData) => api.post('/student-courses/', studentCourseData);
export const updateStudentCourse = (id, studentCourseData) => api.put(`/student-courses/${id}/`, studentCourseData);
export const extendStudentCourse = (id, newEndDate) => api.post(`/student-courses/${id}/extend_course/`, { new_end_date: newEndDate });
export const reassignTrainer = (id, newTrainerId) => api.post(`/student-courses/${id}/reassign_trainer/`, { trainer_id: newTrainerId });
export const listTrainersForCourse = (courseId) => api.get(`/student-courses/${courseId}/list_trainers/`);
export const addTrainerToCourse = (courseId, trainerId) => api.post(`/student-courses/${courseId}/add_trainer/`, { trainer_id: trainerId });
export const removeTrainerFromCourse = (courseId, trainerId) => api.post(`/student-courses/${courseId}/remove_trainer/`, { trainer_id: trainerId });
export const updateCourseTrainers = (courseId, trainerIds) => api.post(`/student-courses/${courseId}/update_trainers/`, { trainer_ids: trainerIds });

//for studentsdetailspage specifically
export const getStudentCourse1 = (studentId) => {
  return api.get(`/student-courses`, { params: { student: studentId } });
}

// Course Holds
export const listCourseHolds = () => api.get('/course-holds/');
export const getCourseHold = (id) => api.get(`/course-holds/${id}/`);
export const createCourseHold = (holdData) => api.post('/course-holds/', holdData);
export const approveCourseHold = (id) => api.post(`/course-holds/${id}/approve/`);
export const rejectCourseHold = (id) => api.post(`/course-holds/${id}/reject/`);

// Study Materials
export const listStudyMaterials = () => api.get('/study-materials/');
export const getStudyMaterial = (id) => api.get(`/study-materials/${id}/`);
export const createStudyMaterial = (formData) => 
  api.post('/study-materials/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
export const getStudyMaterialsForStudent = (studentId) => api.get('/study-materials/for_student/', { params: { student_id: studentId } });
export const getStudyMaterialsForCourse = (courseId) => api.get('/study-materials/for_course/', { params: { course_id: courseId } });

// Student Feedback
export const listStudentFeedbacks = () => api.get('/student-feedback/');
export const listStudentFeedbacks1 = (studentId) => api.get('/student-feedback/', { params: { student: studentId } });
export const getStudentFeedback = (id) => api.get(`/student-feedback/${id}/`);
export const createStudentFeedback = (feedbackData) => api.post('/student-feedback/', feedbackData);
export const respondToStudentFeedback = (id, responseData) => api.post(`/student-feedback/${id}/respond/`, responseData);
export const getStudentFeedbackHistory = () => api.get('/student-feedback/history/');

// In api.js - Updated function
export const getTrainerAssignmentHistory = (studentCourseId) => {
  return api.get(`/student-courses/${studentCourseId}/assignment_history/`);
};

// If you need to get history for a specific course
export const getCourseAssignmentHistory = (courseId) => {
  return api.get(`/student-courses/${courseId}/assignment_history/`);
};
// Trainer Assignments
export const listTrainerAssignments = () => api.get('/trainer-assignments/');
export const getTrainerAssignment = (id) => api.get(`/trainer-assignments/${id}/`);
export const createTrainerAssignment = (assignmentData) => api.post('/trainer-assignments/', assignmentData);
export const getTrainerOccupation = (trainerId, date) => api.get(`/trainer-assignments/trainer_occupation/`, { params: { trainer_id: trainerId, date } });
export const getTrainerAvailability = (trainerId, date) => api.get(`/trainer-assignments/trainer_availability/`, { params: { trainer_id: trainerId, date } });
export const getAvailableTrainers = (classTime, duration, date) => api.get('/trainer-assignments/available_trainers/', { params: { class_time: classTime, duration, date } });
export const getAvailableTrainersExtended = (startTime, duration, startDate) => api.get('/trainer-assignments/available_trainers_extended/', { params: { start_time: startTime, duration, start_date: startDate } });

// Attendance
export const listAttendances = (params) => api.get('/attendances/', { params });
export const getAttendance = (params) => api.get('/attendances/', { params });
export const getStudentAttendances = (params) => api.get('/attendances/', { params });
export const createAttendance = (attendanceData) => api.post('/attendances/', attendanceData);
export const updateAttendance = async (attendanceId, data) => {
  return api.put(`/attendances/${attendanceId}/`, {
    ...data,
    student: Number(data.student) // Ensure it's a number
  });
};export const addClassContent = (id, contentData) => api.post(`/attendances/${id}/add_class_content/`, contentData);
export const provideStudentFeedback = (id, feedbackData) => api.post(`/attendances/${id}/provide_student_feedback/`, feedbackData);
export const changeAttendanceStatus = (attendanceId, newStatus) => {
  return api.post(`/attendances/${attendanceId}/change_status/`, { status: newStatus });
};
export const getPresentAttendancesYesterday = () => api.get('/attendances/present_yesterday/');
export const getTrainerAbsentAttendancesYesterday = () => api.get('/attendances/trainer_absent_yesterday/');
export const requestAttendanceReview = (id) => api.post(`/attendances/${id}/request_review/`);

export const getAttendanceReview = (id) => api.get(`/attendance-reviews/${id}/`);

export const updateAttendanceReview = async (reviewId, data) => {
  const response = await axiosInstance.patch(`/api/attendance-reviews/${reviewId}/`, data);
  return response.data;
};
export const listAttendanceReviews = () => api.get('/attendance-reviews/');
export const createAttendanceReview = (reviewData) => api.post('/attendance-reviews/', reviewData);
export const processAttendanceReview = async (reviewId, status) => {
  return api.post(`/attendance-reviews/${reviewId}/process/`, { status });
};
export const getAttendanceReviewHistory = () => api.get('/attendance-reviews/history/');


// BigBlueButton Integration
export const listBBBRooms = () => api.get('/bbb/rooms/');
export const getBBBRoom = (id) => api.get(`/bbb/rooms/${id}/`);
export const joinBBBRoom = (id) => api.get(`/bbb/rooms/${id}/join/`);
export const isBBBRoomRunning = (id) => api.get(`/bbb/rooms/${id}/is_running/`);
export const getBBBRoomAccessInfo = (id) => api.get(`/bbb/rooms/${id}/access_info/`);

export const listBBBRecordings = (params) => {
  return api.get('/bbb/recordings/', { params: {
    trainer: params.trainer,
    student: params.student,
    start_date: params.startDate,
    end_date: params.endDate,
    ...params
  }});
};
export const getTrainerGroupCourses = (trainerId) => {
  return api.get(`/courses/trainer_group_courses/`, { params: { trainer_id: trainerId } });
};


export const deleteBBBRecording = (recordingId) => api.delete(`/bbb/recordings/${recordingId}/`);
export const updateBBBRecording = (recordingId, updateData) => api.put(`/bbb/recordings/${recordingId}/`, updateData);
export const getBBBRecordingPlayback = (recordingId) => api.get(`/bbb/recordings/${recordingId}/playback/`);

// Messaging
export const listMessages = () => api.get('/messages/');
export const getMessage = (id) => api.get(`/messages/${id}/`);
export const sendMessage = (formData, config) => {
  return api.post('/messages/', formData, config);
};
export const markMessageAsRead = (id) => api.post(`/messages/${id}/mark_as_read/`);
export const listInboxMessages = () => api.get('/messages/inbox/');
export const listSentMessages = () => api.get('/messages/sent/');
export const listUnreadMessages = () => api.get('/messages/unread/');
export const pinMessage = (id) => api.post(`/messages/${id}/pin/`);
export const unpinMessage = (id) => api.post(`/messages/${id}/unpin/`);
export const softDeleteMessage = (id) => api.post(`/messages/${id}/soft_delete/`);
export const getConversation = (id) => api.get(`/messages/${id}/conversation/`);
export const listPinnedMessages = () => api.get('/messages/pinned/');

// Team Communication
export const listTeamUpdates = () => api.get('/team-updates/');
export const getTeamUpdate = (id) => api.get(`/team-updates/${id}/`);
export const createTeamUpdate = (updateData, files, links) => {
  const formData = new FormData();
  formData.append('content', updateData.content);
  
  files.forEach((file, index) => {
    formData.append(`attachments`, file);
  });

  formData.append('links', JSON.stringify(links));

  return api.post('/team-updates/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


export const likeTeamUpdate = (id) => api.post(`/team-updates/${id}/like/`);
export const commentOnTeamUpdate = (id, commentData) => api.post(`/team-updates/${id}/comment/`, commentData);
export const pinUnpinTeamUpdate = (id) => api.post(`/team-updates/${id}/pin/`);
export const deleteTeamUpdate = (updateId) => api.delete(`/team-updates/${updateId}/`);
export const createUpdateAttachment = (updateId, formData) => {
  return api.post(`/team-updates/${updateId}/attachments/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


// Notices
export const listNotices = () => api.get('/notices/');

export const getNotice = (id) => api.get(`/notices/${id}/`);
// api.js
export const createNotice = async (formData) => {
  return await api.post('/notices/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};


export const deleteNotice = (noticeId) => {
  return api.delete(`/notices/${noticeId}/`);
};

// Calculate Salary
export const calculateSalary = (userId, params) => 
  api.get(`/users/${userId}/calculate_salary/`, { params });

// Update Salary
export const updateSalary = (userId, data) => 
  api.post(`/users/${userId}/update_salary/`, data);

// Get Salary History
export const getSalaryHistory = (userId, params) => 
  api.get(`/users/${userId}/salary_history/`, { params });

// Download Salary Slip
export const downloadSalarySlip = (userId, params) => 
  api.get(`/users/${userId}/download_salary_slip/`, { 
    params, 
    responseType: 'blob' 
  });

// Set Fixed Salary (for Admin and Manager)
export const setFixedSalary = (userId, data) => 
  api.post(`/users/${userId}/set_fixed_salary/`, data);

// Notifications
export const listNotifications = () => api.get('/notifications/');
export const getNotification = (id) => api.get(`/notifications/${id}/`);
export const markNotificationAsRead = (id) => api.post(`/notifications/${id}/mark_as_read/`);


export const handleApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("Error data:", error.response.data);
    console.error("Error status:", error.response.status);
    console.error("Error headers:", error.response.headers);
    
    // You can handle specific error codes here
    if (error.response.status === 401) {
      // Unauthorized - you might want to redirect to login page
      // or refresh the token
    }
    
    return error.response.data.error;
  } else if (error.request) {
    // The request was made but no response was received
    console.error("No response received:", error.request);
    return { message: "No response received from server" };
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error message:", error.message);
    return { message: error.message };
  }
};


// Dashboard Statistics
export const getDashboardStats = (date) => api.get('/dashboard-stats/', { params: { date } });



export const listLeaveRequests = () => api.get('/leave-requests/');
export const getLeaveRequest = (id) => api.get(`/leave-requests/${id}/`);
export const approveLeaveRequest = (id, remarks) => api.post(`/leave-requests/${id}/approve/`, { admin_remarks: remarks });
export const rejectLeaveRequest = (id, remarks) => api.post(`/leave-requests/${id}/reject/`, { admin_remarks: remarks });
export const getLeaveHistory = () => api.get('/leave-history/');
export const getLeaveRequestHistory = () => api.get('/leave-request-history/');

// Password Reset
export const requestPasswordReset = (email) => 
  api.post('/auth/password/reset/', { 
    email,
    redirect_url: `${window.location.origin}/password-reset-confirm` 
  });

  export const confirmPasswordReset = (uid, token, new_password1, new_password2) => 
    api.post(`/auth/password/reset/confirm/${uid}/${token}/`, {
      new_password1,
      new_password2
    });

export const changePassword = (old_password, new_password1, new_password2) => 
  api.post('/auth/password/change/', { old_password, new_password1, new_password2 });

export const markTrainer = (userId, color) => {
  return api.post(`/users/${userId}/mark_trainer/`, { color });
};

export const unmarkTrainer = (userId) => {
  return api.post(`/users/${userId}/unmark_trainer/`);
};

export const clearAllMarks = () => {
  return api.post('/users/clear_all_marks/');
};
// In your api.js file
export const requestLeave = async (formData) => {
  return await api.post('/leave-requests/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const unassignStudentFromCourse = async (courseId) => {
  const response = await api.post(`/student-courses/${courseId}/unassign_student/`);
  return response.data;
};


export const createTrainerAudit = (auditData) => api.post('/trainer-audits/', auditData);
export const listTrainerAudits = (trainerId) => api.get('/trainer-audits/trainer_audits/', { params: { trainer_id: trainerId } });
export const getTrainerAudit = (id) => api.get(`/trainer-audits/${id}/`);
export const updateTrainerAudit = (id, auditData) => api.put(`/trainer-audits/${id}/`, auditData);
export const addTrainerRemarks = (id, remarks) => api.post(`/trainer-audits/${id}/add_trainer_remarks/`, { remarks });
export const getTrainerSuggestionsForExternalLead = (leadId) => {
  return api.get(`/external/external-leads/${leadId}/get_trainer_suggestions/`);
};



export const getExternalLead = (leadId) => {
  return api.get(`/external/external-leads/${leadId}/`);
};

export const updateExternalLead = (leadId, data) => {
  return api.put(`/external/external-leads/${leadId}/`, data);
};

export const createExternalLead = (data) => {
  return api.post('/external/external-leads/', data);
};

export const listExternalLeads = (params) => {
  return api.get('/external/external-leads/', { params });
};

export const deleteExternalLead = (leadId) => {
  return api.delete(`/external/external-leads/${leadId}/`);
};

export const syncBBBRecordings = () => {
  return api.post('/bbb-recordings/sync_recordings/');
};

export const getCoursesEndingSoon = (params) => {
  return api.get('/courses/ending_soon/', { params });
};


export const getCourseHoldHistory = async () => {
  return api.get('/course-holds/history/');
};

export const getCourseHoldHistory1 = async (studentId) => {
  return api.get('/course-holds/history/',{ params: { student_id: studentId } });
};

export const getStudentCourses1 = (studentId) => {
  return api.get('/student-courses/', { params: { student: studentId } });
};
// ================ Syllabus Content Management ================
export const listSyllabusContents = (params) => 
  api.get('/syllabus-contents/', { 
    params: {
      content_type: params?.content_type,
      created_by: params?.created_by,
      search: params?.search,
      ordering: params?.ordering
    }
  });

export const getSyllabusContent = (id) => 
  api.get(`/syllabus-contents/${id}/`);

export const createSyllabusContent = (contentData) => {
  const formData = new FormData();
  
  Object.keys(contentData).forEach(key => {
    if (key === 'questions' && contentData.content_type === 'QUIZ') {
      formData.append('questions', JSON.stringify(contentData.questions));
    } else if (contentData[key] instanceof File) {
      formData.append(key, contentData[key]);
    } else {
      formData.append(key, contentData[key]);
    }
  });

  return api.post('/syllabus-contents/', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const updateSyllabusContent = (id, contentData) => {
  const formData = new FormData();
  
  Object.keys(contentData).forEach(key => {
    if (key === 'questions' && contentData.content_type === 'QUIZ') {
      formData.append('questions', JSON.stringify(contentData.questions));
    } else if (contentData[key] instanceof File) {
      formData.append(key, contentData[key]);
    } else {
      formData.append(key, contentData[key]);
    }
  });

  return api.put(`/syllabus-contents/${id}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const deleteSyllabusContent = (id) =>
  api.delete(`/syllabus-contents/${id}/`);

// ================ Quiz Management ================


export const getQuizQuestion = (questionId) =>
  api.get(`/quiz-questions/${questionId}/`);

export const createQuizQuestion = async (contentId, questionData) => {
  const formData = new FormData();
  
  Object.keys(questionData).forEach(key => {
    if (key === 'options') {
      formData.append('options', JSON.stringify(questionData.options));
    } else if (questionData[key] instanceof File) {
      formData.append(key, questionData[key]);
    } else {
      formData.append(key, questionData[key]);
    }
  });

  return api.post(`/quiz-questions/`, {
    content: contentId,
    ...questionData
  });
};

// Update a quiz question

// Update quiz option
export const updateQuizOption = async (optionId, optionData) => {
  const formData = new FormData();
  
  Object.keys(optionData).forEach(key => {
    if (optionData[key] instanceof File) {
      formData.append(key, optionData[key]);
    } else {
      formData.append(key, optionData[key]);
    }
  });

  return api.put(`/quiz-options/${optionId}/`, formData);
};

export const deleteQuizOption = (optionId) =>
  api.delete(`/quiz-options/${optionId}/`);

// Add these functions to your api.js

export const createQuiz = async (quizContentData) => {
  try {
    // Create the initial quiz content
    const response = await api.post('/syllabus-contents/', {
      title: quizContentData.title,
      description: quizContentData.description,
      content_type: 'QUIZ',
      category_ids: quizContentData.category_ids || []
    });

    // Return the full response
    return response.data;
  } catch (error) {
    console.error('Error in createQuiz:', error);
    throw error;
  }
};

export const createBulkQuestions = async (quizId, questionsData) => {
  return await axios.post(`/api/syllabus-contents/${quizId}/bulk_create_quiz_questions/`, questionsData);
};

export const updateQuiz = async (quizId, quizData) => {
  const formData = new FormData();
  
  // Update basic quiz content
  formData.append('title', quizData.title);
  formData.append('description', quizData.description);
  formData.append('content_type', 'QUIZ');

  try {
    // Update the basic quiz content
    const quizResponse = await api.put(`/syllabus-contents/${quizId}/`, formData);

    // Update questions and their options
    if (quizData.questions && quizData.questions.length > 0) {
      const questionsData = {
        questions: quizData.questions.map((question, index) => ({
          question_text: question.question_text,
          explanation: question.explanation,
          question_video_url: question.question_video_url || '',
          question_image: question.question_image || null,
          order: index + 1,
          options: question.options.map((option, optIndex) => ({
            option_text: option.option_text,
            option_image: option.option_image || null,
            is_correct: Boolean(option.is_correct),  // Explicitly include is_correct
            order: optIndex + 1
          }))
        }))
      };

      console.log('Sending updated questions data:', JSON.stringify(questionsData, null, 2));

      await api.post(`/syllabus-contents/${quizId}/bulk_create_quiz_questions/`, questionsData);
    }

    return quizResponse.data;
  } catch (error) {
    console.error('Error updating quiz:', error);
    throw error;
  }
};

// ================ Syllabus Item Management ================
export const listSyllabusItems = (params) => 
  api.get('/syllabus-items/', { 
    params: {
      search: params?.search,
      created_by: params?.created_by,
      ordering: params?.ordering
    } 
  });

export const getSyllabusItem = (id) => 
  api.get(`/syllabus-items/${id}/`);

// In api.js - Update these functions
export const createSyllabusItem = (itemData) => {
  // First create the item with just title and description
  const initialData = {
    title: itemData.title,
    description: itemData.description
  };

  // Return a promise chain that first creates the item, then adds contents if any
  return api.post('/syllabus-items/', initialData)
    .then(response => {
      // If we have contents to add, make a second call to add them
      if (itemData.contents && itemData.contents.length > 0) {
        return api.post(`/syllabus-items/${response.data.id}/add_contents/`, {
          contents: itemData.contents
        }).then(() => response.data);
      }
      return response.data;
    });
};

export const updateSyllabusItem = (id, itemData) => {
  // First update the basic item data
  const updatePromise = api.put(`/syllabus-items/${id}/`, {
    title: itemData.title,
    description: itemData.description
  });

  // If there are contents, update them as well
  if (itemData.contents && itemData.contents.length > 0) {
    return updatePromise.then(() => {
      return api.post(`/syllabus-items/${id}/add_contents/`, {
        contents: itemData.contents
      });
    });
  }

  return updatePromise;
};

export const deleteSyllabusItem = (id) =>
  api.delete(`/syllabus-items/${id}/`);

export const addContentsToSyllabusItem = (itemId, contents) => {
  return api.post(`/syllabus-items/${itemId}/add_contents/`, {
    contents: contents.map((content, index) => ({
      content_id: content.id,
      order: index + 1
    }))
  });
};

export const reorderSyllabusContents = (itemId, contentOrders) =>
  api.post(`/syllabus-items/${itemId}/reorder_contents/`, {
    content_orders: contentOrders
  });
  export const bulkCreateQuizQuestions = async (contentId, questionsData) => {
    try {
      const formData = new FormData();
      
      // Structure the questions data correctly
      const questions = questionsData.map((question, qIndex) => {
        // Create the base question object
        const questionObj = {
          question_text: question.question_text.trim(),
          explanation: question.explanation?.trim() || '',
          question_video_url: question.question_video_url || '',
          order: qIndex + 1,
          options: question.options.map((option, oIndex) => ({
            option_text: option.option_text.trim(),
            is_correct: Boolean(option.is_correct),
            order: oIndex + 1
          }))
        };
  
        // Handle question image
        if (question.question_image instanceof File) {
          const questionImageKey = `question_${qIndex}_image`;
          formData.append(questionImageKey, question.question_image);
        }
  
        // Handle option images
        question.options.forEach((option, oIndex) => {
          if (option.option_image instanceof File) {
            const optionImageKey = `question_${qIndex}_option_${oIndex}_image`;
            formData.append(optionImageKey, option.option_image);
          }
        });
  
        return questionObj;
      });
  
      // Append the questions data as a JSON string
      formData.append('questions', JSON.stringify(questions));
  
      const response = await api.post(
        `/syllabus-contents/${contentId}/bulk_create_quiz_questions/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error('Error in bulkCreateQuizQuestions:', error);
      throw error;
    }
  };
// ================ Course Flow Management ================
export const listCourseFlows = (params) => 
  api.get('/course-flows/', { 
    params: {
      is_default: params?.is_default,
      created_by: params?.created_by,
      search: params?.search,
      ordering: params?.ordering
    }
  });

  export const uploadQuizMedia = async (file, type = 'quiz') => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    
    try {
      const response = await api.post('/syllabus-contents/upload-media/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

export const getDefaultCourseFlows = () =>
  api.get('/course-flows/', { params: { is_default: true } });

export const getCourseFlow = (id) => 
  api.get(`/course-flows/${id}/`);

// api.js
// api.js - Updated version matching your backend
export const createCourseFlow = async (flowData) => {
  // Log the incoming data
  console.log('Creating course flow with data:', flowData);

  const payload = {
    title: flowData.title,
    description: flowData.description,
    is_default: flowData.is_default,
    // Ensure items is always an array, even if undefined
    items: (flowData.items || []).map(item => ({
      syllabus_item: item.syllabus_item,
      order: item.order
    })),
    // Ensure category_ids is always an array, even if undefined
    category_ids: flowData.category_ids || []
  };

  // Log the outgoing payload
  console.log('Sending payload:', payload);

  return api.post('/course-flows/', payload);
};

export const updateCourseFlow = async (id, flowData) => {
  // Log the incoming data
  console.log('Updating course flow with data:', flowData);

  const payload = {
    title: flowData.title,
    description: flowData.description,
    is_default: flowData.is_default,
    items: flowData.items.map(item => ({
      syllabus_item: item.syllabus_item,
      order: item.order
    })),
    category_ids: flowData.category_ids || []
  };

  // Log the outgoing payload
  console.log('Sending payload:', payload);

  return api.put(`/course-flows/${id}/`, payload);
};

// In api.js
export const courseFlowAddItems = (flowId, items) => {
  return api.post(`/course-flows/${flowId}/assign_items/`, {
    items: items.map((item, index) => ({
      syllabus_item: item.id,  // Changed from item.syllabus_item to item.id
      order: index + 1
    }))
  });
};

export const deleteCourseFlow = (id) =>
  api.delete(`/course-flows/${id}/`);

export const assignCourseFlowToStudent = (flowId, studentId) =>
  api.post(`/course-flows/${flowId}/assign_to_student/`, {
    student_id: studentId
  });



// ================ Student Course Flow Management ================
export const listStudentCourseFlows = (params) => 
  api.get('/student-flows/', { 
    params: {
      is_active: params?.is_active,
      student: params?.student,
      base_course_flow: params?.base_course_flow,
      search: params?.search,
      ordering: params?.ordering
    }
  });

export const getStudentCourseFlow = (id) => 
  api.get(`/student-flows/${id}/`);

export const updateStudentCourseFlow = (id, flowData) =>
  api.put(`/student-flows/${id}/`, flowData);

export const customizeStudentFlow = (flowId, itemsToAdd, itemsToRemove) =>
  api.post(`/student-flows/${flowId}/customize_flow/`, {
    add_items: itemsToAdd,
    remove_items: itemsToRemove
  });

  export const markContentComplete = async (flowId, data) => {
    // Validate inputs
    console.log('markContentComplete called with:', { flowId, data });
    
    if (!flowId) {
      throw new Error('Flow ID is required');
    }
    
    if (!data.content_id) {
      throw new Error('Content ID is required');
    }
    
    if (!data.flow_item_id) {
      throw new Error('Flow item ID is required');
    }
  
    try {
      const response = await api.post(`/student-flows/${flowId}/mark_content_complete/`, {
        content_id: data.content_id,
        flow_item_id: data.flow_item_id
      });
      
      console.log('markContentComplete response:', response.data);
      return response;
    } catch (error) {
      console.error('markContentComplete error:', error.response?.data || error);
      throw error;
    }
  };

  export const getStudentCourseFlows = (studentId) => 
    api.get('/student-flows/', { params: { student: studentId } });

export const getStudentProgressReport = (flowId) =>
  api.get(`/student-flows/${flowId}/progress_report/`);

export const getMyProgress = () =>
  api.get('/student-flows/my_progress/');

// ================ Student Course Flow Items Management ================
export const getStudentCourseFlowItem = (itemId) =>
  api.get(`/student-flow-items/${itemId}/`);

export const updateStudentCourseFlowItem = (itemId, itemData) =>
  api.put(`/student-flow-items/${itemId}/`, itemData);

export const reorderStudentFlowItems = (flowId, itemOrders) =>
  api.post(`/student-flows/${flowId}/reorder_items/`, {
    item_orders: itemOrders
  });

// ================ Content Completion Management ================
export const listContentCompletions = (params) => 
  api.get('/content-completions/', { 
    params: {
      student: params?.student,
      student_flow_item: params?.student_flow_item,
      is_completed: params?.is_completed
    }
  });

export const getContentCompletion = (id) => 
  api.get(`/content-completions/${id}/`);

export const createContentCompletion = (completionData) =>
  api.post('/content-completions/', completionData);

export const updateContentCompletion = (id, completionData) =>
  api.put(`/content-completions/${id}/`, completionData);

export const toggleContentCompletion = (completionId) =>
  api.post(`/content-completions/${completionId}/toggle_completion/`);

// ================ Utility Functions ================
export const uploadFile = (file, path) => {
  const formData = new FormData();
  formData.append('file', file);
  
  return api.post(`/upload/${path}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};



// ================ Course Flow Analytics ================
export const getCourseFlowAnalytics = (flowId) =>
  api.get(`/course-flows/${flowId}/analytics/`);

export const getStudentFlowAnalytics = (flowId) =>
  api.get(`/student-flows/${flowId}/analytics/`);

// ================ Validation Functions ================
export const validateContentType = (contentType) => {
  const validTypes = ['VIDEO', 'TEXT', 'QUIZ'];
  return validTypes.includes(contentType);
};

export const validateQuizData = (quizData) => {
  // Validate quiz structure
  if (!quizData.questions || !Array.isArray(quizData.questions)) {
    throw new Error('Quiz must have questions array');
  }
  
  quizData.questions.forEach(question => {
    if (!question.options || !Array.isArray(question.options)) {
      throw new Error('Each question must have options array');
    }
    if (!question.options.some(option => option.is_correct)) {
      throw new Error('Each question must have at least one correct option');
    }
  });
  
  return true;
};

export const submitQuiz = (flowId, data) => 
  api.post(`/student-flows/${flowId}/submit_quiz/`, data);

export const getQuizAttempts = (flowId, contentId, flowItemId) =>
  api.get(`/student-flows/${flowId}/quiz_attempts/`, {
    params: { content_id: contentId, flow_item_id: flowItemId }
  });

  // In api.js, update the updateQuizQuestion function
export const updateQuizQuestion = async (contentId, questionData) => {
  try {
    if (!questionData.id) {
      throw new Error('Question ID is required for updates');
    }

    const formData = new FormData();
    
    // Add the question ID to the formData
    formData.append('question_id', questionData.id);
    
    // Add other question data
    formData.append('question_text', questionData.question_text.trim());
    formData.append('explanation', questionData.explanation?.trim() || '');
    formData.append('question_video_url', questionData.question_video_url || '');
    
    // Handle question image if it's a File
    if (questionData.question_image instanceof File) {
      formData.append('question_image', questionData.question_image);
    }
    
    // Handle options
    const optionsData = questionData.options.map((option) => ({
      id: option.id, // Include option ID if it exists
      option_text: option.option_text.trim(),
      is_correct: option.is_correct,
      order: option.order
    }));
    
    formData.append('options', JSON.stringify(optionsData));
    
    // Handle option images separately if they are Files
    questionData.options.forEach((option, index) => {
      if (option.option_image instanceof File) {
        formData.append(`option_${index}_image`, option.option_image);
      }
    });
    
    const response = await api.put(
      `/syllabus-contents/${contentId}/update_quiz_question/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error updating quiz question:', error);
    throw error;
  }
};
  // Delete a specific question from a quiz
  export const deleteQuizQuestion = async (contentId, questionId) => {
    try {
      const response = await api.delete(`/syllabus-contents/${contentId}/delete_quiz_question/`, {
        data: { question_id: questionId }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting quiz question:', error);
      throw error;
    }
  };

  export const uploadFile1 = async (file, path = 'quiz-images') => {
    const formData = new FormData();
    formData.append('file', file);
    
    try {
      const response = await api.post(`/upload/${path}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  // ================ Competition Management ================
export const listCompetitions = (params = {}) => 
  api.get('/competitions/', { 
    params: {
      page: params.page,
      page_size: params.page_size,
      is_active: params.is_active,
      search: params.search
    }
  });

export const createCompetitionWithQuestions = async (competitionData) => {
    try {
      const response = await api.post('/competitions/', competitionData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response;
    } catch (error) {
      console.error('Error creating competition:', error);
      throw error;
    }
  };
export const getCompetition = (id) => 
  api.get(`/competitions/${id}/`);

export const createCompetition = async (formData) => {
  try {
    const response = await api.post(`${API_URL}/competitions/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.error('Error creating competition:', error.response?.data || error);
    throw error;
  }
};

export const updateCompetition = (id, competitionData) => {
  const formData = new FormData();
  Object.keys(competitionData).forEach(key => {
    formData.append(key, competitionData[key]);
  });

  return api.put(`/competitions/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};



// ================ Competition Questions Management ================
export const bulkCreateCompetitionQuestions = async (competitionData) => {
  try {
    // Create FormData if there are any files to upload
    const formData = new FormData();
    
    // Add basic competition data
    formData.append('title', competitionData.title);
    formData.append('description', competitionData.description);
    formData.append('time_limit', competitionData.time_limit);
    formData.append('start_date', competitionData.start_date);
    formData.append('expiry_date', competitionData.expiry_date);

    // Handle questions and their images
    const questionsForJson = competitionData.questions.map(question => {
      const questionCopy = {...question};
      if (question.image instanceof File) {
        // If there's an image file, append it to FormData with a unique key
        formData.append(`question_images_${question.order}`, question.image);
        // Remove the actual file from the JSON data
        delete questionCopy.image;
      }
      return questionCopy;
    });

    // Append the questions data as JSON
    formData.append('questions', JSON.stringify(questionsForJson));

    const response = await api.post('/competitions/bulk_create/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    return response;
  } catch (error) {
    console.error('Error creating competition with questions:', error.response?.data || error);
    throw error;
  }
};

// ================ Competition Attempt Management ================
export const startCompetitionAttempt = (competitionId) =>
  api.post(`/competitions/${competitionId}/start_attempt/`);

export const submitCompetitionAnswers = (competitionId, answers) =>
  api.post(`/competitions/${competitionId}/submit_answers/`, {
    answers: answers.map(answer => ({
      question_id: answer.question_id,
      selected_option_id: answer.selected_option_id
    }))
  });

// ================ Competition Leaderboard Management ================
export const getCompetitionLeaderboard = (competitionId, params = {}) => {
  const queryString = new URLSearchParams({
    page: params.page || 1,
    page_size: params.page_size || 20
  }).toString();
  
  return api.get(`/competitions/${competitionId}/leaderboard/?${queryString}`);
};

export const getCourseHoldsEndingSoon = () => 
  api.get('/course-holds/ending_soon/');

export const getHistoricalLeaderboards = () =>
  api.get('/competitions/historical_leaderboards/');

// ================ Competition Progress ================
export const getMyCompetitionProgress = () =>
  api.get('/competitions/my_progress/');

export const getCompetitionAttempts = (competitionId) =>
  api.get(`/competitions/${competitionId}/attempts/`);

// ================ Error Handling Helper ================
export const handleCompetitionError = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    
    switch (status) {
      case 400:
        return {
          type: 'validation',
          message: data.error || 'Invalid competition data'
        };
      case 401:
        return {
          type: 'auth',
          message: 'Please login to continue'
        };
      case 403:
        return {
          type: 'permission',
          message: 'You do not have permission to perform this action'
        };
      case 404:
        return {
          type: 'not_found',
          message: 'Competition not found'
        };
      default:
        return {
          type: 'server',
          message: 'Server error occurred'
        };
    }
  }
  
  return {
    type: 'network',
    message: 'Network error occurred'
  };
};

// ================ Utility Functions ================
export const isCompetitionExpired = (expiryDate) => {
  return new Date(expiryDate) < new Date();
};

export const calculateTimeRemaining = (expiryDate) => {
  const now = new Date();
  const expiry = new Date(expiryDate);
  const diff = expiry - now;
  
  if (diff <= 0) return { expired: true };
  
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  
  return {
    expired: false,
    days,
    hours,
    minutes
  };
};

export const formatCompetitionDuration = (minutes) => {
  if (minutes < 60) {
    return `${minutes} minutes`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} hour${hours > 1 ? 's' : ''}${remainingMinutes ? ` ${remainingMinutes} minutes` : ''}`;
};



export const updateCompetitionQuestion = async (competitionId, questionId, formData) => {
  try {
    const response = await api.put(
      `/competitions/${competitionId}/questions/${questionId}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error updating competition question:', error.response?.data || error);
    throw error;
  }
};
export const deleteCompetitionQuestion = async (competitionId, questionId) => {
  try {
    const response = await api.delete(`/competitions/${competitionId}/questions/${questionId}/`);
    return response;
  } catch (error) {
    console.error('Error deleting competition question:', error);
    throw error;
  }
};
export const getCompetitionQuestion = async (competitionId, questionId) => {
  try {
    const response = await api.get(`/competitions/${competitionId}/questions/${questionId}/`);
    return response;
  } catch (error) {
    console.error('Error fetching competition question:', error);
    throw error;
  }
};

export const updateQuestionOption = async (competitionId, questionId, optionId, optionData) => {
  try {
    const formData = new FormData();
    
    Object.keys(optionData).forEach(key => {
      if (optionData[key] instanceof File) {
        formData.append(key, optionData[key]);
      } else {
        formData.append(key, optionData[key]);
      }
    });

    const response = await api.put(
      `/competitions/${competitionId}/questions/${questionId}/options/${optionId}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error updating competition question option:', error);
    throw error;
  }
};

// Competition Question Options Management
export const updateCompetitionQuestionOption = async (competitionId, questionId, optionId, optionData) => {
  try {
    const formData = new FormData();
    
    Object.keys(optionData).forEach(key => {
      if (optionData[key] instanceof File) {
        formData.append(key, optionData[key]);
      } else {
        formData.append(key, optionData[key]);
      }
    });

    const response = await api.put(
      `/competitions/${competitionId}/questions/${questionId}/options/${optionId}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error updating competition question option:', error);
    throw error;
  }
};

export const deleteCompetitionQuestionOption = async (competitionId, questionId, optionId) => {
  try {
    const response = await api.delete(
      `/competitions/${competitionId}/questions/${questionId}/options/${optionId}/`
    );
    return response;
  } catch (error) {
    console.error('Error deleting competition question option:', error);
    throw error;
  }
};

export const addCompetitionQuestionOption = async (competitionId, questionId, optionData) => {
  try {
    const formData = new FormData();
    
    Object.keys(optionData).forEach(key => {
      if (optionData[key] instanceof File) {
        formData.append(key, optionData[key]);
      } else {
        formData.append(key, optionData[key]);
      }
    });

    const response = await api.post(
      `/competitions/${competitionId}/questions/${questionId}/options/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error adding competition question option:', error);
    throw error;
  }
};
export const deleteCompetition = (id) =>
  api.delete(`/competitions/${id}/`);
// Reorder competition questions
export const reorderCompetitionQuestions = async (competitionId, questionOrders) => {
  try {
    const response = await api.post(`/competitions/${competitionId}/reorder_questions/`, {
      question_orders: questionOrders // Array of { question_id: number, order: number }
    });
    return response;
  } catch (error) {
    console.error('Error reordering competition questions:', error);
    throw error;
  }
};

// Reorder question options
export const reorderQuestionOptions = async (competitionId, questionId, optionOrders) => {
  try {
    const response = await api.post(
      `/competitions/${competitionId}/questions/${questionId}/reorder_options/`,
      {
        option_orders: optionOrders // Array of { option_id: number, order: number }
      }
    );
    return response;
  } catch (error) {
    console.error('Error reordering question options:', error);
    throw error;
  }
};

export const toggleCompetitionStatus = (competitionId) =>
  api.post(`/competitions/${competitionId}/toggle_status/`);

export const extendCompetition = (competitionId, newExpiryDate) =>
  api.post(`/competitions/${competitionId}/extend/`, {
    expiry_date: newExpiryDate
  });


  export const copyQuestionsFromCompetition = (targetCompetitionId, sourceCompetitionId) =>
    api.post(`/competitions/${targetCompetitionId}/copy_questions/`, {
      source_competition_id: sourceCompetitionId
    });
  
  export const importQuestionsFromFile = (competitionId, fileData) => {
    const formData = new FormData();
    formData.append('file', fileData);
    return api.post(`/competitions/${competitionId}/import_questions/`, formData);
  };

  // Need to add
export const getCompetitionStats = (competitionId) =>
  api.get(`/competitions/${competitionId}/statistics/`);

export const getParticipantResults = (competitionId, participantId) =>
  api.get(`/competitions/${competitionId}/participant_results/${participantId}/`);

export const exportCompetitionResults = (competitionId, format = 'csv') =>
  api.get(`/competitions/${competitionId}/export_results/`, {
    params: { format },
    responseType: 'blob'
  });


  // Need to add
export const uploadQuestionMedia = (competitionId, questionId, mediaFile) => {
  const formData = new FormData();
  formData.append('media', mediaFile);
  return api.post(
    `/competitions/${competitionId}/questions/${questionId}/upload_media/`,
    formData
  );
};

export const deleteQuestionMedia = (competitionId, questionId, mediaId) =>
  api.delete(
    `/competitions/${competitionId}/questions/${questionId}/media/${mediaId}/`
  );


  export const uploadFile3 = async (formData, type = 'competition-images') => {
    try {
      const response = await api.post(`/upload/${type}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  export const listCategories = (params = {}) => {
    // Normalize the params object
    const normalizedParams = {};
    
    if (params.search) normalizedParams.search = params.search;
    if (params.created_by) normalizedParams.created_by = params.created_by;
    if (params.ordering) normalizedParams.ordering = params.ordering;
  
    // Make the request with normalized params
    return api.get('/categories/', { params: normalizedParams });
  };
  
  export const getCategory = (id) => 
    api.get(`/categories/${id}/`);
  
  export const createCategory = (categoryData) => {
    const formData = new FormData();
    
    Object.keys(categoryData).forEach(key => {
      if (categoryData[key] instanceof File) {
        formData.append(key, categoryData[key]);
      } else {
        formData.append(key, categoryData[key]);
      }
    });
  
    return api.post('/categories/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
  
  export const updateCategory = (id, categoryData) => {
    const formData = new FormData();
    
    Object.keys(categoryData).forEach(key => {
      if (categoryData[key] instanceof File) {
        formData.append(key, categoryData[key]);
      } else {
        formData.append(key, categoryData[key]);
      }
    });
  
    return api.put(`/categories/${id}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
  
  export const deleteCategory = (id) =>
    api.delete(`/categories/${id}/`);

  export const getCurrentSpecialRate = () => api.get('/trainer-special-rates/current_rate/');

// Create or update special rate
export const updateSpecialRate = (rate) => 
  api.post('/trainer-special-rates/', { rate_for_30_min: rate });


// Add to api.js

// Get trainers who are getting free soon
export const getFreingTrainers = (days = 5) => 
  api.get('/trainer-transitions/freeing_trainers/', { params: { days } });

// Get trainer transition notifications
export const getTrainerTransitionNotifications = () =>
  api.get('/trainer-transitions/transition_notifications/');

// Initialize transition notifications polling
let transitionPollingInterval = null;

export const startTransitionPolling = (callback) => {
  // Clear existing interval if any
  if (transitionPollingInterval) {
    clearInterval(transitionPollingInterval);
  }

  // Poll every 5 minutes
  const pollTransitions = async () => {
    try {
      const response = await getTrainerTransitionNotifications();
      callback(response.data);
    } catch (error) {
      console.error('Error polling trainer transitions:', error);
    }
  };

  // Initial poll
  pollTransitions();
  
  // Set up interval
  transitionPollingInterval = setInterval(pollTransitions, 300000); // 5 minutes
  
  return () => {
    if (transitionPollingInterval) {
      clearInterval(transitionPollingInterval);
    }
  };
};

export const stopTransitionPolling = () => {
  if (transitionPollingInterval) {
    clearInterval(transitionPollingInterval);
  }
};