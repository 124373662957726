import { parseISO, addMinutes, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const IST_FORMAT = "d-MMM-yyyy hh:mm aa";
const IST_TIMEZONE = 'Asia/Kolkata';
const IST_OFFSET = 330; // 5 hours and 30 minutes in minutes

/**
 * Converts a UTC date string to IST and formats it.
 * @param {string} utcDateString - Date string in UTC format (e.g., "2024-09-12 12:28:36")
 * @returns {string} Formatted date string in IST (e.g., "12-Sep-2024 05:58 PM")
 */
export const utcToISTString = (utcDateString) => {
  const utcDate = parseISO(utcDateString);
  const istDate = addMinutes(utcDate, IST_OFFSET);
  return format(istDate, IST_FORMAT);
};

/**
 * Converts an IST date string to UTC and formats it.
 * @param {string} istDateString - Date string in IST format (e.g., "12-Sep-2024 05:58 PM")
 * @returns {string} Formatted date string in UTC (e.g., "2024-09-12 12:28:36")
 */
export const istToUTCString = (istDateString) => {
  const istDate = parseISO(istDateString);
  const utcDate = addMinutes(istDate, -IST_OFFSET);
  return format(utcDate, "yyyy-MM-dd HH:mm:ss");
};

/**
 * Formats a Date object to IST string format.
 * @param {Date} date - Date object
 * @returns {string} Formatted date string in IST (e.g., "12-Sep-2024 05:58 PM")
 */
export const formatToISTString = (date) => {
  return formatInTimeZone(date, IST_TIMEZONE, IST_FORMAT);
};

/**
 * Gets the current date and time in IST format.
 * @returns {string} Current date and time in IST format (e.g., "12-Sep-2024 05:58 PM")
 */
export const getCurrentISTString = () => {
  const now = new Date();
  const istDate = addMinutes(now, IST_OFFSET);
  return format(istDate, IST_FORMAT);
};

/**
 * Gets the current date and time as a Date object in IST.
 * @returns {Date} Current date and time as a Date object in IST
 */
export const getCurrentISTDate = () => {
  const now = new Date();
  return addMinutes(now, IST_OFFSET);
};

/**
 * Calculates the time difference between two dates in days.
 * @param {Date|string} date1 - First date (or date string)
 * @param {Date|string} date2 - Second date (or date string)
 * @returns {number} Number of days between the two dates
 */
export const getDaysBetweenDates = (date1, date2) => {
  const d1 = date1 instanceof Date ? date1 : parseISO(date1);
  const d2 = date2 instanceof Date ? date2 : parseISO(date2);
  const diffTime = Math.abs(d2 - d1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const formatDate = (dateString) => {
  if (!dateString) return '';
  try {
    const date = parseISO(dateString);
    return format(date, 'dd-MMM-yyyy');
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};