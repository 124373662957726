import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Typography,
  Box,
  Chip,
  IconButton,
  Autocomplete,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { listUsers } from '../api';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';

function AssignTrainersModal({ open, onClose, course, onAssign }) {
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trainers, setTrainers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (open && course) {
      const initialAssignments = course.trainer_assignments.map(assignment => ({
        id: assignment.id,
        trainer: {
          id: assignment.trainer_id,
          full_name: assignment.trainer_name
        },
        start_time: dayjs(assignment.start_time, 'HH:mm:ss'),
        end_time: dayjs(assignment.end_time, 'HH:mm:ss')
      }));
      setAssignments(initialAssignments);
      setTrainers(course.trainers);
    }
  }, [open, course]);

  const fetchTrainers = useCallback(async (search = '', page = 1) => {
    setIsSearching(true);
    try {
      const response = await listUsers({ roles: ['TRAINER'], search: search, page: page, page_size: 10 });
      console.log('Trainers response:', response);
      if (page === 1) {
        setTrainers(response.results || []);
      } else {
        setTrainers(prevTrainers => [...prevTrainers, ...(response.results || [])]);
      }
      setHasMore(response.next !== null);
      setPage(page);
    } catch (error) {
      console.error('Error fetching trainers:', error);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const debouncedFetchTrainers = useCallback(
    debounce((searchTerm) => {
      setPage(1);
      fetchTrainers(searchTerm, 1);
    }, 300),
    [fetchTrainers]
  );

  const handleSearchChange = (event, newValue) => {
    setSearchTerm(newValue);
    if (newValue.length >= 2) {
      debouncedFetchTrainers(newValue);
    } else if (newValue.length === 0) {
      setPage(1);
      fetchTrainers('', 1);
    }
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight &&
      hasMore &&
      !isSearching
    ) {
      fetchTrainers(searchTerm, page + 1);
    }
  };

  const handleAddAssignment = () => {
    setAssignments([...assignments, { trainer: null, start_time: null, end_time: null }]);
  };

  const handleAssignmentChange = (index, field, value) => {
    const newAssignments = [...assignments];
    newAssignments[index][field] = value;
    setAssignments(newAssignments);
  };

  const handleRemoveAssignment = (index) => {
    setAssignments(assignments.filter((_, i) => i !== index));
  };

  const formatTime = (time) => {
    if (!time) return null;
    return dayjs(time).format('HH:mm:ss');
  };

  const handleSubmit = () => {
    const validAssignments = assignments.filter(
      assignment => assignment.trainer && assignment.start_time && assignment.end_time
    );

    if (validAssignments.length === 0) {
      alert('Please ensure all assignments have a trainer and both start and end times selected.');
      return;
    }

    const formattedAssignments = validAssignments.map(assignment => ({
      trainer_id: assignment.trainer.id,
      start_time: formatTime(assignment.start_time),
      end_time: formatTime(assignment.end_time)
    }));

    console.log('Sending assignments:', formattedAssignments);
    onAssign(formattedAssignments);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Assign Trainers to {course?.name}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="h6">Current Assignments</Typography>
          {assignments.map((assignment, index) => (
            <Box key={index} display="flex" alignItems="center" mb={1}>
              <Chip label={assignment.trainer ? assignment.trainer.full_name : 'No trainer selected'} />
              <Typography variant="body2" mx={1}>
                {formatTime(assignment.start_time)} - {formatTime(assignment.end_time)}
              </Typography>
              <IconButton size="small" onClick={() => handleRemoveAssignment(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
        {assignments.map((assignment, index) => (
          <Grid container spacing={2} key={index} style={{ marginBottom: '1rem' }}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={trainers}
                getOptionLabel={(option) => option.full_name || option.username}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trainer"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                value={assignment.trainer}
                onChange={(event, newValue) => {
                  handleAssignmentChange(index, 'trainer', newValue);
                }}
                onInputChange={handleSearchChange}
                loading={isSearching}
                fullWidth
                ListboxProps={{
                  onScroll: handleScroll,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TimePicker
                label="Start Time"
                value={assignment.start_time}
                onChange={(newValue) => handleAssignmentChange(index, 'start_time', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TimePicker
                label="End Time"
                value={assignment.end_time}
                onChange={(newValue) => handleAssignmentChange(index, 'end_time', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <IconButton onClick={() => handleRemoveAssignment(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button onClick={handleAddAssignment}>Add Assignment</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Assign Trainers
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssignTrainersModal;