import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Grid,
  Alert,
  CircularProgress,
  DialogContentText,
  Snackbar,
  Autocomplete,
  Chip,
  styled,
  InputAdornment,
  Tooltip,
  Menu,
  MenuItem, Select
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Image as ImageIcon,
  VideoLibrary as VideoIcon,
  Save as SaveIcon,
  Category as CategoryIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  Sort as SortIcon,
  Quiz as QuizIcon
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  updateQuizQuestion,
  deleteQuizQuestion,
  createQuiz,
  uploadFile,bulkCreateQuizQuestions, uploadFile3, uploadQuizMedia
  // Add other API functions you need
} from '../../api';

// Styled Components
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)'
  }
}));

const GlassAccordion = styled(Accordion)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '8px !important',
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  }
}));

const GlassChip = styled(Chip)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.common.white,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
}));

const initializeNewQuestion = () => ({
  question_text: '',
  explanation: '',
  question_image: null,
  question_video_url: '',
  options: [
    { option_text: '', is_correct: false, option_image: null, order: 1 },
    { option_text: '', is_correct: false, option_image: null, order: 2 }
  ]
  // Note: No 'id' field for new questions
});


const QuizManagement = ({ 
  contents, 
  categories,
  onQuizCreate, 
  onContentUpdate, 
  onContentDelete 
}) => {
  // State Management
  const [openDialog, setOpenDialog] = useState(false);
  const [editingQuiz, setEditingQuiz] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortBy, setSortBy] = useState('title');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category_ids: [],
    questions: [initializeNewQuestion()]
  });

  // Filtered Quizzes
  const filteredQuizzes = useMemo(() => {
    let filtered = contents.filter(quiz => {
      const matchesSearch = 
        quiz.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quiz.description.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesCategories = 
        selectedCategories.length === 0 ||
        selectedCategories.every(catId => 
          quiz.categories?.some(cat => cat.id === catId)
        );

      return matchesSearch && matchesCategories;
    });

    return filtered.sort((a, b) => {
      let comparison = 0;
      switch (sortBy) {
        case 'title':
          comparison = a.title.localeCompare(b.title);
          break;
        case 'questions':
          comparison = (a.questions?.length || 0) - (b.questions?.length || 0);
          break;
        case 'created_at':
          comparison = new Date(a.created_at) - new Date(b.created_at);
          break;
        default:
          comparison = 0;
      }
      return sortOrder === 'asc' ? comparison : -comparison;
    });
  }, [contents, searchTerm, selectedCategories, sortBy, sortOrder]);

  // Reset Form
  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      category_ids: [],
      questions: [initializeNewQuestion()]
    });
    setEditingQuiz(null);
    setError(null);
  };

  // Dialog Handlers
  const handleOpen = () => {
    resetForm();
    setOpenDialog(true);
  };

  const handleClose = () => {
    resetForm();
    setOpenDialog(false);
  };

  // Edit Handler
  const handleEdit = (quiz) => {
    setEditingQuiz(quiz);
    setFormData({
      title: quiz.title,
      description: quiz.description,
      category_ids: quiz.categories?.map(cat => cat.id) || [],
      questions: quiz.questions?.map(q => ({
        ...q,
        id: q.id, // Ensure question ID is preserved
        question_image: q.question_image || null,
        options: q.options.map(o => ({
          ...o,
          id: o.id, // Ensure option ID is preserved
          option_image: o.option_image || null,
          is_correct: Boolean(o.is_correct)
        }))
      })) || [initializeNewQuestion()]
    });
    setOpenDialog(true);
  };

  // Question Management Handlers
  const handleQuestionChange = async (index, field, value) => {
    const updatedQuestions = formData.questions.map((q, i) => 
      i === index ? { ...q, [field]: value } : q
    );
    
    setFormData(prev => ({
      ...prev,
      questions: updatedQuestions
    }));
  
    // Update on backend if editing existing quiz and question has an ID
    if (editingQuiz && formData.questions[index].id) {
      try {
        const question = formData.questions[index];
        setLoading(true);
        await updateQuizQuestion(editingQuiz.id, {
          ...question,
          [field]: value,
          id: question.id  // Ensure we're passing the question ID
        });
        setSuccessMessage('Question updated successfully');
      } catch (error) {
        console.error('Error updating question:', error);
        setError('Failed to update question: ' + (error.response?.data?.error || error.message));
      } finally {
        setLoading(false);
      }
    }
  };

  // Option Management Handlers
  const handleOptionChange = async (questionIndex, optionIndex, field, value) => {
    const updatedQuestions = formData.questions.map((q, qIndex) => {
      if (qIndex !== questionIndex) return q;
      return {
        ...q,
        options: q.options.map((opt, oIndex) => {
          if (oIndex !== optionIndex) {
            return field === 'is_correct' && value === true ? 
              { ...opt, is_correct: false } : 
              opt;
          }
          return { ...opt, [field]: value };
        })
      };
    });

    setFormData(prev => ({
      ...prev,
      questions: updatedQuestions
    }));

    // Update on backend if editing existing quiz
    if (editingQuiz) {
      try {
        const question = formData.questions[questionIndex];
        if (question.id) {
          setLoading(true);
          await updateQuizQuestion(editingQuiz.id, question.id, {
            ...question,
            options: updatedQuestions[questionIndex].options
          });
          setSuccessMessage('Options updated successfully');
        }
      } catch (error) {
        setError('Failed to update options');
      } finally {
        setLoading(false);
      }
    }
  };

  // Add/Remove Question Handlers
  const handleAddQuestion = () => {
    setFormData(prev => ({
      ...prev,
      questions: [...prev.questions, initializeNewQuestion()]
    }));
  };

  const handleDeleteQuestion = async (index) => {
    if (formData.questions.length <= 1) {
      setError('Quiz must have at least one question');
      return;
    }
  
    try {
      const questionToDelete = formData.questions[index];
      
      // If editing existing quiz and question has an ID, delete from backend
      if (editingQuiz && questionToDelete.id) {
        await deleteQuizQuestion(editingQuiz.id, questionToDelete.id);
      }
  
      setFormData(prev => ({
        ...prev,
        questions: prev.questions.filter((_, i) => i !== index)
      }));
  
      setSuccessMessage('Question deleted successfully');
    } catch (error) {
      console.error('Error deleting question:', error);
      setError('Failed to delete question');
    }
  };

  // Add/Remove Option Handlers
  const handleAddOption = (questionIndex) => {
    setFormData(prev => ({
      ...prev,
      questions: prev.questions.map((q, i) => {
        if (i !== questionIndex) return q;
        return {
          ...q,
          options: [
            ...q.options,
            {
              option_text: '',
              is_correct: false,
              option_image: null,
              order: q.options.length + 1
              // Note: No 'id' field for new options
            }
          ]
        };
      })
    }));
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const question = formData.questions[questionIndex];
    if (question.options.length <= 2) {
      setError('Question must have at least two options');
      return;
    }

    setFormData(prev => ({
      ...prev,
      questions: prev.questions.map((q, i) => {
        if (i !== questionIndex) return q;
        return {
          ...q,
          options: q.options.filter((_, j) => j !== optionIndex)
        };
      })
    }));
  };

  // File Upload Handler
  const handleFileUpload = (questionIndex, field, file, optionIndex = null) => {
    try {
      if (optionIndex !== null) {
        // Handle option image
        setFormData(prev => ({
          ...prev,
          questions: prev.questions.map((q, qIdx) => 
            qIdx === questionIndex
              ? {
                  ...q,
                  options: q.options.map((opt, optIdx) =>
                    optIdx === optionIndex
                      ? { ...opt, option_image: file }  // Store the file directly
                      : opt
                  )
                }
              : q
          )
        }));
      } else {
        // Handle question image
        setFormData(prev => ({
          ...prev,
          questions: prev.questions.map((q, qIdx) =>
            qIdx === questionIndex
              ? { ...q, [field]: file }  // Store the file directly
              : q
          )
        }));
      }
      
      setSuccessMessage('File selected successfully');
    } catch (error) {
      console.error('Error handling file:', error);
      setError('Failed to handle file');
    }
  };
  // Form Validation
  const validateForm = () => {
    if (!formData.title.trim()) {
      setError('Quiz title is required');
      return false;
    }

    if (formData.questions.length === 0) {
      setError('Quiz must have at least one question');
      return false;
    }

    for (const [index, question] of formData.questions.entries()) {
      if (!question.question_text.trim()) {
        setError(`Question ${index + 1} text is required`);
        return false;
      }

      if (question.options.length < 2) {
        setError(`Question ${index + 1} must have at least two options`);
        return false;
      }

      if (!question.options.some(opt => opt.is_correct)) {
        setError(`Question ${index + 1} must have at least one correct answer`);
        return false;
      }

      for (const [optIndex, option] of question.options.entries()) {
        if (!option.option_text.trim()) {
          setError(`Option ${optIndex + 1} in Question ${index + 1} text is required`);
          return false;
        }
      }
    }

    return true;
  };

  const ImagePreview = ({ src, onRemove }) => {
    if (!src) return null;
  
    const imageUrl = src instanceof File ? URL.createObjectURL(src) : src;
  
    return (
      <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
        <img 
          src={imageUrl}
          alt="Preview" 
          style={{ maxWidth: '100px', maxHeight: '60px', objectFit: 'contain' }}
        />
        <IconButton
          size="small"
          onClick={onRemove}
          sx={{ color: 'error.main' }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  };
  // Form Submission
  // In QuizManagement.jsx
  // In QuizManagement.jsx

// 1. Update the handleSubmit function to properly handle updates
const handleSubmit = async () => {
  try {
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError(null);

    // Prepare quiz content data
    const quizContentData = {
      title: formData.title.trim(),
      description: formData.description.trim(),
      content_type: 'QUIZ',
      category_ids: formData.category_ids || [],
    };

    let quizId;
    if (editingQuiz) {
      // Update existing quiz
      await onContentUpdate(editingQuiz.id, quizContentData);
      quizId = editingQuiz.id;
    } else {
      // Create new quiz
      const createdQuiz = await onQuizCreate(quizContentData);
      if (!createdQuiz || !createdQuiz.id) {
        throw new Error('Failed to get quiz ID after creation');
      }
      quizId = createdQuiz.id;
    }

    // Verify we have a valid quizId
    if (!quizId) {
      throw new Error('Quiz ID is undefined');
    }

    // Handle questions
    if (formData.questions && formData.questions.length > 0) {
      // Separate existing and new questions
      const existingQuestions = formData.questions.filter(q => q.id);
      const newQuestions = formData.questions.filter(q => !q.id);

      // Update existing questions
      for (const question of existingQuestions) {
        const questionData = {
          id: question.id, // Ensure we include the question ID
          question_text: question.question_text.trim(),
          explanation: question.explanation?.trim() || '',
          question_video_url: question.question_video_url || '',
          question_image: question.question_image instanceof File ? question.question_image : question.question_image,
          options: question.options.map(option => ({
            id: option.id, // Include option ID if it exists
            option_text: option.option_text.trim(),
            is_correct: Boolean(option.is_correct),
            option_image: option.option_image instanceof File ? option.option_image : option.option_image,
            order: option.order
          }))
        };

        await updateQuizQuestion(quizId, questionData);
      }
      // Add new questions only if there are any
      if (newQuestions.length > 0) {
        const newQuestionsData = newQuestions.map((question, index) => ({
          question_text: question.question_text.trim(),
          explanation: question.explanation?.trim() || '',
          question_video_url: question.question_video_url || '',
          question_image: question.question_image instanceof File ? question.question_image : null,
          order: existingQuestions.length + index + 1,
          options: question.options.map((option, optIndex) => ({
            option_text: option.option_text.trim(),
            is_correct: Boolean(option.is_correct),
            option_image: option.option_image instanceof File ? option.option_image : null,
            order: optIndex + 1
          }))
        }));

        await bulkCreateQuizQuestions(quizId, newQuestionsData);
      }
    }

    setSuccessMessage(editingQuiz ? 'Quiz updated successfully' : 'Quiz created successfully');
    handleClose();
  } catch (error) {
    console.error('Error saving quiz:', error);
    const errorMessage = error.response?.data?.error || error.message || 'Failed to save quiz';
    setError(errorMessage);
  } finally {
    setLoading(false);
  }
};
  // Delete Handler
  const handleDelete = async (quiz) => {
    setItemToDelete(quiz);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      await onContentDelete(itemToDelete.id);
      setSuccessMessage('Quiz deleted successfully');
      setDeleteConfirmOpen(false);
      setItemToDelete(null);
    } catch (error) {
      setError('Failed to delete quiz');
    } finally {
      setLoading(false);
    }
  };

  // Filter and Sort Handlers
  const handleFilterClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterMenuAnchor(null);
  };

  const toggleSortOrder = () => {
    setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  // Category Display Component
  // Continue CategoryDisplay component
  const CategoryDisplay = ({ categories }) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {categories?.map((category) => (
        <GlassChip
          key={category.id}
          label={category.name}
          size="small"
          icon={<CategoryIcon sx={{ fontSize: 16 }} />}
          variant="outlined"
        />
      ))}
    </Box>
  );

  return (
    <Box>
      {/* Header and Controls */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography variant="h6" sx={{ 
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <QuizIcon color="primary" />
          Quiz Management
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            startIcon={<FilterIcon />}
            onClick={handleFilterClick}
          >
            Filter & Sort
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Create Quiz
          </Button>
        </Box>
      </Box>

      {/* Search and Filters */}
      <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          placeholder="Search quizzes..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />

        <Autocomplete
          multiple
          options={categories}
          getOptionLabel={(option) => option.name}
          value={categories.filter(cat => selectedCategories.includes(cat.id))}
          onChange={(_, newValue) => {
            setSelectedCategories(newValue.map(cat => cat.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Categories"
              placeholder="Select categories"
            />
          )}
          sx={{ minWidth: 300 }}
        />
      </Box>

      {/* Alerts */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {/* Quiz Grid */}
      <Grid container spacing={2}>
        {filteredQuizzes.map((quiz) => (
          <Grid item xs={12} md={6} key={quiz.id}>
            <GlassCard>
              <CardContent>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6">{quiz.title}</Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {quiz.description}
                  </Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Questions: {quiz.questions?.length || 0}
                  </Typography>
                  {quiz.categories?.length > 0 && (
                    <Box sx={{ mt: 1 }}>
                      <CategoryDisplay categories={quiz.categories} />
                    </Box>
                  )}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                  <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => handleEdit(quiz)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(quiz)}
                  >
                    Delete
                  </Button>
                </Box>
              </CardContent>
            </GlassCard>
          </Grid>
        ))}
      </Grid>

      {/* Filter Menu */}
      <Menu
        anchorEl={filterMenuAnchor}
        open={Boolean(filterMenuAnchor)}
        onClose={handleFilterClose}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white',
            minWidth: 200
          }
        }}
      >
        <MenuItem>
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle2" gutterBottom>
              Sort By
            </Typography>
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              fullWidth
              size="small"
              sx={{ color: 'white' }}
            >
              <MenuItem value="title">Title</MenuItem>
              <MenuItem value="questions">Number of Questions</MenuItem>
              <MenuItem value="created_at">Creation Date</MenuItem>
            </Select>
          </Box>
        </MenuItem>
        <MenuItem onClick={toggleSortOrder}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SortIcon />
            {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
          </Box>
        </MenuItem>
      </Menu>

      {/* Create/Edit Quiz Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            background: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white',
          }
        }}
      >
        <DialogTitle>
          {editingQuiz ? 'Edit Quiz' : 'Create New Quiz'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            {/* Basic Info */}
            <TextField
              label="Quiz Title"
              value={formData.title}
              onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
              required
              error={!formData.title.trim()}
              helperText={!formData.title.trim() ? 'Title is required' : ''}
              fullWidth
            />
            
            <TextField
              label="Description"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              multiline
              rows={2}
              fullWidth
            />

            {/* Category Selection */}
            <Autocomplete
              multiple
              options={categories}
              getOptionLabel={(option) => option.name}
              value={categories.filter(cat => formData.category_ids.includes(cat.id))}
              onChange={(_, newValue) => {
                setFormData(prev => ({
                  ...prev,
                  category_ids: newValue.map(cat => cat.id)
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categories"
                  placeholder="Select categories"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <GlassChip
                    key={option.id}
                    label={option.name}
                    {...getTagProps({ index })}
                    icon={<CategoryIcon sx={{ fontSize: 16 }} />}
                  />
                ))
              }
            />

            {/* Questions Section */}
            <Typography variant="h6" sx={{ mt: 2 }}>
              Questions
            </Typography>

            {formData.questions.map((question, questionIndex) => (
              <GlassAccordion key={questionIndex}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`question-${questionIndex}-content`}
                  id={`question-${questionIndex}-header`}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width: '100%', 
                    justifyContent: 'space-between'
                  }}>
                    <Typography>
                      Question {questionIndex + 1}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteQuestion(questionIndex);
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                      label="Question Text"
                      value={question.question_text}
                      onChange={(e) => handleQuestionChange(
                        questionIndex,
                        'question_text',
                        e.target.value
                      )}
                      required
                      multiline
                      rows={2}
                      fullWidth
                    />

                    <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
  <Button
    variant="outlined"
    startIcon={<ImageIcon />}
    component="label"
  >
    {question.question_image ? 'Change Image' : 'Upload Image'}
    <input
      type="file"
      hidden
      accept="image/*"
      onChange={(e) => handleFileUpload(
        questionIndex,
        'question_image',
        e.target.files[0]
      )}
    />
  </Button>
  {question.question_image && (
    <ImagePreview
      src={question.question_image}
      onRemove={() => handleQuestionChange(questionIndex, 'question_image', null)}
    />
  )}
</Box>

                      <TextField
                        label="Video URL"
                        value={question.question_video_url}
                        onChange={(e) => handleQuestionChange(
                          questionIndex,
                          'question_video_url',
                          e.target.value
                        )}
                        sx={{ flexGrow: 1 }}
                      />
                    </Box>

                    <TextField
                      label="Explanation"
                      value={question.explanation}
                      onChange={(e) => handleQuestionChange(
                        questionIndex,
                        'explanation',
                        e.target.value
                      )}
                      multiline
                      rows={2}
                      fullWidth
                    />

                    {/* Options Section */}
                    <Typography variant="subtitle1">Options</Typography>

                    <List>
                      {question.options.map((option, optionIndex) => (
                        <ListItem 
                          key={optionIndex}
                          sx={{ 
                            bgcolor: 'rgba(255, 255, 255, 0.05)',
                            borderRadius: 1,
                            mb: 1 
                          }}
                        >
                          <Box sx={{ 
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            gap: 1 
                          }}>
                            <Box sx={{ 
                              display: 'flex', 
                              gap: 2, 
                              alignItems: 'center' 
                            }}>
                              <TextField
                                label={`Option ${optionIndex + 1}`}
                                value={option.option_text}
                                onChange={(e) => handleOptionChange(
                                  questionIndex,
                                  optionIndex,
                                  'option_text',
                                  e.target.value
                                )}
                                required
                                fullWidth
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={option.is_correct}
                                    onChange={(e) => handleOptionChange(
                                      questionIndex,
                                      optionIndex,
                                      'is_correct',
                                      e.target.checked
                                    )}
                                  />
                                }
                                label="Correct"
                              />
                              <IconButton
                                onClick={() => handleRemoveOption(
                                  questionIndex,
                                  optionIndex
                                )}
                                disabled={question.options.length <= 2}
                                sx={{ color: 'error.main' }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>

                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
  <Button
    variant="outlined"
    startIcon={<ImageIcon />}
    component="label"
    size="small"
  >
    {option.option_image ? 'Change Image' : 'Upload Image'}
    <input
      type="file"
      hidden
      accept="image/*"
      onChange={(e) => handleFileUpload(
        questionIndex,
        'option_image',
        e.target.files[0],
        optionIndex
      )}
    />
  </Button>
  {option.option_image && (
    <ImagePreview
      src={option.option_image}
      onRemove={() => handleOptionChange(
        questionIndex,
        optionIndex,
        'option_image',
        null
      )}
    />
  )}
</Box>
                          </Box>
                        </ListItem>
                      ))}
                    </List>

                    <Button
                      onClick={() => handleAddOption(questionIndex)}
                      startIcon={<AddIcon />}
                      variant="outlined"
                    >
                      Add Option
                    </Button>
                  </Box>
                </AccordionDetails>
              </GlassAccordion>
            ))}

            <Button
              onClick={handleAddQuestion}
              startIcon={<AddIcon />}
              variant="outlined"
              sx={{ mt: 2 }}
            >
              Add Question
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: 'white' }}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            disabled={loading || !formData.title.trim() || formData.questions.length === 0}
            startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            {editingQuiz ? 'Update Quiz' : 'Create Quiz'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        PaperProps={{
          sx: {
            background: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white',
          }
        }}
      >
        <DialogTitle>Delete Quiz</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            Are you sure you want to delete this quiz? This action cannot be undone.
          </DialogContentText>
          {itemToDelete?.questions?.length > 0 && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              This quiz contains {itemToDelete.questions.length} questions that will also be deleted.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteConfirmOpen(false)}
            sx={{ color: 'white' }}
          >
            Cancel
            </Button>
            <Button 
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            startIcon={loading ? <CircularProgress size={20} /> : <DeleteIcon />}
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSuccessMessage('')} 
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

// PropTypes validation
QuizManagement.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    content_type: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })),
    questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      question_text: PropTypes.string.isRequired,
      explanation: PropTypes.string,
      question_image: PropTypes.string,
      question_video_url: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        option_text: PropTypes.string.isRequired,
        option_image: PropTypes.string,
        is_correct: PropTypes.bool.isRequired
      }))
    }))
  })).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string
  })).isRequired,
  onQuizCreate: PropTypes.func.isRequired,
  onContentUpdate: PropTypes.func.isRequired,
  onContentDelete: PropTypes.func.isRequired
};

export default QuizManagement;