import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

const GradientBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  background: 'linear-gradient(45deg, #1a237e, #311b92, #4a148c)',
});

const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

function SalaryBreakdownModal({ open, onClose, salaryData }) {
  if (!salaryData) return null;

  const { year, month, total_salary, calculation_details } = salaryData;

  return (
    <>
      <GradientBackground />
      <GlassDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ color: 'white' }}>
          Salary Breakdown for {dayjs().month(month - 1).format('MMMM')} {year}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
            Total Salary: ₹{parseFloat(total_salary).toFixed(2)}
          </Typography>
          <GlassTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell rowSpan={5}>Earnings</TableCell>
                  <TableCell>Base Salary</TableCell>
                  <TableCell align="right">{calculation_details.base_salary}</TableCell>
                </TableRow>
                {Object.entries(calculation_details.additions).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2} align="right"><strong>Total Earnings</strong></TableCell>
                  <TableCell align="right"><strong>{calculation_details.total_earnings}</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={Object.keys(calculation_details.deductions).length + 1}>Deductions</TableCell>
                </TableRow>
                {Object.entries(calculation_details.deductions).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2} align="right"><strong>Total Deductions</strong></TableCell>
                  <TableCell align="right"><strong>{calculation_details.total_deductions}</strong></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </GlassTableContainer>
          {calculation_details.trainer_details && (
            <Typography variant="body2" style={{ marginTop: '1rem', color: 'white' }}>
              Hourly Rate: {calculation_details.trainer_details.hourly_rate}, 
              Total Hours: {calculation_details.trainer_details.total_hours}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={onClose}>
            Close
          </GradientButton>
        </DialogActions>
      </GlassDialog>
    </>
  );
}

export default SalaryBreakdownModal;