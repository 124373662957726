import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Button, Typography, CircularProgress,
  useTheme, useMediaQuery,Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getUser, getCourse, getTrainerAudits } from '../api';

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const GradientBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  background: 'linear-gradient(45deg, #0d47a1, #1565c0, #1e88e5)',


});

const AuditHistoryModal = ({ open, onClose, audits: initialAudits, onViewDetails, trainerId }) => {
  const [audits, setAudits] = useState(initialAudits);
  const [auditorNames, setAuditorNames] = useState({});
  const [courseNames, setCourseNames] = useState({});
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchAudits = async () => {
      setLoading(true);
      try {
        if (trainerId) {
          const response = await getTrainerAudits(trainerId);
          setAudits(response.data);
        } else {
          setAudits(initialAudits);
        }
      } catch (error) {
        console.error('Error fetching trainer audits:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAudits();
  }, [trainerId, initialAudits]);

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      const newAuditorNames = { ...auditorNames };
      const newCourseNames = { ...courseNames };

      for (const audit of audits) {
        if (audit.auditor && !newAuditorNames[audit.auditor]) {
          try {
            const response = await getUser(audit.auditor);
            if (response && response.full_name) {
              newAuditorNames[audit.auditor] = response.full_name;
            } else {
              newAuditorNames[audit.auditor] = 'Unknown';
            }
          } catch (error) {
            console.error(`Error fetching auditor name for ID ${audit.auditor}:`, error);
            newAuditorNames[audit.auditor] = 'Unknown';
          }
        }

        if (audit.course && !newCourseNames[audit.course]) {
          try {
            const response = await getCourse(audit.course);
            if (response && response.name) {
              newCourseNames[audit.course] = response.name;
            } else {
              newCourseNames[audit.course] = 'Unknown Course';
            }
          } catch (error) {
            console.error(`Error fetching course name for ID ${audit.course}:`, error);
            newCourseNames[audit.course] = 'Unknown Course';
          }
        }
      }

      setAuditorNames(newAuditorNames);
      setCourseNames(newCourseNames);
      setLoading(false);
    };

    if (open) {
      fetchDetails();
    }
  }, [audits, open]);

  const formatDate = (dateString) => {
    return dateString ? new Date(dateString).toLocaleDateString() : 'N/A';
  };

  if (loading) {
    return (
      <Dialog 
        open={open} 
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 128, 0.8)', 

            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <DialogContent>
          <CircularProgress sx={{ color:'white' }} />
          <Typography color="white">Loading audit data...</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <GradientBackground />
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 128, 0.8)', 
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <DialogTitle sx={{ color: 'white' }}>
          {trainerId ? 'Trainer Audit History' : 'Audit History'}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress sx={{ color:'white' }} />
            </Box>
          ) : (
            <GlassTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Audit Date</TableCell>
                    {!isMobile && <TableCell>Class Date</TableCell>}
                    <TableCell>Overall Score</TableCell>
                    {!isMobile && <TableCell>Student Name</TableCell>}
                    {!isMobile && <TableCell>Course</TableCell>}
                    {!trainerId && <TableCell>Auditor</TableCell>}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {audits.map((audit) => (
                    <TableRow key={audit.id}>
                      <TableCell>{formatDate(audit.audit_date)}</TableCell>
                      {!isMobile && <TableCell>{formatDate(audit.class_date)}</TableCell>}
                      <TableCell>{audit.overall_score}</TableCell>
                      {!isMobile && <TableCell>{audit.student_name || 'N/A'}</TableCell>}
                      {!isMobile && <TableCell>{audit.course ? (courseNames[audit.course] || 'Unknown Course') : 'N/A'}</TableCell>}
                      {!trainerId && <TableCell>{auditorNames[audit.auditor] || 'Unknown Auditor'}</TableCell>}
                      <TableCell>
                        <GradientButton
                          onClick={() => onViewDetails(audit)}
                          size="small"
                        >
                          View Details
                        </GradientButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GlassTableContainer>
          )}
          {audits.length === 0 && !loading && (
            <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px', color: 'white' }}>
              No audit history available.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AuditHistoryModal;