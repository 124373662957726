import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  UserRound, Users, School, BellRing,
  Calendar, GraduationCap, UserMinus
} from 'lucide-react';
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip, Box, Typography } from '@mui/material';

const GlassBox = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '8px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
  }
}));

const NotificationItem = ({ notification, onMarkRead }) => {
  const navigate = useNavigate();

  const getNavigationDetails = () => {
    const message = notification.message;
    const type = notification.notification_type;

    console.log('Notification type:', type);
    console.log('Message:', message);

    if (type === 'TRAINER_ABSENT') {
      const trainerMatch = message.match(/Trainer .* \(ID: (\d+)\).*?student/);
      const studentMatch = message.match(/student .* \(ID: (\d+)\)/);

      console.log('Trainer match:', trainerMatch);
      console.log('Student match:', studentMatch);

      if (trainerMatch && trainerMatch[1]) {
        console.log('Trainer ID found:', trainerMatch[1]);
        return {
          route: `/trainers/${trainerMatch[1]}`,
          icon: <UserRound />,
          tooltip: 'View Trainer Profile'
        };
      } else if (studentMatch && studentMatch[1]) {
        console.log('No trainer ID found, using student ID:', studentMatch[1]);
        return {
          route: `/student-details/${studentMatch[1]}`,
          icon: <Users />,
          tooltip: 'View Student Profile'
        };
      } else {
        console.log('No IDs found, cannot navigate');
        return {
          route: null,
          icon: <BellRing />,
          tooltip: 'Notification'
        };
      }
    }
    
    const idMatch = message.match(/\(ID: (\d+)\)/);
    
    switch(type) {
      case 'ABSENCE':
      case 'COURSE_ENDING':
      case 'COURSE_ENDED':
      case 'STUDENT_UNASSIGNED':
        return {
          route: idMatch ? `/student-details/${idMatch[1]}` : null,
          icon: type === 'ABSENCE' ? <Users /> :
                type === 'COURSE_ENDING' ? <Calendar /> :
                type === 'COURSE_ENDED' ? <GraduationCap /> : <UserMinus />,
          tooltip: 'View Student Details'
        };
        
      default:
        return {
          route: null,
          icon: <BellRing />,
          tooltip: 'Notification'
        };
    }
  };

  const { route, icon, tooltip } = getNavigationDetails();

  const handleNotificationClick = () => {
    // Only call onMarkRead if it exists and notification is not read
    if (onMarkRead && !notification.is_read) {
      onMarkRead(notification.id);
    }
  };

  return (
    <GlassBox>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box flex={1}>
          <Typography
            variant="body2"
            sx={{
              color: notification.is_read ? 'rgba(255,255,255,0.6)' : 'white',
              cursor: 'pointer'
            }}
            onClick={handleNotificationClick}
          >
            {notification.message}
          </Typography>
          <Typography variant="caption" color="rgba(255,255,255,0.4)">
            {new Date(notification.created_at).toLocaleString()}
          </Typography>
        </Box>
        
        {route && (
          <Tooltip title={tooltip}>
            <IconButton 
              size="small"
              onClick={() => navigate(route)}
              sx={{ 
                color: 'white',
                '&:hover': {
                  background: 'rgba(255,255,255,0.1)'
                }
              }}
            >
              {icon}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </GlassBox>
  );
};

export default NotificationItem;