import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Autocomplete,
  Alert,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Chip,
  styled,
  InputAdornment,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Select,
  Snackbar
} from '@mui/material';
import {
  Add as AddIcon,
  Person as PersonIcon,
  School as SchoolIcon,
  Category as CategoryIcon,
  Search as SearchIcon,
  AssignmentTurnedIn as AssignmentIcon
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { listUsers, listCategories } from '../../api';

// Styled Components
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)'
  }
}));

const GlassChip = styled(Chip)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.common.white,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
}));

const AssignmentManagement = ({ courseFlows, onAssign }) => {
  // State Management
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [students, setStudents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [studentSearchTerm, setStudentSearchTerm] = useState('');
  const [isLoadingStudents, setIsLoadingStudents] = useState(false);
  const [studentPage, setStudentPage] = useState(1);
  const [hasMoreStudents, setHasMoreStudents] = useState(true);
  
  const [formData, setFormData] = useState({
    student: null,
    courseFlow: null,
    startDate: dayjs(),
    endDate: dayjs().add(30, 'day')
  });

  // Load students function with search and pagination
  const loadStudents = async (searchQuery = '', page = 1) => {
    try {
      setIsLoadingStudents(true);
      const response = await listUsers({ 
        roles: ['STUDENT'],
        search: searchQuery,
        page: page,
        page_size: 10
      });
      
      if (page === 1) {
        setStudents(response.results || []);
      } else {
        setStudents(prev => [...prev, ...(response.results || [])]);
      }
      
      setHasMoreStudents((response.results || []).length === 10);
      setStudentPage(page);
    } catch (err) {
      console.error('Error loading students:', err);
      setError('Failed to load students');
    } finally {
      setIsLoadingStudents(false);
    }
  };

  // Initial load of categories and students
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        const categoriesRes = await listCategories();
        setCategories(categoriesRes.data || []);
        await loadStudents();
      } catch (err) {
        setError('Failed to load initial data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, []);

  // Handle student search with debounce
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (studentSearchTerm !== '') {
        loadStudents(studentSearchTerm, 1);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [studentSearchTerm]);

  // Filtered course flows
  const filteredCourseFlows = useMemo(() => {
    return courseFlows.filter(flow => {
      const matchesSearch = 
        flow.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        flow.description.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesCategories = 
        selectedCategories.length === 0 ||
        selectedCategories.every(catId => 
          flow.categories?.some(cat => cat.id === catId)
        );

      return matchesSearch && matchesCategories;
    });
  }, [courseFlows, searchTerm, selectedCategories]);

  // Dialog Handlers
  const handleOpen = () => {
    setOpenDialog(true);
    setFormData({
      student: null,
      courseFlow: null,
      startDate: dayjs(),
      endDate: dayjs().add(30, 'day')
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
    setError(null);
  };

  // Form Submission
  const handleSubmit = async () => {
    try {
      if (!formData.student || !formData.courseFlow) {
        setError('Please select both student and course flow');
        return;
      }

      setLoading(true);
      await onAssign(formData.courseFlow.id, formData.student.id);
      setSuccessMessage('Course flow assigned successfully');
      handleClose();
    } catch (err) {
      setError('Failed to assign course flow');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Category Display Component
  const CategoryDisplay = ({ categories }) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {categories?.map((category) => (
        <GlassChip
          key={category.id}
          label={category.name}
          size="small"
          icon={<CategoryIcon sx={{ fontSize: 16 }} />}
          variant="outlined"
        />
      ))}
    </Box>
  );

  return (
    <Box>
      {/* Header and Controls */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography variant="h6" sx={{ 
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <AssignmentIcon color="primary" />
          Course Flow Assignments
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Assign Course Flow
        </Button>
      </Box>

      {/* Search and Filters */}
      <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          placeholder="Search course flows..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />

        <Autocomplete
          multiple
          options={categories}
          getOptionLabel={(option) => option.name}
          value={categories.filter(cat => selectedCategories.includes(cat.id))}
          onChange={(_, newValue) => {
            setSelectedCategories(newValue.map(cat => cat.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Categories"
              placeholder="Select categories"
            />
          )}
          sx={{ minWidth: 300 }}
        />
      </Box>

      {/* Alerts */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {/* Course Flows Grid */}
      <Grid container spacing={2}>
        {filteredCourseFlows.map((flow) => (
          <Grid item xs={12} md={6} lg={4} key={flow.id}>
            <GlassCard>
              <CardContent>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6">
                    {flow.title}
                    {flow.is_default && (
                      <GlassChip
                        icon={<SchoolIcon />}
                        label="Default"
                        size="small"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {flow.description}
                  </Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Items: {flow.flow_items?.length || 0}
                  </Typography>
                  {flow.categories?.length > 0 && (
                    <Box sx={{ mt: 1 }}>
                      <CategoryDisplay categories={flow.categories} />
                    </Box>
                  )}
                </Box>

                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  fullWidth
                  onClick={() => {
                    setFormData(prev => ({ ...prev, courseFlow: flow }));
                    setOpenDialog(true);
                  }}
                >
                  Assign to Student
                </Button>
              </CardContent>
            </GlassCard>
          </Grid>
        ))}
      </Grid>

      {/* Assignment Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            background: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white'
          }
        }}
      >
        <DialogTitle>Assign Course Flow</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <Autocomplete
              options={students}
              getOptionLabel={(option) => 
                `${option.username} (${option.full_name || 'No name'})`
              }
              value={formData.student}
              onChange={(_, newValue) => setFormData(prev => ({
                ...prev,
                student: newValue
              }))}
              onInputChange={(_, newInputValue) => {
                setStudentSearchTerm(newInputValue);
              }}
              filterOptions={(x) => x} // Disable built-in filtering
              loading={isLoadingStudents}
              loadingText="Loading students..."
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Search Students"
                  error={!formData.student && error}
                  helperText={!formData.student && error ? 'Please select a student' : ''}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingStudents ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1">
                      {option.username}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {option.full_name} • {option.email}
                    </Typography>
                  </Box>
                </li>
              )}
              ListboxProps={{
                onScroll: (event) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight &&
                    hasMoreStudents &&
                    !isLoadingStudents
                  ) {
                    loadStudents(studentSearchTerm, studentPage + 1);
                  }
                },
              }}
            />

            <Autocomplete
              options={filteredCourseFlows}
              getOptionLabel={(option) => option.title}
              value={formData.courseFlow}
              onChange={(_, newValue) => setFormData(prev => ({
                ...prev,
                courseFlow: newValue
              }))}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Select Course Flow"
                  error={!formData.courseFlow && error}
                  helperText={!formData.courseFlow && error ? 'Please select a course flow' : ''}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1">
                      {option.title}
                      {option.is_default && (
                        <GlassChip
                          icon={<SchoolIcon />}
                          label="Default"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Typography>
                    <Box sx={{ mt: 0.5 }}>
                      <CategoryDisplay categories={option.categories} />
                    </Box>
                  </Box>
                </li>
              )}
            />

            <DatePicker
              label="Start Date"
              value={formData.startDate}
              onChange={(newValue) => setFormData(prev => ({
                ...prev,
                startDate: newValue
              }))}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />

            <DatePicker
              label="End Date"
              value={formData.endDate}
              onChange={(newValue) => setFormData(prev => ({
                ...prev,
                endDate: newValue
              }))}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: 'white' }}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            disabled={loading || !formData.student || !formData.courseFlow}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Assigning...' : 'Assign Course Flow'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Assignment List Section */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ 
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <AssignmentIcon color="primary" />
          Recent Assignments
        </Typography>

        <List>
          {courseFlows
            .filter(flow => flow.assignments?.length > 0)
            .map(flow => (
              <GlassCard key={flow.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">
                    {flow.title}
                    {flow.is_default && (
                      <GlassChip
                        icon={<SchoolIcon />}
                        label="Default"
                        size="small"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Typography>

                  <Box sx={{ mt: 1 }}>
                    <CategoryDisplay categories={flow.categories} />
                  </Box>

                  <List dense>
                    {flow.assignments?.map(assignment => (
                      <ListItem
                        key={assignment.id}
                        sx={{
                          bgcolor: 'rgba(255, 255, 255, 0.05)',
                          borderRadius: 1,
                          mt: 1
                        }}
                      >
                        <ListItemText
                          primary={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <PersonIcon fontSize="small" />
                              <Typography variant="body2">
                                {assignment.student.username}
                              </Typography>
                            </Box>
                          }
                          secondary={
                            <Box sx={{ mt: 0.5 }}>
                              <Typography variant="caption" color="text.secondary">
                                Assigned: {dayjs(assignment.assigned_at).format('MMMM D, YYYY')}
                              </Typography>
                            </Box>
                          }
                        />
                        <ListItemSecondaryAction>
                          <GlassChip
                            label={assignment.status}
                            size="small"
                            color={assignment.status === 'ACTIVE' ? 'success' : 'default'}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </GlassCard>
            ))}
        </List>
      </Box>

      {/* Success Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSuccessMessage('')} 
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Loading Overlay */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

// PropTypes validation
AssignmentManagement.propTypes = {
  courseFlows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    is_default: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })),
    assignments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      student: PropTypes.shape({
        id: PropTypes.number.isRequired,
        username: PropTypes.string.isRequired
      }),
      assigned_at: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired
    })),
    flow_items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      order: PropTypes.number.isRequired,
      syllabus_item: PropTypes.object.isRequired
    }))
  })).isRequired,
  onAssign: PropTypes.func.isRequired
};

export default AssignmentManagement;