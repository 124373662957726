import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Chip,
  Tooltip,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Card,
  CardContent,
  Alert,
  CircularProgress,
  InputAdornment,
  Autocomplete,
  Menu,
  MenuItem,
  styled,
  Divider, Select
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
  CheckCircle as CheckCircleIcon,
  School as SchoolIcon,
  Timeline as TimelineIcon,
  LibraryBooks as LibraryBooksIcon,
  Category as CategoryIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  VideoLibrary as VideoIcon,
  TextFields as TextIcon,
  Quiz as QuizIcon,
  Sort as SortIcon
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {courseFlowAddItems, createCourseFlow} from '../../api';

// Styled Components
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)'
  }
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GlassChip = styled(Chip)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.common.white,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
}));

const DraggableListItem = styled(ListItem)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '8px',
  marginBottom: theme.spacing(1),
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const ContentTypeIcon = ({ type }) => {
  switch (type) {
    case 'VIDEO':
      return <VideoIcon color="primary" />;
    case 'TEXT':
      return <TextIcon color="success" />;
    case 'QUIZ':
      return <QuizIcon color="warning" />;
    default:
      return null;
  }
};

const GlassStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    color: theme.palette.common.white,
  },
  '& .MuiStepIcon-root': {
    color: 'rgba(255, 255, 255, 0.3)',
    '&.Mui-active, &.Mui-completed': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiStepContent-root': {
    borderLeft: '1px solid rgba(255, 255, 255, 0.2)',
  }
}));

const CourseFlowManagement = ({ 
  courseFlows, 
  items,
  categories,
  onFlowCreate, 
  onFlowUpdate, 
  onFlowDelete 
}) => {
  // State
  const [openDialog, setOpenDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [editingFlow, setEditingFlow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortBy, setSortBy] = useState('title');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    is_default: false,
    selectedItems: [],
    category_ids: []
  });

  // Filtered and Sorted Flows
  const filteredFlows = useMemo(() => {
    let filtered = courseFlows.filter(flow => {
      const matchesSearch = 
        flow.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        flow.description.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesCategories = 
        selectedCategories.length === 0 ||
        selectedCategories.every(catId => 
          flow.categories?.some(cat => cat.id === catId)
        );

      return matchesSearch && matchesCategories;
    });

    // Sort the filtered flows
    return filtered.sort((a, b) => {
      let comparison = 0;
      switch (sortBy) {
        case 'title':
          comparison = a.title.localeCompare(b.title);
          break;
        case 'items':
          comparison = (a.flow_items?.length || 0) - (b.flow_items?.length || 0);
          break;
        case 'created_at':
          comparison = new Date(a.created_at) - new Date(b.created_at);
          break;
        default:
          comparison = 0;
      }
      return sortOrder === 'asc' ? comparison : -comparison;
    });
  }, [courseFlows, searchTerm, selectedCategories, sortBy, sortOrder]);

  // Reset Form
  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      is_default: false,
      selectedItems: [],
      category_ids: []
    });
    setEditingFlow(null);
    setError(null);
  };

  // Dialog Handlers
  const handleOpen = () => {
    resetForm();
    setOpenDialog(true);
  };

  const handleClose = () => {
    resetForm();
    setOpenDialog(false);
  };

  const handleOpenPreview = (flow) => {
    setSelectedFlow(flow);
    setOpenPreviewDialog(true);
  };

  const handleClosePreview = () => {
    setOpenPreviewDialog(false);
    setSelectedFlow(null);
  };
  const handleCreateCourseFlow = async (flowData) => {
    try {
      // First, create the course flow with basic info
      const response = await createCourseFlow({
        title: flowData.title,
        description: flowData.description,
        is_default: flowData.is_default,
        category_ids: flowData.category_ids
      });
  
      // If we have items and the flow was created successfully, add the items
      if (response?.data?.id && flowData.selectedItems?.length > 0) {
        await courseFlowAddItems(response.data.id, flowData.selectedItems);
      }
  
      return response;
    } catch (error) {
      console.error('Error creating course flow:', error);
      throw error;
    }
  };
  // Edit Handler
  const handleEdit = (flow) => {
    setEditingFlow(flow);
    setFormData({
      title: flow.title,
      description: flow.description,
      is_default: flow.is_default,
      selectedItems: flow.flow_items.map(fi => ({
        id: fi.syllabus_item.id,
        order: fi.order
      })),
      category_ids: flow.categories?.map(cat => cat.id) || []
    });
    setOpenDialog(true);
  };

  // Form Submission
  // In CourseFlowManagement.jsx
  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
  
      if (!formData.title.trim()) {
        setError('Title is required');
        return;
      }
  
      if (editingFlow) {
        // For existing flows, update basic info and then add items
        await onFlowUpdate(editingFlow.id, {
          title: formData.title.trim(),
          description: formData.description.trim(),
          is_default: formData.is_default,
          category_ids: formData.category_ids
        });
        
        if (formData.selectedItems.length > 0) {
          await courseFlowAddItems(editingFlow.id, formData.selectedItems);
        }
      } else {
        // For new flows, use the handleCreateCourseFlow function
        await handleCreateCourseFlow({
          title: formData.title.trim(),
          description: formData.description.trim(),
          is_default: formData.is_default,
          category_ids: formData.category_ids,
          selectedItems: formData.selectedItems
        });
      }
  
      handleClose();
    } catch (error) {
      console.error('Error saving course flow:', error);
      setError(error.response?.data?.error || 'Error saving course flow');
    } finally {
      setLoading(false);
    }
  };
  // Delete Handlers
  const handleDeleteClick = (flow) => {
    setItemToDelete(flow);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      await onFlowDelete(itemToDelete.id);
      setDeleteConfirmOpen(false);
      setItemToDelete(null);
    } catch (error) {
      setError('Failed to delete course flow');
    } finally {
      setLoading(false);
    }
  };

  // Drag and Drop Handler
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    setFormData(prev => {
      const items = Array.from(prev.selectedItems);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      return {
        ...prev,
        selectedItems: items.map((item, index) => ({
          ...item,
          order: index + 1
        }))
      };
    });
  };

  // Item Selection Handler
  const handleItemToggle = (itemId) => {
    console.log('Toggling item with ID:', itemId); // Debug log
  
    setFormData(prev => {
      const currentItems = [...prev.selectedItems];
      const index = currentItems.findIndex(i => i.id === itemId);
      
      let updatedItems;
      if (index === -1) {
        // Add new item
        updatedItems = [
          ...currentItems,
          {
            id: itemId,
            order: currentItems.length + 1
          }
        ];
      } else {
        // Remove existing item
        updatedItems = currentItems.filter((_, idx) => idx !== index);
      }
  
      // Reorder remaining items
      updatedItems = updatedItems.map((item, idx) => ({
        id: item.id,
        order: idx + 1
      }));
  
      console.log('Updated items:', updatedItems); // Debug log
  
      return {
        ...prev,
        selectedItems: updatedItems
      };
    });
  };

  // Filter and Sort Handlers
  const handleFilterClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterMenuAnchor(null);
  };

  const toggleSortOrder = () => {
    setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  // Category Display Component
  const CategoryDisplay = ({ categories }) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {categories?.map((category) => (
        <GlassChip
          key={category.id}
          label={category.name}
          size="small"
          icon={<CategoryIcon sx={{ fontSize: 16 }} />}
          variant="outlined"
        />
      ))}
    </Box>
  );

  return (
    <Box>
      {/* Header and Controls */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography variant="h6" sx={{ 
          color: 'white', 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1 
        }}>
          <TimelineIcon color="primary" />
          Course Flow Management
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            startIcon={<FilterIcon />}
            onClick={handleFilterClick}
          >
            Filter & Sort
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Create Course Flow
          </Button>
        </Box>
      </Box>

      {/* Search and Filters */}
      <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          placeholder="Search flows..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />

<Autocomplete
  multiple
  options={categories}
  getOptionLabel={(option) => option.name}
  value={categories.filter(cat => formData.category_ids.includes(cat.id))}
  onChange={(_, newValue) => {
    const newCategoryIds = newValue.map(category => category.id);
    setFormData(prev => ({
      ...prev,
      category_ids: newCategoryIds
    }));
  }}
  isOptionEqualToValue={(option, value) => option.id === value.id}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Categories"
      placeholder="Select categories"
    />
  )}
  renderTags={(value, getTagProps) =>
    value.map((option, index) => (
      <GlassChip
        key={option.id}
        label={option.name}
        {...getTagProps({ index })}
        icon={<CategoryIcon sx={{ fontSize: 16 }} />}
      />
    ))
  }
/>
      </Box>

      {/* Alerts */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {/* Course Flows Table */}
      <GlassTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Items</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredFlows.map((flow) => (
              <TableRow key={flow.id}>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TimelineIcon color="primary" fontSize="small" />
                    <Typography
                      component="button"
                      onClick={() => handleOpenPreview(flow)}
                      sx={{
                        background: 'none',
                        border: 'none',
                        color: 'primary.main',
                        cursor: 'pointer',
                        padding: 0,
                        textAlign: 'left',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {flow.title}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <GlassChip
                    icon={flow.is_default ? <SchoolIcon /> : <CheckCircleIcon />}
                    label={flow.is_default ? 'Default' : 'Custom'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <GlassChip
                      icon={<LibraryBooksIcon />}
                      label={`${flow.flow_items?.length || 0} items`}
                      size="small"
                    />
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {flow.flow_items?.slice(0, 3).map((item) => (
                        <Tooltip 
                          key={item.syllabus_item.id}
                          title={`Order: ${item.order} - ${item.syllabus_item.title}`}
                        >
                          <GlassChip
                            size="small"
                            label={`#${item.order}`}
                            variant="outlined"
                          />
                        </Tooltip>
                      ))}
                      {flow.flow_items?.length > 3 && (
                        <GlassChip
                          size="small"
                          label={`+${flow.flow_items.length - 3}`}
                          variant="outlined"
                        />
                      )}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <CategoryDisplay categories={flow.categories} />
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Preview Flow">
                    <IconButton onClick={() => handleOpenPreview(flow)} sx={{ color: 'primary.main' }}>
                      <TimelineIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Flow">
                    <IconButton onClick={() => handleEdit(flow)} sx={{ color: 'info.main' }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Flow">
  <IconButton 
    onClick={() => handleDeleteClick(flow)}
    sx={{ color: 'error.main' }}
  >
    <DeleteIcon />
  </IconButton>
</Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </GlassTableContainer>

      {/* Filter Menu */}
      <Menu
        anchorEl={filterMenuAnchor}
        open={Boolean(filterMenuAnchor)}
        onClose={handleFilterClose}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white',
            minWidth: 200
          }
        }}
      >
        <MenuItem>
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle2" gutterBottom>
              Sort By
            </Typography>
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              fullWidth
              size="small"
              sx={{ color: 'white' }}
            >
              <MenuItem value="title">Title</MenuItem>
              <MenuItem value="items">Number of Items</MenuItem>
              <MenuItem value="created_at">Creation Date</MenuItem>
            </Select>
          </Box>
        </MenuItem>
        <MenuItem onClick={toggleSortOrder}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SortIcon />
            {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
          </Box>
        </MenuItem>
      </Menu>

      {/* Create/Edit Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            background: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white',
            minHeight: '60vh'
          }
        }}
      >
        <DialogTitle>
          {editingFlow ? 'Edit Course Flow' : 'Create New Course Flow'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            {/* Basic Info */}
            <TextField
              label="Title"
              value={formData.title}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                title: e.target.value 
              }))}
              required
              fullWidth
              error={!formData.title.trim()}
              helperText={!formData.title.trim() ? 'Title is required' : ''}
            />
            
            <TextField
              label="Description"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                description: e.target.value 
              }))}
              multiline
              rows={3}
              fullWidth
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_default}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    is_default: e.target.checked 
                  }))}
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <SchoolIcon color={formData.is_default ? 'primary' : 'disabled'} />
                  Set as Default Course Flow
                </Box>
              }
            />

            {/* Category Selection */}
            <Autocomplete
              multiple
              options={categories}
              getOptionLabel={(option) => option.name}
              value={categories.filter(cat => formData.category_ids.includes(cat.id))}
              onChange={(_, newValue) => {
                setFormData(prev => ({
                  ...prev,
                  category_ids: newValue.map(cat => cat.id)
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categories"
                  placeholder="Select categories"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <GlassChip
                    key={option.id}
                    label={option.name}
                    {...getTagProps({ index })}
                    icon={<CategoryIcon sx={{ fontSize: 16 }} />}
                  />
                ))
              }
            />

            <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

            {/* Selected Items */}
            <Typography variant="subtitle1" sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1 
            }}>
              <DragIcon />
              Course Items (Drag to reorder)
            </Typography>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="selected-items">
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {formData.selectedItems.map((item, index) => {
                      const itemDetails = items.find(i => i.id === item.id);
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <DraggableListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ListItemIcon>
                                <DragIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={itemDetails?.title}
                                secondary={
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                                      Order: {index + 1}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                                      {itemDetails?.item_contents?.map(content => (
                                        <Tooltip
                                          key={content.content.id}
                                          title={content.content.title}
                                        >
                                          <Box>
                                            <ContentTypeIcon type={content.content.content_type} />
                                          </Box>
                                        </Tooltip>
                                      ))}
                                    </Box>
                                  </Box>
                                }
                              />
                              <IconButton 
                                onClick={() => handleItemToggle(item.id)}
                                sx={{ color: 'error.main' }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </DraggableListItem>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>

            <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

            {/* Available Items */}
            <Typography variant="subtitle1">
              Available Items
            </Typography>

            <List sx={{ 
              maxHeight: 300, 
              overflow: 'auto',
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: 1,
              p: 1
            }}>
              {items
                .filter(item => !formData.selectedItems.find(si => si.id === item.id))
                .map((item) => (
                  <DraggableListItem key={item.id}>
                    <ListItemText
                      primary={item.title}
                      secondary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box sx={{ display: 'flex', gap: 0.5 }}>
                            {item.item_contents?.map(content => (
                              <Tooltip
                                key={content.content.id}
                                title={content.content.title}
                              >
                                <Box>
                                  <ContentTypeIcon type={content.content.content_type} />
                                </Box>
                              </Tooltip>
                            ))}
                          </Box>
                          <CategoryDisplay categories={item.categories} />
                        </Box>
                      }
                    />
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => handleItemToggle(item.id)}
                    >
                      Add
                    </Button>
                  </DraggableListItem>
                ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: 'white' }}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={loading || !formData.title.trim() || formData.selectedItems.length === 0}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Saving...' : (editingFlow ? 'Update' : 'Create')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      {selectedFlow && (
        <Dialog
          open={openPreviewDialog}
          onClose={handleClosePreview}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              background: 'rgba(30, 30, 30, 0.9)',
              backdropFilter: 'blur(10px)',
              color: 'white',
              maxHeight: '90vh'
            }
          }}
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TimelineIcon color="primary" />
                {selectedFlow.title}
              </Typography>
              {selectedFlow.is_default && (
                <GlassChip
                  icon={<SchoolIcon />}
                  label="Default Flow"
                  color="primary"
                />
              )}
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {selectedFlow.description}
              </Typography>
              <Box sx={{ mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <GlassChip
                  icon={<LibraryBooksIcon />}
                  label={`${selectedFlow.flow_items?.length} Items`}
                />
                <CategoryDisplay categories={selectedFlow.categories} />
              </Box>
            </Box>

            <Typography variant="h6" gutterBottom>
              Flow Structure
            </Typography>

            <GlassStepper orientation="vertical">
              {selectedFlow.flow_items?.map((flowItem, index) => (
                <Step key={flowItem.syllabus_item.id} active={true}>
                  <StepLabel>
                    <Typography variant="subtitle1">
                      {flowItem.syllabus_item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {flowItem.syllabus_item.description}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <GlassCard>
                      <CardContent>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                          Content ({flowItem.syllabus_item.item_contents?.length || 0})
                        </Typography>
                        <List dense>
                        {flowItem.syllabus_item.item_contents?.map((content, contentIndex) => (
                            <ListItem
                              key={content.content.id}
                              sx={{
                                borderLeft: '2px solid',
                                borderLeftColor: 'primary.main',
                                bgcolor: 'rgba(255, 255, 255, 0.05)',
                                mb: 1,
                                borderRadius: 1,
                              }}
                            >
                              <ListItemIcon>
                                <ContentTypeIcon type={content.content.content_type} />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    {contentIndex + 1}. {content.content.title}
                                  </Typography>
                                }
                                secondary={
                                  <Box sx={{ mt: 1 }}>
                                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                                      {content.content.description}
                                    </Typography>
                                    {content.content.categories?.length > 0 && (
                                      <Box sx={{ mt: 1 }}>
                                        <CategoryDisplay categories={content.content.categories} />
                                      </Box>
                                    )}
                                  </Box>
                                }
                              />
                              <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'flex-end', 
                                gap: 1 
                              }}>
                                <GlassChip
                                  size="small"
                                  label={content.content.content_type}
                                  variant="outlined"
                                />
                              </Box>
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </GlassCard>
                  </StepContent>
                </Step>
              ))}
            </GlassStepper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePreview} sx={{ color: 'white' }}>
              Close
            </Button>
            <Button 
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => {
                handleClosePreview();
                handleEdit(selectedFlow);
              }}
            >
              Edit Flow
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        PaperProps={{
          sx: {
            background: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white',
          }
        }}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the course flow "{itemToDelete?.title}"? 
            This action cannot be undone.
          </Typography>
          {itemToDelete?.flow_items?.length > 0 && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              This flow contains {itemToDelete.flow_items.length} items that will also be removed from the flow.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteConfirmOpen(false)}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            startIcon={loading ? <CircularProgress size={20} /> : <DeleteIcon />}
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// PropTypes validation
CourseFlowManagement.propTypes = {
  courseFlows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    is_default: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })),
    flow_items: PropTypes.arrayOf(PropTypes.shape({
      syllabus_item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        item_contents: PropTypes.arrayOf(PropTypes.shape({
          content: PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            content_type: PropTypes.string.isRequired,
            description: PropTypes.string
          }).isRequired
        }))
      }).isRequired,
      order: PropTypes.number.isRequired
    }))
  })).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    item_contents: PropTypes.array
  })).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string
  })).isRequired,
  onFlowCreate: PropTypes.func.isRequired,
  onFlowUpdate: PropTypes.func.isRequired,
  onFlowDelete: PropTypes.func.isRequired
};

export default CourseFlowManagement;