import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import dayjs from 'dayjs';
import { 
  listAttendances, 
  createAttendanceReview, 
  updateAttendance,
  getAttendance, 
  addClassContent
} from '../api'; 

const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(10, 10, 50, 0.8)',

  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const DayCell = styled(Box)(({ theme, isSelected, isToday, status }) => ({
  height: '70px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: 
    isSelected ? 'rgba(255, 255, 255, 0.2)' :
    isToday ? 'rgba(255, 255, 255, 0.1)' :
    'transparent',
  color: 'white',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
}));

const StatusText = styled(Typography)(({ theme, status }) => ({
  fontSize: '10px',
  color: 
    status === 'PRESENT' ? theme.palette.success.main :
    status === 'ABSENT' ? theme.palette.error.main :
    status === 'TRAINER_ABSENT' ? theme.palette.warning.main :
    status === 'OFF' ? theme.palette.info.main :
    status === 'COMP' ? theme.palette.secondary.main :
    theme.palette.text.secondary,
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const AttendanceCalendar = ({ studentId, trainerId, userRole, onRequestReview }) => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState(null);
  const [attendanceData, setAttendanceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [classContent, setClassContent] = useState('');
  const [reviewRemark, setReviewRemark] = useState('');
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchAttendanceData = useCallback(async () => {
    if (!studentId && !trainerId) return;
    setLoading(true);
    try {
      const startDate = selectedMonth.startOf('month').format('YYYY-MM-DD');
      const endDate = selectedMonth.endOf('month').format('YYYY-MM-DD');
      const response = await listAttendances({
        student: studentId,
        trainer: trainerId,
        start_date: startDate,
        end_date: endDate,
      });
      
      const attendanceMap = {};
      response.data.forEach(attendance => {
        const attendanceDate = dayjs(attendance.timestamp).format('YYYY-MM-DD');
        if (!attendanceMap[attendanceDate] || dayjs(attendance.timestamp).isAfter(dayjs(attendanceMap[attendanceDate].timestamp))) {
          attendanceMap[attendanceDate] = attendance;
        }
      });
      setAttendanceData(attendanceMap);
      
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedMonth, studentId, trainerId]);

  useEffect(() => {
    fetchAttendanceData();
  }, [fetchAttendanceData]);

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth.add(1, 'month'));
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const dateString = date.format('YYYY-MM-DD');
    const attendance = attendanceData[dateString];
    if (attendance) {
      setSelectedAttendance(attendance);
      setClassContent(attendance.class_content || '');
      setDetailModalOpen(true);
    }
  };

  const handleUpdateAttendance = async () => {
    if (!selectedAttendance) return;
  
    try {
      if (userRole !== 'TRAINER') {
        throw new Error("Only trainers can update class content.");
      }
  
      const updatedAttendance = await addClassContent(selectedAttendance.id, { 
        class_content: classContent,
      });
      
      setDetailModalOpen(false);
      
      setAttendanceData(prevData => ({
        ...prevData,
        [dayjs(updatedAttendance.timestamp).format('YYYY-MM-DD')]: updatedAttendance
      }));
      
      fetchAttendanceData();
      setSnackbar({ open: true, message: 'Class content updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating class content:', error);
      setSnackbar({ open: true, message: error.message || 'Failed to update class content. Please try again.', severity: 'error' });
    }
  };

  const handleRequestReview = async () => {
    try {
      await createAttendanceReview({
        attendance: selectedAttendance.id,
        trainer: trainerId,
        remark: reviewRemark,
      });
      setReviewModalOpen(false);
      onRequestReview(selectedAttendance);
      setReviewRemark('');
      setSnackbar({ open: true, message: 'Attendance review requested successfully', severity: 'success' });
    } catch (error) {
      console.error('Error requesting review:', error);
      setSnackbar({ open: true, message: 'Failed to request review. Please try again.', severity: 'error' });
    }
  };

  const renderCalendar = () => {
    const startOfMonth = selectedMonth.startOf('month');
    const endOfMonth = selectedMonth.endOf('month');
    const startDate = startOfMonth.startOf('week');
    const endDate = endOfMonth.endOf('week');

    const calendarDays = [];
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    calendarDays.push(
      <Grid container key="weekdays">
        {weekDays.map(day => (
          <Grid item xs key={day}>
            <Typography align="center" variant="subtitle2" color="white">{day}</Typography>
          </Grid>
        ))}
      </Grid>
    );

    let week = [];
    for (let day = startDate; day.isBefore(endDate) || day.isSame(endDate); day = day.add(1, 'day')) {
      const dateString = day.format('YYYY-MM-DD');
      const attendance = attendanceData[dateString];
      const isSelected = selectedDate && selectedDate.isSame(day, 'day');
      const isToday = day.isSame(dayjs(), 'day');

      week.push(
        <Grid item xs key={dateString}>
          <DayCell
            isSelected={isSelected}
            isToday={isToday}
            onClick={() => handleDateClick(day)}
          >
            <Typography variant="body2">{day.date()}</Typography>
            {attendance && (
              <StatusText status={attendance.status}>
                {attendance.status}
              </StatusText>
            )}
          </DayCell>
        </Grid>
      );

      if (week.length === 7) {
        calendarDays.push(<Grid container key={day.format('YYYY-MM-DD')}>{week}</Grid>);
        week = [];
      }
    }

    if (week.length > 0) {
      calendarDays.push(<Grid container key={endDate.format('YYYY-MM-DD')}>{week}</Grid>);
    }

    return calendarDays;
  };

  return (
    <GlassContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <IconButton onClick={handlePrevMonth} sx={{ color: 'white' }}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h6" color="white">{selectedMonth.format('MMMM YYYY')}</Typography>
        <IconButton onClick={handleNextMonth} sx={{ color: 'white' }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress sx={{ color: 'white' }} />
        </Box>
      ) : (
        renderCalendar()
      )}

      <Dialog 
        open={detailModalOpen} 
        onClose={() => setDetailModalOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 18, 0.8)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <DialogTitle sx={{ color: 'white' }}>Attendance Details</DialogTitle>
        <DialogContent>
          {selectedAttendance && (
            <Box position="relative">
              <Typography color="white">Date: {dayjs(selectedAttendance.timestamp).format('MMMM D, YYYY')}</Typography>
              <Typography color="white">Status: {selectedAttendance.status}</Typography>
              <Typography color="white">Time: {dayjs(selectedAttendance.timestamp).format('HH:mm:ss')}</Typography>
              {userRole === 'TRAINER' && (
                <StyledTextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Class Content: Update what you taught"
                  value={classContent}
                  onChange={(e) => setClassContent(e.target.value)}
                  margin="normal"
                />
              )}
              {selectedAttendance.student_feedback !== 'NO_ACTION' && (
                <Box mt={2}>
                  <Typography variant="subtitle2" color="white">Student Feedback:</Typography>
                  <Chip
                    label={selectedAttendance.student_feedback}
                    color={selectedAttendance.student_feedback === 'ACCEPTED' ? 'success' : 'error'}
                    sx={{ color: 'white', borderColor: 'white' }}
                  />
                </Box>
              )}
              {userRole === 'TRAINER' && (
                <>
                <GradientButton
                  variant="contained"
                  size="small"
                  onClick={() => setReviewModalOpen(true)}
                  sx={{ mt: 2 }}
                >
                  Send attendance for review
                </GradientButton>
                <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" sx={{ mt: 1 }}>
                  <strong>Note:</strong> If you think the student has marked incorrect attendance, you can ask for a review.
                </Typography>
              </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailModalOpen(false)} sx={{ color: 'white' }}>Close</Button>
          {userRole === 'TRAINER' && (
            <GradientButton onClick={handleUpdateAttendance}>
              Update Class Content
            </GradientButton>
          )}
        </DialogActions>
      </Dialog>

      <Dialog 
        open={reviewModalOpen} 
        onClose={() => setReviewModalOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 18, 0.8)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <DialogTitle sx={{ color: 'white' }}>Request Attendance Review</DialogTitle>
        <DialogContent>
          <StyledTextField
            fullWidth
            multiline
            rows={5}
            label="Reason for review request"
            value={reviewRemark}onChange={(e) => setReviewRemark(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReviewModalOpen(false)} sx={{ color: 'white' }}>Cancel</Button>
          <GradientButton onClick={handleRequestReview}>
            Submit Review Request
          </GradientButton>
        </DialogActions>
      </Dialog>
    </GlassContainer>
  );
};

export default AttendanceCalendar;