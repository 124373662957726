import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { CheckCircle, FileText, Newspaper } from 'lucide-react';
import {
  listStudentCourseFlows,
  getStudentProgressReport,
  getQuizAttempts,
  submitQuiz
} from '../api';

const StudentProgress = ({ studentId }) => {
  const [loading, setLoading] = useState(true);
  const [studentFlows, setStudentFlows] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [quizAttempts, setQuizAttempts] = useState([]);
  const [expandedFlow, setExpandedFlow] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStudentFlows = async () => {
      try {
        setLoading(true);
        setError(null);

        // First get all student flows
        const flowsResponse = await listStudentCourseFlows({ 
          student: studentId,
          is_active: true 
        });

        if (!flowsResponse.data || !Array.isArray(flowsResponse.data)) {
          throw new Error('Invalid response format from student flows API');
        }

        // Then get progress report for each flow
        const flowsWithProgress = await Promise.all(
          flowsResponse.data.map(async (flow) => {
            try {
              const progressResponse = await getStudentProgressReport(flow.id);
              return {
                ...flow,
                progress: progressResponse.data
              };
            } catch (progressError) {
              console.error(`Error fetching progress for flow ${flow.id}:`, progressError);
              return {
                ...flow,
                progress: {
                  overall_progress: { completion_percentage: 0 },
                  items_progress: []
                }
              };
            }
          })
        );

        console.log('Flows with progress:', flowsWithProgress); // Debug log
        setStudentFlows(flowsWithProgress);
      } catch (err) {
        console.error('Error fetching student flows:', err);
        setError('Failed to load student progress. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (studentId) {
      fetchStudentFlows();
    }
  }, [studentId]);

  const handleQuizClick = async (flowId, contentId, flowItemId) => {
    try {
      const response = await getQuizAttempts(flowId, contentId, flowItemId);
      if (response && response.data) {
        setQuizAttempts(response.data);
        setSelectedQuiz({
          contentId,
          flowItemId,
          attempts: response.data
        });
      }
    } catch (error) {
      console.error('Error fetching quiz attempts:', error);
      setError('Failed to load quiz attempts');
    }
  };

  const handleCloseQuizDialog = () => {
    setSelectedQuiz(null);
    setQuizAttempts([]);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!studentFlows.length) {
    return (
      <Box p={4}>
        <Typography color="white">No course flows found for this student.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      {studentFlows.map((flow) => (
        <Accordion 
          key={flow.id}
          expanded={expandedFlow === flow.id}
          onChange={() => setExpandedFlow(expandedFlow === flow.id ? null : flow.id)}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 2,
            mb: 2,
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary 
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            sx={{
              borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ color: 'white', fontWeight: 600 }}>
                  {flow.base_course_flow?.title || 'Untitled Flow'}
                </Typography>
                <Chip
                  label={`${Math.round(flow.progress?.overall_progress?.completion_percentage || 0)}% Complete`}
                  color="primary"
                  sx={{ ml: 2 }}
                />
              </Box>
              <LinearProgress 
                variant="determinate" 
                value={flow.progress?.overall_progress?.completion_percentage || 0}
                sx={{ mt: 1 }}
              />
            </Box>
          </AccordionSummary>
          
          <AccordionDetails>
            <List>
              {flow.progress?.items_progress?.map((item) => (
                <ListItem 
                  key={item.item_id}
                  sx={{
                    borderRadius: 1,
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    mb: 1,
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography sx={{ color: 'white', fontWeight: 500 }}>
                        {item.item_title}
                      </Typography>
                      {item.is_completed && (
                        <CheckCircle color="#4caf50" size={20} />
                      )}
                    </Box>
                    
                    <List sx={{ pl: 2 }}>
                      {item.content_progress?.map((content) => (
                        <ListItem 
                          key={content.content_id}
                          sx={{ py: 0.5 }}
                        >
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {content.content_type === 'QUIZ' ? (
                                  <Newspaper className="mr-2" size={16} />
                                ) : (
                                  <FileText className="mr-2" size={16} />
                                )}
                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                                  {content.content_title}
                                </Typography>
                              </Box>
                            }
                            secondary={
                              <Box sx={{ mt: 0.5 }}>
                                {content.content_type === 'QUIZ' && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handleQuizClick(flow.id, content.content_id, item.flow_item_id)}
                                    sx={{ 
                                      color: 'white',
                                      borderColor: 'rgba(255, 255, 255, 0.3)',
                                      '&:hover': {
                                        borderColor: 'white'
                                      }
                                    }}
                                  >
                                    View Quiz Results
                                  </Button>
                                )}
                                <Chip
                                  size="small"
                                  label={content.is_completed ? 'Completed' : 'Pending'}
                                  color={content.is_completed ? 'success' : 'default'}
                                  sx={{ ml: 1 }}
                                />
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}

      <Dialog 
        open={Boolean(selectedQuiz)} 
        onClose={handleCloseQuizDialog}
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(18, 18, 18, 0.95)',
            backdropFilter: 'blur(10px)',
          }
        }}
      >
        <DialogTitle sx={{ color: 'white', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
          Quiz Attempts History
        </DialogTitle>
        <DialogContent>
          {quizAttempts.map((attempt) => (
            <Box 
              key={attempt.id}
              sx={{ 
                mt: 2,
                p: 2,
                borderRadius: 1,
                border: '1px solid rgba(255, 255, 255, 0.1)'
              }}
            >
              <Typography sx={{ color: 'white', fontWeight: 500, mb: 1 }}>
                Attempt {attempt.attempt_number}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  Score: {attempt.score}%
                </Typography>
                <Chip
                  label={attempt.passed ? 'Passed' : 'Failed'}
                  color={attempt.passed ? 'success' : 'error'}
                  size="small"
                />
              </Box>
              <List>
                {attempt.answer_submissions?.map((submission, index) => (
                  <ListItem key={index} sx={{ py: 0.5 }}>
                    <ListItemText
                      primary={
                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                          Question {index + 1}
                        </Typography>
                      }
                      secondary={
                        <Chip
                          size="small"
                          label={submission.is_correct ? 'Correct' : 'Incorrect'}
                          color={submission.is_correct ? 'success' : 'error'}
                          sx={{ mt: 0.5 }}
                        />
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
          <Button onClick={handleCloseQuizDialog} sx={{ color: 'white' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StudentProgress;