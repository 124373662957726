import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { BASE1_URL } from '../api';
import { styled } from '@mui/material/styles';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CommentIcon from '@mui/icons-material/Comment';
import PushPinIcon from '@mui/icons-material/PushPin';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import { ReactTinyLink } from 'react-tiny-link';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  listTeamUpdates,
  createTeamUpdate,
  likeTeamUpdate,
  commentOnTeamUpdate,
  pinUnpinTeamUpdate,
  deleteTeamUpdate,
  listUsers,
  createUpdateAttachment
} from '../api';









const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
 
  borderRadius: '10px',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  marginBottom: theme.spacing(2),
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const GlassModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: theme.palette.common.white,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: theme.palette.common.white,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.common.white,
  },
}));

const TeamUpdatesPage = () => {
  const [updates, setUpdates] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [managers, setManagers] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [newUpdate, setNewUpdate] = useState('');
  const [files, setFiles] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [links, setLinks] = useState([]);
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [newLink, setNewLink] = useState({ url: '', title: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [newComments, setNewComments] = useState({});

  const fetchUpdates = useCallback(async () => {
    try {
      const response = await listTeamUpdates();
      setUpdates(response.data);
    } catch (err) {
      console.error('Error fetching team updates:', err);
      setError('Failed to fetch team updates. Please try again.');
    }
  }, []);

  const fetchUsersByRole = useCallback(async (role, setterFunction) => {
    try {
      const response = await listUsers({ roles: [role], page_size: 100 }); // Increase page_size if you have more than 100 users per role
      setterFunction(response.results || []);
    } catch (err) {
      console.error(`Error fetching ${role}s:`, err);
      setterFunction([]);
    }
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      setError(null);
      try {
        await Promise.all([
          fetchUpdates(),
          fetchUsersByRole('ADMIN', setAdmins),
          fetchUsersByRole('MANAGER', setManagers),
          fetchUsersByRole('TRAINER', setTrainers),
        ]);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('An error occurred while fetching data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [fetchUpdates, fetchUsersByRole]);


  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    
    const encodedFiles = await Promise.all(selectedFiles.map(async (file) => {
      const base64 = await convertToBase64(file);
      return {
        file: base64,
        is_image: file.type.startsWith('image/'),
      };
    }));
    
    setPreviewFiles(encodedFiles);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleAddLink = () => {
    if (newLink.url && newLink.title) {
      setLinks([...links, newLink]);
      setNewLink({ url: '', title: '' });
      setLinkDialogOpen(false);
    }
  };

  const handleCreateUpdate = async () => {
    try {
      const updateData = {
        content: newUpdate,
      };
  
      const response = await createTeamUpdate(updateData, files, links);
      console.log('Create update response:', response);
  
      setNewUpdate('');
      setFiles([]);
      setPreviewFiles([]);
      setLinks([]);
      fetchUpdates();
      setSnackbar({ open: true, message: 'Update posted successfully!', severity: 'success' });
    } catch (err) {
      console.error('Error creating update:', err.response?.data || err.message);
      setSnackbar({ open: true, message: 'Failed to post update. Please try again.', severity: 'error' });
    }
  };

  const handleLike = async (updateId) => {
    try {
      await likeTeamUpdate(updateId);
      fetchUpdates();
    } catch (err) {
      console.error('Error liking update:', err);
      setSnackbar({ open: true, message: 'Failed to like update. Please try again.', severity: 'error' });
    }
  };

  const handleComment = async (updateId) => {
    const comment = newComments[updateId];
    if (!comment) return;

    try {
      await commentOnTeamUpdate(updateId, { content: comment });
      setNewComments(prev => ({ ...prev, [updateId]: '' }));
      fetchUpdates();
      setSnackbar({ open: true, message: 'Comment posted successfully!', severity: 'success' });
    } catch (err) {
      console.error('Error posting comment:', err);
      setSnackbar({ open: true, message: 'Failed to post comment. Please try again.', severity: 'error' });
    }
  };

  const handlePinUnpin = async (updateId) => {
    try {
      await pinUnpinTeamUpdate(updateId);
      fetchUpdates();
    } catch (err) {
      console.error('Error pinning/unpinning update:', err);
      setSnackbar({ open: true, message: 'Failed to pin/unpin update. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (updateId) => {
    try {
      await deleteTeamUpdate(updateId);
      fetchUpdates();
      setSnackbar({ open: true, message: 'Update deleted successfully.', severity: 'success' });
    } catch (err) {
      console.error('Error deleting update:', err);
      setSnackbar({ open: true, message: 'Failed to delete update. Please try again.', severity: 'error' });
    }
  };

  const LinkPreview = ({ url }) => {
    const ensureHttps = (url) => {
      if (!url.match(/^https?:\/\//i)) {
        return `https://${url}`;
      }
      return url;
    };
  
    const secureUrl = ensureHttps(url);
    
    return (
      <ReactTinyLink
        cardSize="small"
        showGraphic={true}
        maxLine={2}
        minLine={1}
        url={secureUrl}
        proxyUrl={BASE1_URL + '/api/cors-proxy/?url='}
        requestHeaders={{
          'Accept': '*/*',
          'User-Agent': 'Mozilla/5.0'
        }}
        defaultMedia="/api/placeholder/400/320"
      />
    );
  };

  const renderAttachments = (attachments) => {
    const images = attachments.filter(att => att.is_image);
    const videos = attachments.filter(att => !att.is_image);

    return (
      <>
        {images.length > 0 && (
          <ImageGallery
            items={images.map(att => ({
              original: att.file,
              thumbnail: att.file,
            }))}
            showPlayButton={false}
            showFullscreenButton={true}
            showNav={true}
          />
        )}
        {videos.map((video, index) => (
          <Box key={index} mb={2}>
            <video controls width="100%">
              <source src={video.file} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        ))}
      </>
    );
  };

  const renderUserList = (users, role) => {
    if (!users || users.length === 0) {
      return null;
    }

    return (
      <GlassCard>
        <Typography variant="h6" gutterBottom>
          {role}s
        </Typography>
        <List>
          {users.map((user) => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Avatar src={user.profile_image} alt={user.username}>
                  {user.full_name ? user.full_name.charAt(0) : ''}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={user.full_name || user.username}
                secondary={user.email}
                secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)' }}
              />
            </ListItem>
          ))}
        </List>
      </GlassCard>
    );
  };


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <>
  
      <GlassContainer  sx={{ paddingBottom: '400px'}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom color="white">
              Team Updates
            </Typography>
            <Box mb={4}>
              <StyledTextField
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                placeholder="What's on your mind?"
                value={newUpdate}
                onChange={(e) => setNewUpdate(e.target.value)}
              />
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <label htmlFor="file-input">
                    <GradientButton
                      component="span"
                      startIcon={<AttachFileIcon />}
                    >
                      Attach Files
                    </GradientButton>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    multiple
                    accept="*/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <GradientButton
                    startIcon={<LinkIcon />}
                    onClick={() => setLinkDialogOpen(true)}
                    sx={{ ml: 1 }}
                  >
                    Add Link
                  </GradientButton>
                </Box>
                <GradientButton
                  endIcon={<SendIcon />}
                  onClick={handleCreateUpdate}
                  disabled={!newUpdate.trim() && previewFiles.length === 0 && links.length === 0}
                >
                  Post Update
                </GradientButton>
              </Box>
            {previewFiles.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle2">
                  Attached files:
                </Typography>
                {previewFiles.map((file, index) => (
                  <Typography key={index} variant="body2">
                    {files[index].name} ({file.is_image ? 'Image' : 'File'})
                  </Typography>
                ))}
              </Box>
            )}
            {links.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle2">
                  Attached links:
                </Typography>
                {links.map((link, index) => (
                  <Typography key={index} variant="body2">
                    {link.title}: {link.url}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
          {updates.map((update) => (
              <GlassCard key={update.id}>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar src={update.author.profile_image} alt={update.author.username}>
                      {update.author.full_name.charAt(0)}
                    </Avatar>
                    <Box ml={2}>
                      <Typography variant="subtitle1">{update.author.full_name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {new Date(update.created_at).toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1" paragraph>
                    {update.content}
                  </Typography>
                {renderAttachments(update.attachments)}
                {update.links.map((link, index) => (
  <Box key={index} mb={2}>
    <LinkPreview url={link.url} />
  </Box>
))}
              </CardContent>
              
                <CardActions>
                  <IconButton onClick={() => handleLike(update.id)}>
                    <ThumbUpAltIcon color={update.likes > 0 ? 'primary' : 'inherit'} />
                  </IconButton>
                  <Typography variant="body2">{update.likes} Likes</Typography>
                  <IconButton>
                    <CommentIcon />
                  </IconButton>
                  <Typography variant="body2">{update.comments.length} Comments</Typography>
                  <IconButton onClick={() => handlePinUnpin(update.id)}>
                    <PushPinIcon color={update.is_pinned ? 'secondary' : 'inherit'} />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(update.id)}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
                <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
                <List>
                  {update.comments.map((comment) => (
                    <ListItem key={comment.id} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar src={comment.author.profile_image} alt={comment.author.username}>
                          {comment.author.full_name.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={comment.author.full_name}
                        secondary={
                          <>
                            <Typography component="span" variant="body2" color="textPrimary">
                              {comment.content}
                            </Typography>
                            <br />
                            <Typography component="span" variant="caption" color="textSecondary">
                              {new Date(comment.created_at).toLocaleString()}
                            </Typography>
                          </>
                        }
                        primaryTypographyProps={{ color: 'white' }}
                        secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)' }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Box p={2}>
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    placeholder="Write a comment..."
                    value={newComments[update.id] || ''}
                    onChange={(e) => setNewComments(prev => ({ ...prev, [update.id]: e.target.value }))}
                  />
                  <GradientButton
                    endIcon={<SendIcon />}
                    onClick={() => handleComment(update.id)}
                    disabled={!newComments[update.id]}
                    sx={{ mt: 1 }}
                  >
                    Post Comment
                  </GradientButton>
                </Box>
              </GlassCard>
            ))}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" gutterBottom color="white">
              Team Members
            </Typography>
            {renderUserList(admins, 'Admin')}
            {renderUserList(managers, 'Manager')}
            {renderUserList(trainers, 'Trainer')}
          </Grid>
        </Grid>

        <GlassModal open={linkDialogOpen} onClose={() => setLinkDialogOpen(false)}>
          <DialogTitle>Add Link</DialogTitle>
          <DialogContent>
            <StyledTextField
              autoFocus
              margin="dense"
              label="URL"
              type="url"
              fullWidth
              value={newLink.url}
              onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
            />
            <StyledTextField
              margin="dense"
              label="Title"
              type="text"
              fullWidth
              value={newLink.title}
              onChange={(e) => setNewLink({ ...newLink, title: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setLinkDialogOpen(false)}>Cancel</GradientButton>
            <GradientButton onClick={handleAddLink}>Add</GradientButton>
          </DialogActions>
        </GlassModal>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            sx={{ 
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              '& .MuiAlert-icon': {
                color: 'white'
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </GlassContainer>
    </>
  );
};

export default TeamUpdatesPage;