import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Alert,
  CircularProgress,
  Chip,
  Checkbox,
  Autocomplete,
  styled,
  InputAdornment,
  Tooltip
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
  Search as SearchIcon,
  Category as CategoryIcon,
  VideoLibrary as VideoIcon,
  TextFields as TextIcon,
  Quiz as QuizIcon
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Styled Components
const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GlassChip = styled(Chip)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.common.white,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
}));

const DraggableListItem = styled(ListItem)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '8px',
  marginBottom: theme.spacing(1),
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const ItemsManagement = ({ 
  items, 
  contents,
  categories,
  onItemCreate, 
  onItemUpdate, 
  onItemDelete,
  onRefresh 
}) => {
  // State
  const [openDialog, setOpenDialog] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    selectedContents: [],
    category_ids: []
  });

  // Filtered Items
  const filteredItems = useMemo(() => {
    return items.filter(item => {
      const matchesSearch = 
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesCategories = 
        selectedCategories.length === 0 ||
        selectedCategories.every(catId => 
          item.categories?.some(cat => cat.id === catId)
        );

      return matchesSearch && matchesCategories;
    });
  }, [items, searchTerm, selectedCategories]);

  // Reset form
  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      selectedContents: [],
      category_ids: []
    });
    setEditingItem(null);
    setError(null);
  };

  // Dialog handlers
  const handleOpen = () => {
    resetForm();
    setOpenDialog(true);
  };

  const handleClose = () => {
    resetForm();
    setOpenDialog(false);
  };

  // Edit handler
  const handleEdit = (item) => {
    setEditingItem(item);
    setFormData({
      title: item.title,
      description: item.description,
      selectedContents: item.item_contents?.map(ic => ({
        id: ic.content.id,
        order: ic.order
      })) || [],
      category_ids: item.categories?.map(cat => cat.id) || []
    });
    setOpenDialog(true);
  };

  // Form submission
  const handleSubmit = async () => {
    try {
      if (!formData.title.trim()) {
        setError('Title is required');
        return;
      }
  
      setLoading(true);
      setError(null);
  
      const itemData = {
        title: formData.title.trim(),
        description: formData.description.trim(),
        contents: formData.selectedContents.map((content, index) => ({
          content_id: content.id,
          order: index + 1
        })),
        category_ids: formData.category_ids // Make sure this is included
      };
  
      console.log('Submitting item data:', itemData); // Add logging
  
      if (editingItem) {
        await onItemUpdate(editingItem.id, itemData);
      } else {
        await onItemCreate(itemData);
      }
  
      handleClose();
      if (onRefresh) {
        onRefresh();
      }
    } catch (error) {
      console.error('Error saving item:', error);
      setError(error.response?.data?.error || 'Error saving item');
    } finally {
      setLoading(false);
    }
  };

  // Content handlers
  const handleContentToggle = (content) => {
    setFormData(prev => {
      const currentContents = [...prev.selectedContents];
      const index = currentContents.findIndex(c => c.id === content.id);
      
      if (index === -1) {
        return {
          ...prev,
          selectedContents: [
            ...currentContents,
            { id: content.id, order: currentContents.length + 1 }
          ]
        };
      } else {
        return {
          ...prev,
          selectedContents: currentContents
            .filter((_, i) => i !== index)
            .map((content, index) => ({
              ...content,
              order: index + 1
            }))
        };
      }
    });
  };

  // Drag and drop handler
  const onDragEnd = (result) => {
    if (!result.destination) return;

    setFormData(prev => {
      const items = Array.from(prev.selectedContents);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      return {
        ...prev,
        selectedContents: items.map((item, index) => ({
          ...item,
          order: index + 1
        }))
      };
    });
  };

  // Content type icon component
  const ContentTypeIcon = ({ type }) => {
    switch (type) {
      case 'VIDEO':
        return <VideoIcon color="primary" />;
      case 'TEXT':
        return <TextIcon color="success" />;
      case 'QUIZ':
        return <QuizIcon color="warning" />;
      default:
        return null;
    }
  };

  // Category display component
  const CategoryDisplay = ({ categories }) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {categories?.map((category) => (
        <GlassChip
          key={category.id}
          label={category.name}
          size="small"
          icon={<CategoryIcon sx={{ fontSize: 16 }} />}
          variant="outlined"
        />
      ))}
    </Box>
  );

  // Continue ItemsManagement component...

  return (
    <Box>
      {/* Header and Controls */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography variant="h6" sx={{ color: 'white' }}>
          Items Management
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Add Item
        </Button>
      </Box>

      {/* Search and Filters */}
      <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          placeholder="Search items..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />

        <Autocomplete
          multiple
          options={categories}
          getOptionLabel={(option) => option.name}
          value={categories.filter(cat => selectedCategories.includes(cat.id))}
          onChange={(_, newValue) => {
            setSelectedCategories(newValue.map(cat => cat.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Categories"
              placeholder="Select categories"
            />
          )}
          sx={{ minWidth: 300 }}
        />
      </Box>

      {/* Error Alert */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {/* Items Table */}
      <GlassTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Contents</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {item.item_contents?.map((content) => (
                      <Tooltip 
                        key={content.content.id}
                        title={content.content.title}
                      >
                        <GlassChip
                          icon={<ContentTypeIcon type={content.content.content_type} />}
                          label={`${content.content.content_type}`}
                          size="small"
                        />
                      </Tooltip>
                    ))}
                  </Box>
                </TableCell>
                <TableCell>
                  <CategoryDisplay categories={item.categories} />
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(item)} sx={{ color: 'info.main' }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton 
                      onClick={() => onItemDelete(item.id)}
                      sx={{ color: 'error.main' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </GlassTableContainer>

      {/* Create/Edit Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            background: 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            color: 'white'
          }
        }}
      >
        <DialogTitle>
          {editingItem ? 'Edit Item' : 'Create New Item'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            {/* Basic Info */}
            <TextField
              label="Title"
              value={formData.title}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                title: e.target.value 
              }))}
              required
              fullWidth
              error={!formData.title.trim()}
              helperText={!formData.title.trim() ? 'Title is required' : ''}
            />
            
            <TextField
              label="Description"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                description: e.target.value 
              }))}
              multiline
              rows={3}
              fullWidth
            />

            {/* Category Selection */}
            <Autocomplete
  multiple
  options={categories}
  getOptionLabel={(option) => option.name}
  value={categories.filter(cat => formData.category_ids.includes(cat.id))}
  onChange={(_, newValue) => {
    const newCategoryIds = newValue.map(category => category.id);
    setFormData(prev => ({
      ...prev,
      category_ids: newCategoryIds
    }));
  }}
  isOptionEqualToValue={(option, value) => option.id === value.id}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Categories"
      placeholder="Select categories"
    />
  )}
  renderTags={(value, getTagProps) =>
    value.map((option, index) => (
      <GlassChip
        key={option.id}
        label={option.name}
        {...getTagProps({ index })}
        icon={<CategoryIcon sx={{ fontSize: 16 }} />}
      />
    ))
  }
/>
            {/* Selected Contents */}
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Selected Contents (Drag to reorder)
            </Typography>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="selected-contents">
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {formData.selectedContents.map((content, index) => {
                      const contentDetails = contents.find(c => c.id === content.id);
                      return (
                        <Draggable
                          key={content.id}
                          draggableId={content.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <DraggableListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ListItemIcon>
                                <DragIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={contentDetails?.title}
                                secondary={
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <ContentTypeIcon type={contentDetails?.content_type} />
                                    {`Order: ${index + 1}`}
                                  </Box>
                                }
                              />
                              <IconButton 
                                onClick={() => handleContentToggle(content)}
                                sx={{ color: 'error.main' }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </DraggableListItem>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>

            {/* Available Contents */}
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Available Contents
            </Typography>

            <List sx={{ 
              maxHeight: 300, 
              overflow: 'auto',
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: 1,
              p: 1
            }}>
              {contents
                .filter(content => !formData.selectedContents.find(sc => sc.id === content.id))
                .map((content) => (
                  <DraggableListItem key={content.id}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        onChange={() => handleContentToggle(content)}
                        checked={false}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={content.title}
                      secondary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <ContentTypeIcon type={content.content_type} />
                          {content.content_type}
                          {content.categories?.map(cat => (
                            <GlassChip
                              key={cat.id}
                              label={cat.name}
                              size="small"
                              variant="outlined"
                            />
                          ))}
                        </Box>
                      }
                    />
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => handleContentToggle(content)}
                    >
                      Add
                    </Button>
                  </DraggableListItem>
                ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: 'white' }}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              editingItem ? 'Update' : 'Create'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ItemsManagement;