import React, { useState, useEffect } from 'react';
import { 
  Button, 
  Box, 
  Tabs, 
  Tab, 
  Typography,
  Paper,
  Alert,
  CircularProgress,
  Dialog,
  styled
} from '@mui/material';
import {
  listSyllabusContents,
  listSyllabusItems,
  listCourseFlows,
  listCategories,
  createSyllabusContent,
  createSyllabusItem,
  createCourseFlow,
  updateSyllabusContent,
  updateSyllabusItem,
  updateCourseFlow,
  deleteSyllabusContent,
  deleteSyllabusItem,
  deleteCourseFlow,
  bulkCreateQuizQuestions,
  assignCourseFlowToStudent,
  createCategory,
  updateCategory,
  deleteCategory, createQuiz
} from '../api';

import ContentManagement from '../components/CourseManagement/ContentManagement';
import ItemsManagement from '../components/CourseManagement/ItemsManagement';
import CourseFlowManagement from '../components/CourseManagement/CourseFlowManagement';
import QuizManagement from '../components/CourseManagement/QuizManagement';
import AssignmentManagement from '../components/CourseManagement/AssignmentManagement';
import CategoryManagement from '../components/CategoryManagement';

// Styled Components
const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GlassTab = styled(Tab)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  }
}));

const GlassPaper = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const CourseManagementPage = () => {
  // State Management
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Data states
  const [contents, setContents] = useState([]);
  const [items, setItems] = useState([]);
  const [courseFlows, setCourseFlows] = useState([]);
  const [categories, setCategories] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Load initial data
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const [contentsRes, itemsRes, flowsRes, categoriesRes] = await Promise.all([
          listSyllabusContents(),
          listSyllabusItems(),
          listCourseFlows(),
          listCategories()
        ]);
        
        setContents(contentsRes.data);
        setItems(itemsRes.data);
        setCourseFlows(flowsRes.data);
        setCategories(categoriesRes.data);
      } catch (err) {
        setError('Failed to load course management data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [refreshTrigger]);

  // Tab Management
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const refreshData = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  // Notification Handlers
  const showSuccess = (message) => {
    setSuccess(message);
    setTimeout(() => setSuccess(null), 3000);
  };

  const showError = (message) => {
    setError(message);
    setTimeout(() => setError(null), 3000);
  };

  // Content Management Handlers
  const handleContentCreate = async (contentData) => {
    setLoading(true);
    try {
      await createSyllabusContent(contentData);
      showSuccess('Content created successfully');
      refreshData();
    } catch (err) {
      showError('Failed to create content');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleContentUpdate = async (id, contentData) => {
    setLoading(true);
    try {
      await updateSyllabusContent(id, contentData);
      showSuccess('Content updated successfully');
      refreshData();
    } catch (err) {
      showError('Failed to update content');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleContentDelete = async (id) => {
    setLoading(true);
    try {
      await deleteSyllabusContent(id);
      showSuccess('Content deleted successfully');
      refreshData();
    } catch (err) {
      showError('Failed to delete content');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Item Management Handlers
  const handleItemCreate = async (itemData) => {
    setLoading(true);
    try {
      const response = await createSyllabusItem({
        title: itemData.title,
        description: itemData.description,
        contents: itemData.contents || [],
        category_ids: itemData.category_ids || []
      });
      showSuccess('Item created successfully');
      refreshData();
      return response;
    } catch (err) {
      showError('Failed to create item');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleItemUpdate = async (id, itemData) => {
    setLoading(true);
    try {
      await updateSyllabusItem(id, itemData);
      showSuccess('Item updated successfully');
      refreshData();
    } catch (err) {
      showError('Failed to update item');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleItemDelete = async (id) => {
    setLoading(true);
    try {
      await deleteSyllabusItem(id);
      showSuccess('Item deleted successfully');
      refreshData();
    } catch (err) {
      showError('Failed to delete item');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Course Flow Management Handlers
  const handleFlowCreate = async (flowData) => {
    setLoading(true);
    try {
      await createCourseFlow({
        ...flowData,
        category_ids: flowData.category_ids || []
      });
      showSuccess('Course flow created successfully');
      refreshData();
    } catch (err) {
      showError('Failed to create course flow');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  const handleFlowUpdate = async (id, flowData) => {
    setLoading(true);
    try {
      await updateCourseFlow(id, flowData);
      showSuccess('Course flow updated successfully');
      refreshData();
    } catch (err) {
      showError('Failed to update course flow');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFlowDelete = async (id) => {
    setLoading(true);
    try {
      await deleteCourseFlow(id);
      showSuccess('Course flow deleted successfully');
      refreshData();
    } catch (err) {
      showError('Failed to delete course flow');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Quiz Management Handlers
  const handleQuizCreate = async (quizData) => {
    try {
      const quizId = await createQuiz(quizData); // Now receiving just the ID
      refreshData(); // Refresh the quiz list
      return quizId;
    } catch (error) {
      console.error('Failed to create quiz:', error);
      throw error;
    }
  };

  // Assignment Management Handlers
  const handleCourseAssignment = async (flowId, studentId) => {
    setLoading(true);
    try {
      await assignCourseFlowToStudent(flowId, studentId);
      showSuccess('Course assigned successfully');
      refreshData();
    } catch (err) {
      showError('Failed to assign course');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Category Management Handlers
  const handleCategoryCreate = async (data) => {
    setLoading(true);
    try {
      await createCategory(data);
      showSuccess('Category created successfully');
      refreshData();
    } catch (err) {
      showError('Failed to create category');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onQuizCreate = async (quizContentData) => {
    try {
      const response = await createQuiz(quizContentData);
      refreshData(); // Refresh the quiz list
      return response; // Return the full response object
    } catch (error) {
      console.error('Error creating quiz:', error);
      throw error;
    }
  };
  const handleCategoryUpdate = async (id, data) => {
    setLoading(true);
    try {
      await updateCategory(id, data);
      showSuccess('Category updated successfully');
      refreshData();
    } catch (err) {
      showError('Failed to update category');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryDelete = async (id) => {
    setLoading(true);
    try {
      await deleteCategory(id);
      showSuccess('Category deleted successfully');
      refreshData();
    } catch (err) {
      showError('Failed to delete category');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GlassContainer  sx={{ paddingBottom: '400px'}}>
      <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
        Course Management
      </Typography>

      {(success || error) && (
        <Box sx={{ mb: 2 }}>
          {success && <Alert severity="success">{success}</Alert>}
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
      )}

      <GlassPaper sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <GlassTab label="Content" />
          <GlassTab label="Items" />
          <GlassTab label="Course Flows" />
          <GlassTab label="Quizzes" />
          <GlassTab label="Assignments" />
          <GlassTab label="Categories" />
        </Tabs>
      </GlassPaper>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        {activeTab === 0 && (
          <ContentManagement 
            contents={contents}
            categories={categories}
            onContentCreate={handleContentCreate}
            onContentUpdate={handleContentUpdate}
            onContentDelete={handleContentDelete}
          />
        )}
        {activeTab === 1 && (
          <ItemsManagement 
            items={items}
            contents={contents}
            categories={categories}
            onItemCreate={handleItemCreate}
            onItemUpdate={handleItemUpdate}
            onItemDelete={handleItemDelete}
            onRefresh={refreshData}
          />
        )}
        {activeTab === 2 && (
          <CourseFlowManagement 
            courseFlows={courseFlows}
            items={items}
            categories={categories}
            onFlowCreate={handleFlowCreate}
            onFlowUpdate={handleFlowUpdate}
            onFlowDelete={handleFlowDelete}
          />
        )}
        {activeTab === 3 && (
          <QuizManagement 
            contents={contents.filter(c => c.content_type === 'QUIZ')}
            categories={categories}
            onQuizCreate={handleQuizCreate}
            onContentUpdate={handleContentUpdate}
            onContentDelete={handleContentDelete}
          />
        )}
        {activeTab === 4 && (
          <AssignmentManagement 
            courseFlows={courseFlows}
            onAssign={handleCourseAssignment}
          />
        )}
        {activeTab === 5 && (
          <CategoryManagement 
            categories={categories}
            onRefresh={refreshData}
            onCategoryCreate={handleCategoryCreate}
            onCategoryUpdate={handleCategoryUpdate}
            onCategoryDelete={handleCategoryDelete}
          />
        )}
      </Box>
    </GlassContainer>
  );
};

export default CourseManagementPage;