import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Badge,
  Tooltip,
  Popper,
  Paper,
  ClickAwayListener,
  Grow,
  Drawer,
  Divider,
  AppBar,
  Toolbar,
  useMediaQuery,
  Tabs,
  Tab,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BookIcon from '@mui/icons-material/Book';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GroupIcon from '@mui/icons-material/Group';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SchoolIcon from '@mui/icons-material/School';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { SitemarkIcon } from '../CustomIcons';
import NotificationItem from './NotificationItem';
import { getTrainerTransitionNotifications } from '../api';
import { keyframes } from '@emotion/react';

import { listNotifications, markNotificationAsRead } from '../api';

const DRAWER_WIDTH = 300;
const COLLAPSED_DRAWER_WIDTH = 64;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  boxShadow: 'none',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const DesktopHeader = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    display: open ? 'none' : 'flex',
    position: 'fixed',
    left: COLLAPSED_DRAWER_WIDTH,
    top: 0,
    height: 64,
    alignItems: 'center',
    padding: theme.spacing(2),
    zIndex: theme.zIndex.drawer - 1,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
);

const DesktopDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      width: open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      background: 'rgba(255, 255, 255, 0.05)',
      backdropFilter: 'blur(10px)',
      borderRight: '1px solid rgba(255, 255, 255, 0.1)',
      overflowX: 'hidden',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '& .MuiListItem-root:hover .expand-button': {
      opacity: 1,
    },
  }),
);

const MobileDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '85%',
    background: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(10px)',
    borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  minHeight: 64,
  
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  margin: '4px 8px',
  borderRadius: '8px',
  color: 'white',
  background: selected ? 'rgba(255, 255, 255, 0.15)' : 'transparent',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiListItemIcon-root': {
    color: 'white',
    minWidth: 40,
  },
}));

const LogoContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    display: 'flex',
    alignItems: 'center',
    opacity: open ? 1 : 0,
    transition: theme.transitions.create(['opacity', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: open ? 'auto' : 0,
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
  })
);

// Modified to support proper desktop positioning
const NotificationPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
  width: 360,
  maxHeight: '70vh',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '95vw',
    position: 'fixed',
    left: '50% !important',
    transform: 'translateX(-50%) !important',
    top: '80px !important',
  },
}));

const NotificationPaper = styled(Paper)(({ theme }) => ({
  background: 'rgba(25, 25, 25, 0.95)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

const NotificationList = styled(List)(({ theme }) => ({
  maxHeight: '50vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
  },
}));

const pulse = keyframes`
  0% { transform: scale(1); opacity: 0.7; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 0.7; }
`;

const TransitionDot = styled('div')({
  position: 'absolute',
  top: -2,
  right: -2,
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: '#FF4444',
  animation: `${pulse} 2s infinite ease-in-out`
});

const TransitionTooltip = styled(Tooltip)({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'rgba(255, 68, 68, 0.9)',
    color: 'white',
    fontSize: '0.875rem',
    padding: '8px 12px',
    borderRadius: '4px',
    maxWidth: 'none'
  }
});

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, isMobile }) => ({
    flexGrow: 1,
    marginLeft: isMobile ? 0 : (open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH),
    // Only add top padding for mobile view
    paddingTop: isMobile ? 32 : 0,
   
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
);

// Added ExpandButton component
const ExpandButton = styled(IconButton)(({ theme, open }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  color: 'white',
  // Remove opacity transition and keep button always visible
  opacity: 1,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const menuItems = [
  { text: 'Dashboard', icon: <DashboardIcon />, link: '/', roles: ['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'] },
  { text: 'Students', icon: <PeopleIcon />, link: '/students', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Trainers', icon: <SchoolIcon />, link: '/trainers', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Courses', icon: <BookIcon />, link: '/courses', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Coordinators', icon: <SupervisorAccountIcon />, link: '/coordinators', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Team Updates', icon: <GroupIcon />, link: '/team-updates', roles: ['ADMIN', 'MANAGER', 'TRAINER'] },
  { text: 'Class Recordings', icon: <VideoLibraryIcon />, link: '/recordings', roles: ['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'] },
  { text: 'My Employment', icon: <WorkIcon />, link: '/my-employment', roles: ['TRAINER'] },
  { text: 'Allotment', icon: <WorkIcon />, link: '/allotment', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Course Completion', icon: <AssignmentTurnedInIcon />, link: '/course-completion', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Competitions', icon: <EmojiEventsIcon />, link: '/competitions', roles: ['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'] },
  { text: 'Syllabus', icon: <SupervisorAccountIcon />, link: '/course-management', roles: ['ADMIN', 'MANAGER'] },
];

const TopNavBar = ({ userRole, onLogout, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [desktopOpen, setDesktopOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notifications, setNotifications] = useState({ unread: [], read: [] });
  const [notificationPopperOpen, setNotificationPopperOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [hasTransitionNotifications, setHasTransitionNotifications] = useState(false);
  const location = useLocation();
  const notificationIconRef = useRef(null);
  const desktopNotificationIconRef = useRef(null);

  const filteredMenuItems = menuItems.filter(item => item.roles.includes(userRole));

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await listNotifications();
        const allNotifications = Array.isArray(response.data) ? response.data : [];
        setNotifications({
          unread: allNotifications.filter(notification => !notification.is_read),
          read: allNotifications.filter(notification => notification.is_read)
        });
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const checkTransitions = async () => {
      try {
        const response = await getTrainerTransitionNotifications();
        setHasTransitionNotifications(response.data.length > 0);
      } catch (error) {
        console.error('Error checking trainer transitions:', error);
      }
    };

    checkTransitions();
    const intervalId = setInterval(checkTransitions, 300000);
    return () => clearInterval(intervalId);
  }, []);

  const handleDesktopDrawerToggle = () => {
    setDesktopOpen(!desktopOpen);
  };

  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleNotificationClick = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
    setNotificationPopperOpen((prev) => !prev);
  };

  const handleNotificationRead = async (id) => {
    const notification = notifications.unread.find(n => n.id === id);
    if (notification) {
      try {
        await markNotificationAsRead(id);
        setNotifications(prevNotifications => ({
          unread: prevNotifications.unread.filter(n => n.id !== id),
          read: [...prevNotifications.read, notification]
        }));
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const drawerContent = (isDesktop = false) => (
    <>
      <DrawerHeader>
        <LogoContainer open={!isDesktop || desktopOpen}>
          <SitemarkIcon sx={{ height: 32, width: 'auto' }} />
        </LogoContainer>
        {isDesktop && (
          <ExpandButton
            onClick={handleDesktopDrawerToggle}
            className={!desktopOpen || 'visible'}
          >
            {desktopOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ExpandButton>
        )}
      </DrawerHeader>
      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
      <List>
        {filteredMenuItems.map((item) => {
          const isCourseCompletion = item.link === '/course-completion';
          
          return (
            <TransitionTooltip
              key={item.text}
              title={
                isCourseCompletion && hasTransitionNotifications
                  ? "Trainers are getting free soon! Click to view details"
                  : (isDesktop && !desktopOpen ? item.text : '')
              }
              placement="right"
              arrow
              open={isCourseCompletion && hasTransitionNotifications ? undefined : false}
            >
              <StyledListItem
                button
                component={Link}
                to={item.link}
                selected={location.pathname === item.link}
                onClick={handleMenuItemClick}
                sx={{ position: 'relative' }}
              >
                <ListItemIcon>
                  {item.icon}
                  {isCourseCompletion && hasTransitionNotifications && (
                    <TransitionDot />
                  )}
                </ListItemIcon>
                {(!isDesktop || desktopOpen) && <ListItemText primary={item.text} />}
              </StyledListItem>
            </TransitionTooltip>
          );
        })}
      </List>
      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)', mt: 'auto' }} />
      <List>
        <StyledListItem
          button
          onClick={handleNotificationClick}
          ref={isDesktop ? desktopNotificationIconRef : notificationIconRef}
        >
          <ListItemIcon>
            <Badge badgeContent={notifications.unread.length} color="secondary">
              <NotificationsIcon />
            </Badge>
          </ListItemIcon>
          {(!isDesktop || desktopOpen) && <ListItemText primary="Notifications" />}
        </StyledListItem>
        <StyledListItem button onClick={onLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          {(!isDesktop || desktopOpen) && <ListItemText primary="Logout" />}
        </StyledListItem>
      </List>
    </>
  );

  

  return (
    <Box sx={{ display: 'flex' }}>
      {!desktopOpen && (
      <DesktopHeader open={desktopOpen}>
        <SitemarkIcon sx={{ height: 32, width: 'auto' }} />
      </DesktopHeader>
    )}

      <StyledAppBar position="fixed">
        <Toolbar sx={{ width: '100%', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SitemarkIcon sx={{ height: 32, width: 'auto',mr: 1 }} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              onClick={handleNotificationClick}
              sx={{ mr: 1 }}
              ref={notificationIconRef}
            >
              <Badge badgeContent={notifications.unread.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton 
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleMobileDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </StyledAppBar>

      <DesktopDrawer 
        variant="permanent" 
        open={desktopOpen}
      >
        {drawerContent(true)}
      </DesktopDrawer>

      <MobileDrawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawerContent(false)}
      </MobileDrawer>

      <NotificationPopper
        open={notificationPopperOpen}
        anchorEl={isMobile ? notificationIconRef.current : desktopNotificationIconRef.current}
        placement={isMobile ? "bottom" : "right-start"}
        transition
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: window,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <NotificationPaper>
              <ClickAwayListener onClickAway={() => setNotificationPopperOpen(false)}>
                <Box>
                  <Box sx={{ px: 2, pb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      Notifications
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => setNotificationPopperOpen(false)}
                      sx={{ color: 'white' }}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>
                  
                  <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange} 
                    centered
                    sx={{
                      borderBottom: 1,
                      borderColor: 'rgba(255, 255, 255, 0.1)',
                      mb: 2,
                      '& .MuiTab-root': {
                        color: 'rgba(255, 255, 255, 0.7)',
                        '&.Mui-selected': {
                          color: 'white',
                        },
                      },
                      '& .MuiTabs-indicator': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    <Tab 
                      label={`Unread (${notifications.unread.length})`}
                      sx={{ 
                        textTransform: 'none',
                        fontSize: '0.9rem',
                      }} 
                    />
                    <Tab 
                      label={`Read (${notifications.read.length})`}
                      sx={{ 
                        textTransform: 'none',
                        fontSize: '0.9rem',
                      }}
                    />
                  </Tabs>

                  <NotificationList>
                    <Box sx={{ px: 2 }}>
                      {activeTab === 0 ? (
                        notifications.unread.length === 0 ? (
                          <Box sx={{ py: 4, textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgba(255,255,255,0.6)' }}>
                              No unread notifications
                            </Typography>
                          </Box>
                        ) : (
                          notifications.unread.map((notification) => (
                            <NotificationItem
                              key={notification.id}
                              notification={notification}
                              onMarkRead={handleNotificationRead}
                            />
                          ))
                        )
                      ) : (
                        notifications.read.length === 0 ? (
                          <Box sx={{ py: 4, textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgba(255,255,255,0.6)' }}>
                              No read notifications
                            </Typography>
                          </Box>
                        ) : (
                          notifications.read.map((notification) => (
                            <NotificationItem
                              key={notification.id}
                              notification={notification}
                            />
                          ))
                        )
                      )}
                    </Box>
                  </NotificationList>
                </Box>
              </ClickAwayListener>
            </NotificationPaper>
          </Grow>
        )}
      </NotificationPopper>

      <Main open={desktopOpen} isMobile={isMobile}>
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      </Main>
    </Box>
  );
};

TopNavBar.propTypes = {
  userRole: PropTypes.oneOf(['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT']).isRequired,
  onLogout: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default React.memo(TopNavBar);