import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Avatar, 
  Paper, 
  useTheme,
  Pagination,
  CircularProgress,
  Stack,
  IconButton,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getImageUrl, getUserCompetitionRankings } from '../api';
import {
  EmojiEvents as TrophyIcon,
  AccessTime as TimeIcon,
  Group as GroupIcon,
  Star as StarIcon,
  History as HistoryIcon,
  AccountCircle as ProfileIcon,
  ErrorOutline as ErrorIcon
} from '@mui/icons-material';

// Styled Components
const LeaderboardContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  color: theme.palette.common.white,
  minHeight: '600px',
  width: '100%',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '@media (max-width: 600px)': {
    padding: theme.spacing(2),
  },
}));

const LeaderboardHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'left',
  '@media (max-width: 600px)': {
    marginBottom: theme.spacing(2),
  },
}));

const TopThreeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  position: 'relative',
  marginBottom: theme.spacing(6),
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

const MobileTopPlayer = styled(Box)(({ theme, position }) => ({
  display: 'none',
  '@media (max-width: 600px)': {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: position === 1 
      ? 'linear-gradient(180deg, #1e3a8a 0%, #1e40af 100%)' 
      : 'rgba(255, 255, 255, 0.05)',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    border: position === 1 ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const PodiumCard = styled(Paper)(({ position, theme }) => ({
  background: position === 1 
    ? 'linear-gradient(180deg, #1e3a8a 0%, #1e40af 100%)' 
    : 'rgba(255, 255, 255, 0.05)',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  position: 'relative',
  minHeight: position === 1 ? 280 : 240,
  justifyContent: 'center',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  '@media (max-width: 600px)': {
    display: 'none',
  },
}));

const PlayerAvatar = styled(Avatar)(({ size = 80, theme }) => ({
  width: size,
  height: size,
  border: '4px solid rgba(255, 255, 255, 0.2)',
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 14px rgba(0, 0, 0, 0.25)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  '@media (max-width: 600px)': {
    width: size * 0.75,
    height: size * 0.75,
    marginBottom: 0,
    border: '2px solid rgba(255, 255, 255, 0.2)',
  },
}));

const RankNumber = styled(Box)(({ color = 'primary', theme }) => ({
  background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : color,
  borderRadius: '50%',
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.25rem',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  marginBottom: theme.spacing(1),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
  '@media (max-width: 600px)': {
    width: 30,
    height: 30,
    fontSize: '1rem',
    marginBottom: 0,
  },
}));

const PlayersList = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  margin: '0 auto',
}));

const PlayerRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  '&:last-child': {
    borderBottom: 'none',
  },
  '@media (max-width: 600px)': {
    padding: theme.spacing(1.5),
    gap: theme.spacing(1),
  },
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: theme.shape.borderRadius,
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  '@media (max-width: 600px)': {
    padding: theme.spacing(1.5),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const StatItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '@media (max-width: 600px)': {
    width: '100%',
    justifyContent: 'space-between',
  },
}));

const PaginationContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  '& .MuiPagination-ul': {
    '& .MuiPaginationItem-root': {
      color: theme.palette.common.white,
      borderColor: 'rgba(255, 255, 255, 0.2)',
      '&.Mui-selected': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
      },
    }
  },
  '@media (max-width: 600px)': {
    marginTop: theme.spacing(2),
  },
}));

const ErrorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  textAlign: 'center',
  gap: theme.spacing(2),
}));

const CompetitionLeaderboard = ({ 
  leaderboardData, 
  competition, 
  currentUser,
  onPageChange,
  currentPage = 1,
  totalPages = 1,
  loading = false
}) => {
  const theme = useTheme();
  const [displayData, setDisplayData] = useState({
    topThree: [],
    paginatedEntries: [],
    total: 0
  });
  const [error, setError] = useState(null);
  const [studentRankings, setStudentRankings] = useState(null);
  const [showRankingHistory, setShowRankingHistory] = useState(false);
  const [currentCompRanking, setCurrentCompRanking] = useState(null);
  const itemsPerPage = 20;

  useEffect(() => {
    if (leaderboardData?.results) {
      organizeLeaderboardData(leaderboardData);
    }
  }, [leaderboardData]);

  useEffect(() => {
    if (currentUser && competition?.id) {
      fetchStudentRankings();
    }
  }, [currentUser, competition?.id]);

  const fetchStudentRankings = async () => {
    try {
      if (!currentUser?.id) return;
      
      const response = await getUserCompetitionRankings(currentUser.id);
      setStudentRankings(response.data);
      
      const currentRanking = response.data?.active_competitions?.find(
        comp => comp.competition_id === competition?.id
      );
      
      if (currentRanking) {
        setCurrentCompRanking(currentRanking);
      }
    } catch (error) {
      console.error('Error fetching student rankings:', error);
      setError('Failed to fetch student rankings');
    }
  };

  const organizeLeaderboardData = (data) => {
    if (!data?.results?.length) return;

    try {
      // Always keep top 3 from the first page results
      const topThree = data.results
        .filter(entry => entry.rank <= 3)
        .sort((a, b) => a.rank - b.rank);

      // Get remaining entries, excluding any that are in top 3
      const paginatedEntries = data.results
        .filter(entry => entry.rank > 3);

      setDisplayData({
        topThree,
        paginatedEntries,
        total: data.count || 0
      });
      setError(null);
    } catch (error) {
      console.error('Error organizing leaderboard data:', error);
      setError('Failed to organize leaderboard data');
    }
  };

  const handlePageChange = (event, newPage) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return '--:--';
    const [hours, minutes, seconds] = timeString.split(':');
    return `${minutes}:${seconds.split('.')[0]}`;
  };

  const getProfileImageUrl = (student) => {
    if (!student?.profile_image) return null;
    return getImageUrl(student.profile_image);
  };

  const RankingHistoryDrawer = () => (
    <Drawer 
      anchor="right" 
      open={showRankingHistory} 
      onClose={() => setShowRankingHistory(false)}
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: 350 },
          bgcolor: 'rgba(0, 0, 0, 0.9)',
          color: 'white',
          p: 2,
          backdropFilter: 'blur(10px)',
        }
      }}
    >
      <Typography variant="h6" gutterBottom>Your Competition History</Typography>
      {studentRankings ? (
        <>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)">
              Total Competitions Participated: {studentRankings.total_competitions_participated}
            </Typography>
          </Box>
          
          {studentRankings.active_competitions.length > 0 && (
            <>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Active Competitions</Typography>
              <List>
                {studentRankings.active_competitions.map((comp, index) => (
                  <ListItem key={index} sx={{ mb: 1, bgcolor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <ListItemText
                      primary={comp.competition_title}
                      secondary={
                        <Box sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          <Typography variant="body2">
                            Rank: #{comp.rank} of {comp.total_participants}
                          </Typography>
                          <Typography variant="body2">
                            Score: {comp.score}
                          </Typography>
                          <Typography variant="body2">
                            Time: {formatTime(comp.completion_time)}
                          </Typography>
                        </Box>
                      }
                    />
                    <Chip 
                      label={`Top ${Math.round((comp.rank / comp.total_participants) * 100)}%`}
                      size="small"
                      color="primary"
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
          
          {studentRankings.historical_competitions.length > 0 && (
            <>
              <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Past Competitions</Typography>
              <List>
                {studentRankings.historical_competitions.map((comp, index) => (
                  <ListItem key={index} sx={{ mb: 1, bgcolor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <ListItemText
                      primary={comp.competition_title}
                      secondary={
                        <Box sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          <Typography variant="body2">
                            Rank: #{comp.rank} of {comp.total_participants}
                          </Typography>
                          <Typography variant="body2">
                            Score: {comp.score}
                          </Typography>
                          <Typography variant="body2">
                            Time: {formatTime(comp.completion_time)}
                          </Typography>
                        </Box>
                      }
                    />
                    <Chip 
                      label={`Top ${Math.round((comp.rank / comp.total_participants) * 100)})}%`}
                      size="small"
                      color="default"
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </>
      ) : (
        <ErrorContainer>
          <ErrorIcon color="error" sx={{ fontSize: 40 }} />
          <Typography>Failed to load competition history</Typography>
        </ErrorContainer>
      )}
    </Drawer>
  );

  if (!leaderboardData) {
    return (
      <LeaderboardContainer>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      </LeaderboardContainer>
    );
  }

  if (!leaderboardData.results?.length) {
    return (
      <LeaderboardContainer>
        <Typography variant="h6" align="center" sx={{ py: 4 }}>
          No entries yet. Be the first to complete this competition!
        </Typography>
      </LeaderboardContainer>
    );
  }

  if (error) {
    return (
      <LeaderboardContainer>
        <ErrorContainer>
          <ErrorIcon color="error" sx={{ fontSize: 48 }} />
          <Typography variant="h6">{error}</Typography>
          <Typography color="text.secondary">
            Please try refreshing the page or check back later.
          </Typography>
        </ErrorContainer>
      </LeaderboardContainer>
    );
  }

  const { topThree, paginatedEntries, total } = displayData;

  return (
    <LeaderboardContainer>
      <LeaderboardHeader>
        <Typography variant="h4" fontWeight="bold" gutterBottom sx={{ 
          fontSize: { xs: '1.5rem', sm: '2rem' } 
        }}>
          Competition Leaderboard
        </Typography>
        <StatsContainer>
          <StatItem>
            <GroupIcon />
            <Typography>
              {total} Participants
            </Typography>
          </StatItem>
          <StatItem>
            <StarIcon />
            <Typography>
              Max Score: {competition?.total_questions || 'N/A'}
            </Typography>
          </StatItem>
          <StatItem>
            <TimeIcon />
            <Typography>
              Time Limit: {competition?.time_limit} mins
            </Typography>
          </StatItem>
          {currentUser && currentCompRanking && (
            <StatItem>
              <ProfileIcon />
              <Box>
                <Typography variant="body2">Your Rank: #{currentCompRanking.rank}</Typography>
                <Typography variant="body2">
                  Score: {currentCompRanking.score}
                </Typography>
              </Box>
            </StatItem>
          )}
          {currentUser && (
            <Tooltip title="View your competition history">
              <IconButton
                onClick={() => setShowRankingHistory(true)}
                sx={{ color: 'white' }}
              >
                <Typography>My History</Typography>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          )}
        </StatsContainer>
      </LeaderboardHeader>

      <TopThreeContainer>
        {topThree.map((player, index) => (
          <>
            {/* Desktop View - Podium Cards */}
            <PodiumCard key={`desktop-${index}`} position={index + 1}>
              {index === 0 && (
                <Box sx={{ 
                  position: 'absolute', 
                  top: -40, 
                  left: '50%', 
                  transform: 'translateX(-50%)',
                  fontSize: '40px'
                }}>
                  👑
                </Box>
              )}
              <RankNumber color={index === 0 ? '#FFD700' : 'primary'}>{index + 1}</RankNumber>
              <PlayerAvatar
                src={getProfileImageUrl(player?.student)}
                size={index === 0 ? 120 : 100}
                sx={index === 0 ? { border: '4px solid #FFD700' } : {}}
                alt={player?.student?.full_name}
              >
                {player?.student?.full_name?.[0] || '?'}
              </PlayerAvatar>
              <Typography variant="h6" fontWeight="bold" align="center">
                {player?.student?.full_name}
              </Typography>
              <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" align="center">
                @{player?.student?.username}
              </Typography>
              <Typography variant={index === 0 ? 'h4' : 'h5'} fontWeight="bold" sx={{ mt: 1 }}>
                {player?.score} points
              </Typography>
              <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                {formatTime(player?.completion_time)}
              </Typography>
            </PodiumCard>

            {/* Mobile View - Top Players List */}
            <MobileTopPlayer key={`mobile-${index}`} position={index + 1}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 2 }}>
                <Box sx={{ position: 'relative', minWidth: 'fit-content' }}>
                  {index === 0 && (
                    <Box sx={{ 
                      position: 'absolute', 
                      top: -20, 
                      left: '50%', 
                      transform: 'translateX(-50%)',
                      fontSize: '24px',
                      zIndex: 1
                    }}>
                      👑
                    </Box>
                  )}
                  <RankNumber 
                    color={index === 0 ? '#FFD700' : 'primary'}
                    sx={{ position: 'absolute', top: -10, left: -10, zIndex: 1 }}
                  >
                    {index + 1}
                  </RankNumber>
                  <PlayerAvatar
                    src={getProfileImageUrl(player?.student)}
                    size={60}
                    sx={index === 0 ? { border: '3px solid #FFD700' } : {}}
                    alt={player?.student?.full_name}
                  >
                    {player?.student?.full_name?.[0] || '?'}
                  </PlayerAvatar>
                </Box>
                <Box sx={{ flex: 1, minWidth: 0 }}>
                  <Typography variant="body1" fontWeight="bold" noWrap>
                    {player?.student?.full_name}
                  </Typography>
                  <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" noWrap>
                    @{player?.student?.username}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'right', minWidth: 'fit-content' }}>
                  <Typography variant="h6" fontWeight="bold">
                    {player?.score}
                  </Typography>
                  <Typography variant="caption" color="rgba(255, 255, 255, 0.7)">
                    {formatTime(player?.completion_time)}
                  </Typography>
                </Box>
              </Box>
            </MobileTopPlayer>
          </>
        ))}
      </TopThreeContainer>

      <PlayersList>
        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : (
          paginatedEntries.map((player) => (
            <PlayerRow key={player.student?.id}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                flex: 1,
                gap: { xs: 1, sm: 2 }
              }}>
                <RankNumber>{player.rank}</RankNumber>
                <PlayerAvatar
                  src={getProfileImageUrl(player.student)}
                  size={50}
                  alt={player.student?.full_name}
                >
                  {player.student?.full_name?.[0] || '?'}
                </PlayerAvatar>
                <Box sx={{ minWidth: 0 }}>
                  <Typography variant="body1" fontWeight="bold" noWrap>
                    {player.student?.full_name}
                  </Typography>
                  <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" noWrap>
                    @{player.student?.username}
                  </Typography>
                </Box>
              </Box>
              <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={{ xs: 1, sm: 3 }} 
                alignItems={{ xs: 'flex-end', sm: 'center' }}
                sx={{ 
                  ml: { xs: 'auto', sm: 0 },
                  mt: { xs: 1, sm: 0 }
                }}
              >
                <Tooltip title="Score">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TrophyIcon fontSize="small" />
                    <Typography variant="h6" fontWeight="bold">
                      {player.score}
                    </Typography>
                  </Box>
                </Tooltip>
                <Tooltip title="Completion Time">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TimeIcon fontSize="small" />
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                      {formatTime(player.completion_time)}
                    </Typography>
                  </Box>
                </Tooltip>
                <Tooltip title="Ranking Percentage">
                  <Typography 
                    variant="caption" 
                    color="rgba(255, 255, 255, 0.5)"
                    sx={{ minWidth: 60 }}
                  >
                    {`Top ${Math.round((player.rank / total) * 100)}%`}
                  </Typography>
                </Tooltip>
              </Stack>
            </PlayerRow>
          ))
        )}
      </PlayersList>

      {totalPages > 1 && (
        <PaginationContainer>
          <Pagination 
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
            disabled={loading}
          />
        </PaginationContainer>
      )}

      <Box 
        sx={{ 
          mt: 4, 
          pt: 2, 
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
          Showing ranks {Math.min((currentPage - 1) * itemsPerPage + 4, total)} - {Math.min(currentPage * itemsPerPage + 3, total)} of {total}
        </Typography>
        <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
          Last updated: {new Date().toLocaleString()}
        </Typography>
      </Box>

      <RankingHistoryDrawer />
    </LeaderboardContainer>
  );
};

export default CompetitionLeaderboard;