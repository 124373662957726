import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { 
  listCourses, 
  createCourse, 
  updateCourse, 
  deleteCourse,getCurrentSpecialRate, updateSpecialRate
} from '../api';
import AssignTrainersModal from '../components/AssignTrainersModal';
import { assignTrainersToCourse } from '../api';



const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GlassCard = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const GradientChip = styled(Chip)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const GlassModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: theme.palette.common.white,
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

function CoursesPage() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAssignTrainersModal, setOpenAssignTrainersModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [openModal, setOpenModal] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null);
  const [openSpecialRateModal, setOpenSpecialRateModal] = useState(false);
const [specialRate, setSpecialRate] = useState('');
const [currentSpecialRate, setCurrentSpecialRate] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    class_duration: '',
    is_group_class: false,
    class_time: null,
  });
  const [searchTerm, setSearchTerm] = useState('');

  const fetchCourses = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listCourses();
      console.log('API response:', response);
      if (response && Array.isArray(response.data)) {
        setCourses(response.data);
      } else {
        console.error('Unexpected API response structure:', response);
        setCourses([]);
        setError('Received unexpected data format from the server.');
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
      setCourses([]);
      setError('Failed to fetch courses. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  const handleOpenAssignTrainersModal = (course) => {
    setSelectedCourse(course);
    setOpenAssignTrainersModal(true);
  };

  const handleAssignTrainers = async (trainerAssignments) => {
    try {
      await assignTrainersToCourse(selectedCourse.id, trainerAssignments);
      setSnackbar({ open: true, message: 'Trainers assigned successfully', severity: 'success' });
      fetchCourses();
    } catch (error) {
      console.error('Error assigning trainers:', error);
      setSnackbar({ open: true, message: 'Failed to assign trainers', severity: 'error' });
    }
    setOpenAssignTrainersModal(false);
  };

  const handleOpenModal = (course = null) => {
    if (course) {
      setFormData({
        name: course.name,
        description: course.description,
        class_duration: course.class_duration,
        is_group_class: course.is_group_class,
        class_time: course.class_time ? dayjs(course.class_time, 'HH:mm:ss') : null,
      });
      setEditingCourse(course);
    } else {
      setFormData({
        name: '',
        description: '',
        class_duration: '',
        is_group_class: false,
        class_time: null,
      });
      setEditingCourse(null);
    }
    setOpenModal(true);
  };

  const fetchCurrentSpecialRate = async () => {
    try {
      const response = await getCurrentSpecialRate();
      setCurrentSpecialRate(response.data);
    } catch (error) {
      console.error('Error fetching special rate:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch current special rate',
        severity: 'error'
      });
    }
  };
  
  // Add useEffect to fetch current special rate
  useEffect(() => {
    fetchCurrentSpecialRate();
  }, []);
  
  const handleOpenSpecialRateModal = () => {
    setSpecialRate(currentSpecialRate?.rate_for_30_min || '');
    setOpenSpecialRateModal(true);
  };
  
  const handleCloseSpecialRateModal = () => {
    setOpenSpecialRateModal(false);
    setSpecialRate('');
  };
  
  const handleSpecialRateSubmit = async () => {
    try {
      await updateSpecialRate(specialRate);
      setSnackbar({
        open: true,
        message: 'Special rate updated successfully',
        severity: 'success'
      });
      fetchCurrentSpecialRate();
      handleCloseSpecialRateModal();
    } catch (error) {
      console.error('Error updating special rate:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update special rate',
        severity: 'error'
      });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingCourse(null);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleTimeChange = (newValue) => {
    setFormData(prev => ({ ...prev, class_time: newValue }));
  };

  const handleSave = async () => {
    try {
      const courseData = {
        ...formData,
        class_time: formData.class_time ? formData.class_time.format('HH:mm:ss') : null,
      };

      if (editingCourse) {
        await updateCourse(editingCourse.id, courseData);
      } else {
        await createCourse(courseData);
      }

      setSnackbar({ 
        open: true, 
        message: `Course ${editingCourse ? 'updated' : 'created'} successfully`, 
        severity: 'success' 
      });
      handleCloseModal();
      fetchCourses();
    } catch (error) {
      console.error('Error saving course:', error);
      setSnackbar({ 
        open: true, 
        message: `Failed to ${editingCourse ? 'update' : 'create'} course. Please try again.`, 
        severity: 'error' 
      });
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this course?')) {
      try {
        await deleteCourse(id);
        setSnackbar({ open: true, message: 'Course deleted successfully', severity: 'success' });
        fetchCourses();
      } catch (error) {
        console.error('Error deleting course:', error);
        setSnackbar({ open: true, message: 'Failed to delete course. Please try again.', severity: 'error' });
      }
    }
  };

  const filteredCourses = courses.filter(course =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    course.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
   
      <GlassContainer  sx={{ paddingBottom: '400px'}}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
  <Typography variant="h4" gutterBottom color="white">
    Courses
  </Typography>
  <Box>
    {/* Add this button */}
    <GradientButton
      onClick={handleOpenSpecialRateModal}
      sx={{ mr: 2 }}
    >
      Update Special Rate
      {currentSpecialRate && (
        <Chip
          label={`₹${currentSpecialRate.rate_for_30_min}`}
          size="small"
          sx={{ ml: 1, backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
        />
      )}
    </GradientButton>
    <GradientButton
      startIcon={<AddIcon />}
      onClick={() => handleOpenModal()}
    >
      Add Course
    </GradientButton>
  </Box>
</Box>

        <TextField
          fullWidth
          label="Search Courses"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          margin="normal"
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />

        <StyledTableContainer component={GlassCard}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Class Time</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCourses.length > 0 ? (
                filteredCourses.map((course) => (
                  <TableRow key={course.id}>
                    <TableCell>{course.name}</TableCell>
                    <TableCell>{course.description}</TableCell>
                    <TableCell>{course.class_duration}</TableCell>
                    <TableCell>
                      <GradientChip 
                        label={course.is_group_class ? "Group" : "Individual"}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{course.class_time || 'Not set'}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleOpenModal(course)}>
                          <EditIcon sx={{ color: 'white' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton onClick={() => handleDelete(course.id)}>
                          <DeleteIcon sx={{ color: 'white' }} />
                        </IconButton>
                      </Tooltip>
                      {course.is_group_class && (
                        <GradientButton
                          size="small"
                          onClick={() => handleOpenAssignTrainersModal(course)}
                        >
                          Assign Trainers
                        </GradientButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No courses found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>

        <GlassModal open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
          <DialogTitle>{editingCourse ? 'Edit Course' : 'Add New Course'}</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Course Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              margin="normal"
              multiline
              rows={3}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
            />
            <TextField
              fullWidth
              label="Duration (HH:MM:SS)"
              name="class_duration"
              value={formData.class_duration}
              onChange={handleInputChange}
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel style={{ color: 'white' }}>Course Type</InputLabel>
              <Select
                name="is_group_class"
                value={formData.is_group_class}
                onChange={handleInputChange}
                style={{ color: 'white' }}
              >
                <MenuItem value={false}>Individual</MenuItem>
                <MenuItem value={true}>Group</MenuItem>
              </Select>
            </FormControl>
            <TimePicker
              label="Class Time"
              value={formData.class_time}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" InputLabelProps={{ style: { color: 'white' } }} InputProps={{ style: { color: 'white' } }} />}
            />
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={handleCloseModal}>Cancel</GradientButton>
            <GradientButton onClick={handleSave}>
              {editingCourse ? 'Update' : 'Create'}
            </GradientButton>
          </DialogActions>
        </GlassModal>

        <AssignTrainersModal
          open={openAssignTrainersModal}
          onClose={() => setOpenAssignTrainersModal(false)}
          course={selectedCourse}
          onAssign={handleAssignTrainers}
        />

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            sx={{ 
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              '& .MuiAlert-icon': {
                color: 'white'
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        
  <GlassModal 
    open={openSpecialRateModal} 
    onClose={handleCloseSpecialRateModal}
  >
    <DialogTitle>Update 30-Min Special Rate</DialogTitle>
    <DialogContent>
      <Box sx={{ mt: 2 }}>
        {currentSpecialRate && (
          <Typography variant="body2" sx={{ mb: 2, color: 'white' }}>
            Current Rate: ₹{currentSpecialRate.rate_for_30_min} for 22 sessions
          </Typography>
        )}
        <TextField
          fullWidth
          label="Special Rate for 22 Sessions (30 min)"
          type="number"
          value={specialRate}
          onChange={(e) => setSpecialRate(e.target.value)}
          InputProps={{
            startAdornment: <Typography sx={{ mr: 1 }}>₹</Typography>,
            style: { color: 'white' }
          }}
          InputLabelProps={{ style: { color: 'white' } }}
        />
        <Typography variant="caption" sx={{ display: 'block', mt: 1, color: 'white' }}>
          This rate will be used for calculating compensation for 30-minute sessions
          and partial compensation for 1.5-hour sessions.
        </Typography>
      </Box>
    </DialogContent>
    <DialogActions>
      <GradientButton onClick={handleCloseSpecialRateModal}>
        Cancel
      </GradientButton>
      <GradientButton onClick={handleSpecialRateSubmit}>
        Update Rate
      </GradientButton>
    </DialogActions>
  </GlassModal>
);
      </GlassContainer>
    </>
  );
}

export default CoursesPage;